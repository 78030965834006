import {
  TextQuestion,
  ChoiceQuestion,
  YesNoQuestion,
  QuestionGroup,
} from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";
import { countries } from "../datasources";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.TRANSFER_AGENT_ACTIVITY,
  questions: [
    new QuestionGroup("Transfer Agent Activity", {
      questions: [
        new ChoiceQuestion({
          label:
            "Are you directly providing the Transfer Agent services or do you outsource them?",
          key: "providingOrOutsourcing",
          isMultipleChoice: false,
          choices: [
            { value: "Yes, the services are directly provided" },
            {
              value: "No, the services are outsourced to a third-party",
              questions: [
                new TextQuestion({
                  label: "Name of the third-party provider",
                  key: "nameOfThirdPartyProvider",
                }),
                new ChoiceQuestion({
                  label: "Country of the third-party provider",
                  key: "countryOfThirdPartyProvider",
                  choices: countries,
                }),
                new TextQuestion({
                  label:
                    "Could you please detail the oversight protocol to the outsourced entity?",
                  key: "oversightProtocolDetail",
                }),
                new YesNoQuestion({
                  label:
                    "Do you perform due diligences on the outsourcing entity in compliance with the SLA in place?",
                  key: "performDDOnOutsourcingEntity",
                  Yes: [
                    new TextQuestion({
                      label: "Comment",
                      key: "performDDOnOutsourcingEntityYes",
                    }),
                  ],
                  No: [
                    new TextQuestion({
                      label: "Comment",
                      key: "performDDOnOutsourcingEntityNo",
                    }),
                  ],
                }),
              ],
            },
          ],
        }),
      ],
    }),
    new QuestionGroup("Volume of Activity", {
      questions: [
        new ChoiceQuestion({
          label:
            "For how many clients do you provide transfer agency services?",
          key: "howManyClientsProvided",
          isMultipleChoice: false,
          choices: ["1-10", "11-50", "51-200", "+200"],
        }),
        new ChoiceQuestion({
          label: "How many transaction did you process last year?",
          key: "howManyTransactionsProcessLastYear",
          isMultipleChoice: false,
          choices: [
            "1-1000",
            "1001-5000",
            "5001-20000",
            "20001-100 000",
            "100 001-1M",
            "+1M",
          ],
        }),
        new TextQuestion({
          label: "For how many years have you acted as a transfer agent?",
          key: "howManyYearsActedAsTransferAgent",
        }),
      ],
    }),
    new QuestionGroup("Register information", {
      questions: [
        new YesNoQuestion({
          label: "Are the underlying beneficial owners of units documented?",
          key: "recordUnderlyingBeneficialOwnerOfUnits",
          Yes: [
            TextQuestion.Explain(
              "recordUnderlyingBeneficialOwnerOfUnitsExplain",
            ),
          ],
        }),
        new YesNoQuestion({
          label:
            "Do you conduct reconciliation between your internal accounts and records of those of your Clients?",
          key: "conductReconciliation",
          Yes: [
            new TextQuestion({
              label: "On which frequency?",
              key: "conductReconciliationFrequency",
            }),
          ],
        }),
        new TextQuestion({
          label:
            "Do you ensure that the assets of the Client do not form part of the estate in case of bankruptcy or insolvency and are out of reach of any of your creditors?",
          key: "ensureAssetsNotPartOfEstate",
        }),
        new TextQuestion({
          label:
            "Would the register be impaired if you were to start insolvency proceedings?",
          key: "insolvencyProceedingsRegisterNotAffected",
        }),
        new ChoiceQuestion({
          label:
            "How long does it take to open an account for a new investor with all relevant documentation already available?",
          key: "HowLongOpenAccountForNewInvestor",
          choices: [
            "Within 24h",
            "Within 48h",
            {
              value: "Other",
              questions: [
                new TextQuestion({
                  label: "Please Specify",
                  key: "HowLongOpenAccountForNewInvestorOther",
                }),
              ],
            },
          ],
        }),
        new YesNoQuestion({
          label:
            "During a pandemic, please confirm you can accept electronic signing and scanning of documents?",
          key: "electronicSigningDuringPandemic",
          No: [
            new TextQuestion({
              label: "What alternatives are available?",
              key: "electronicSigningDuringPandemicAlternatives",
              isMultiLine: true,
            }),
          ],
        }),
      ],
    }),
    new QuestionGroup("Banking account Monitoring", {
      questions: [
        new YesNoQuestion({
          label:
            "Do you have an arrangement whereby your cash accounts are separated from the fund's cash accounts?",
          key: "cashAccountsSegregated",
        }),
      ],
    }),
    new QuestionGroup("Audit and Control", {
      questions: [
        new TextQuestion({
          label:
            "Could you please briefly detail the controls in place around Transfer Agency operations?",
          key: "TransferAgencyOperationsDetail",
        }),
        new ChoiceQuestion({
          label: "How often are the Transfer Agency controls reviewed?",
          key: "HowOftenTransferAgencyControlsReviewed",
          choices: [
            "Annually",
            "Biannually",
            {
              value: "Other",
              questions: [
                new TextQuestion({
                  label: "Please Specify",
                  key: "HowOftenTransferAgencyControlsReviewedOther",
                }),
              ],
            },
          ],
        }),
        new YesNoQuestion({
          label:
            "Is the transfer agency service controlled by any internal entities?",
          key: "TransferAgencyControlledBy",
          Yes: [
            new ChoiceQuestion({
              label: "Which frequency?",
              key: "TransferAgencyControlledByFrequency",
              choices: [
                "Annually",
                "Biannually",
                {
                  value: "Other",
                  questions: [
                    new TextQuestion({
                      label: "Please Specify",
                      key: "TransferAgencyControlledByFrequencyOther",
                    }),
                  ],
                },
              ],
            }),
          ],
        }),
        new YesNoQuestion({
          label:
            "Have you put in place protocols and guidelines that guarantee the safeguarding of assets: proper segregation, reconciliation, record keeping and reporting.",
          key: "PoliciesToEnsureProtectionOfAssets",
          No: [
            TextQuestion.Explain("PoliciesToEnsureProtectionOfAssetsExplain"),
          ],
        }),
      ],
    }),
  ],
};
export default category;
