import _nationalities from "./nationalities.json";

export const NATIONALITIES = _nationalities;
export { getKeys, getEntries, getValues, getEnumValues } from "./constants";
export * from "./values";

// used by forms
export const nationalitiesOptions = NATIONALITIES.map((nationality) => ({
  label: nationality,
  value: nationality,
}));
