import dotenv from "dotenv";
import camelCase from "lodash/camelCase";

if (typeof process !== "undefined" && process.env.TS_NODE_DEV) {
  dotenv.config({ path: "../../.env.local" });
}

const DEBUG_PREFIX = "GFW_DEBUG";

type configType = {
  [key: string]: any;
};

export function format(key: string, value: any): any {
  if (key.endsWith("_COUNT")) {
    return parseFloat(value);
  }
  if (
    key.startsWith("IS_") ||
    key.startsWith("HAS_") ||
    ["ENABLED", "DISABLED"].includes(key)
  ) {
    return !!value;
  }
  return value;
}

export function convertEnvToObject(env: configType): configType {
  const PREFIX = `${DEBUG_PREFIX}_`;

  const config: configType = {};

  for (const key in env) {
    if (key.startsWith(PREFIX)) {
      const attr = key.split(PREFIX)[1];
      const [section, subAttr] = attr.split("__");
      if (!config[camelCase(section)]) config[camelCase(section)] = {};
      config[camelCase(section)][camelCase(subAttr)] = format(
        subAttr,
        env[key],
      );
    }
  }
  return config;
}

/**
 * get and format the debug config defined in .env
 */
export function getConfig(
  category: string | undefined,
  processEnv: configType = process.env,
): configType {
  if (!processEnv.TS_NODE_DEV) return {};
  const config: configType = convertEnvToObject(processEnv);

  return category ? config[camelCase(category.toUpperCase())] || {} : config;
}
