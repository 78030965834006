import slugify from "@sindresorhus/slugify";
import { QuestionnaireQuestion } from "./QuestionnaireQuestion";
import { IQuestionnaireSnapshot, applySnapshot } from "../Questionnaire";
import { createLogger } from "@app/utils";

const log = createLogger("questionnaire:questionsGroup");
interface IQuestionGroup {
  questions: QuestionnaireQuestion[];
  helpText?: string;
  variant?: string;
}

/**
 * Not really a question, it groups questions together.
 * But at the same time it can act as a question.
 */
export class QuestionGroup extends QuestionnaireQuestion {
  type = "group";
  questions: QuestionnaireQuestion[];
  helpText?: string;
  variant?: string;

  constructor(label: string, options: IQuestionGroup) {
    super({ label, key: `group::${slugify(label)}` });

    this.questions = options.questions;
    this.helpText = options.helpText;
    this.variant = options.variant;
  }

  clone(): QuestionnaireQuestion {
    return new QuestionGroup(this.label, {
      ...this,
      questions: this.questions.map((question) => question.clone()),
    });
  }

  getQuestions(options: { depth?: number } = {}): QuestionnaireQuestion[] {
    const opts = Object.assign({ depth: 1 }, options);

    return this.questions.reduce<QuestionnaireQuestion[]>(
      (questions, question) => {
        return [...questions, ...question.getQuestions(opts)];
      },
      [],
    );
  }

  answerToString(): string {
    log.warn("QuestionGroup has no answer");
    return "";
  }

  getQuestion(key: string) {
    return this.getQuestions().find((question) => question.key === key);
  }

  applySnapshot(snapshot: IQuestionnaireSnapshot): void {
    applySnapshot(this.questions, snapshot);
  }
}
