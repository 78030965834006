import { Flex, Statistic } from "antd";
import { saveAs } from "file-saver";
import { useMutation } from "react-query";
import React, { useEffect } from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  Grid,
  Icon,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { datetime } from "@gfw/core";

import {
  Page,
  PageTitle,
  PageTitleTypo,
} from "@app/resources/generic/components";

function UserStatsPage() {
  const {
    mutateAsync: getUsersStats,
    data: usersStatsList = [],
    isLoading,
  } = useMutation(
    async () => {
      const response = await apiClient.get("/admin/users-count");
      if (response?.data) {
        for (const [key, value] of Object.entries(response?.data)) {
          usersStatsList.push({ title: key, count: value });
        }
      }
      return usersStatsList;
    },
    {
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  async function onExportToExcelClick() {
    const response = await apiClient.get("/admin/exports/users", {
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    });

    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    saveAs(file, `GFW-Users-${datetime.iso8601Date()}.xlsx`, {
      autoBom: true,
    });
  }

  const usersCountTitle = {
    countActiveUsers: "Active users",
    countInactiveUsers: "Deactivated users",
    usersCountWithoutProfilesAssociated:
      "Users without any profiles associated",
    usersCountWithMoreThan1ProfileLinked:
      "Users with more than 1 profile linked",
    countActiveUsersReadOnly: "Read-only users",
    countActiveUsersRegular: "Regular users",
    countActiveUsersFull: "Full users",
    countActiveUsersLastSixMonths: "Users who connected in the last 6 months",
    countActiveUsersLastTwelveMonths:
      "Users who connected in the last 12 months",
  };

  useEffect(() => {
    getUsersStats();
  }, [getUsersStats]);

  return (
    <Page>
      <Flex align="center" justify="space-between">
        <PageTitle>
          <Icon mr="sm" type="users" />
          <PageTitleTypo>Users</PageTitleTypo>
        </PageTitle>
        <Button icon="download" onClick={onExportToExcelClick} type="link">
          Export All
        </Button>
      </Flex>
      <Grid.Row gutter={[16, 16]}>
        {usersStatsList.map((userStats) => {
          return (
            <Grid.Col key={userStats.title} sm={12} xs={24}>
              <Card isLoading={isLoading}>
                <Statistic
                  prefix={<Icon fs="md" mr="sm" type="users" />}
                  title={usersCountTitle[userStats.title]}
                  value={userStats.count}
                />
              </Card>
            </Grid.Col>
          );
        })}
      </Grid.Row>
    </Page>
  );
}

export default UserStatsPage;
