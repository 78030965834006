enum TEMPORARY_FILE_SUBMIT_STATE {
  TEMP = "temp" /* Form not submitted*/,
  READY = "ready" /* Form submitted */,
}

Object.freeze(TEMPORARY_FILE_SUBMIT_STATE);

enum TEMPORARY_FILE_STATE {
  IN_PROGRESS = "IN_PROGRESS" /* Temporary file is not fully completed*/,
  REJECTED = "REJECTED" /* Temporary file has been rejected (due to virus or other problem) */,
  COMPLETED = "COMPLETED" /* Temporary file form has been submitted and antivirus run */,
}

Object.freeze(TEMPORARY_FILE_STATE);

export { TEMPORARY_FILE_SUBMIT_STATE, TEMPORARY_FILE_STATE };
