import {
  TextQuestion,
  ChoiceQuestion,
  YesNoQuestion,
  CustomQuestion,
  QuestionGroup,
} from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.MANAGEMENT_AND_OWNERSHIP,
  questions: [
    new QuestionGroup("Parent Company", {
      variant: "noBorder",
      questions: [
        new YesNoQuestion({
          label: "Do you have a parent company?",
          key: "haveParentCompany", // parentCompany is already in corporate
          Yes: [
            new TextQuestion({
              label: "Name of the parent company",
              key: "nameParentCompany",
            }),
            new TextQuestion({
              label: "Corporate Identity Number",
              key: "parentCompanyCIN",
            }),
            new YesNoQuestion({
              key: "isParentCompanyListed",
              label: "Is the parent company listed?",
              Yes: [
                new TextQuestion({
                  key: "parentCompanyMarketPlaceName",
                  label: "Name of the market place",
                }),
                new TextQuestion({
                  key: "parentCompanyMarketPlaceWebsite",
                  label: "Website of the market place",
                }),
              ],
            }),
          ],
        }),
      ],
    }),
    new QuestionGroup("Ultimate Beneficial Owners: List UBOs", {
      // TODO: need a way to set this as a help text or explanation or something??
      // Ultimate Beneficial Owner (UBO) is a natural person who controls or owns, directly and/or indirecty, 10 % or more of the shares or exercises ultimate control over the management of the entity.
      questions: [
        new ChoiceQuestion({
          key: "declarationOfUBO",
          label:
            "Declaration of UBOs. One of the following alternatives must be selected.",
          // TODO: isn't this just a YES/NO question in disguise? For now we need to keep the value and label in sync but??
          choices: [
            {
              value:
                "We confirm that our Ultimate Beneficial Owners are as follows and that no other natural person controls or owns directly and/or indirectly 25% or more of the shares in our entity",
            },
            {
              value:
                "We confirm that no natural person controls or owns directly and/or indirectly 25% or more of the shares in our entity",
            },
          ],
        }),
        new CustomQuestion({
          key: "listUBO",
          label: "List of UBOs & Key Persons",
        }),
      ],
    }),
    new QuestionGroup("Management and Shareholding history", {
      questions: [
        new TextQuestion({
          key: "changeInManagementAndShareholding",
          label:
            "Were there any significant changes in the management and shareholding structure during the last 3 years?",
        }),
      ],
    }),
    new QuestionGroup("Upcoming changes", {
      questions: [
        new YesNoQuestion({
          key: "upcomingChanges",
          label:
            "Are any significant changes planned in the short term in relation to ownership structure, role in your group, legal structure, directors and/or organization?",
          Yes: [TextQuestion.Explain("ownershipChangesExplain")],
        }),
      ],
    }),
    new YesNoQuestion({
      key: "hasBoardOfDirectors",
      label: "Does your company have a board of directors?",
      Yes: [
        new CustomQuestion({
          key: "boardMemberWarning",
          label: "Add board members identities",
        }),
      ],
    }),
  ],
};

export default category;
