import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";

import { apiClient } from "@gfw/backend-connector";
import { getUIConfig, loadGlobalWatchFonts } from "@gfw/corvus";

import App from "./app/App";
import React from "react";

// init bc connector
const { APP_VERSION, GFW_API_URL } = getUIConfig();
apiClient.init({ baseURL: GFW_API_URL, appVersion: APP_VERSION });

loadGlobalWatchFonts();

// save a reference to the root element for reuse
const container = document.getElementById("gfw");

// render the application normally
const root = createRoot(container);
root.render(<App />);

if (module.hot) {
  // whenever a new version of App is available
  module.hot.accept("./app/App", function () {
    // require the new version and render it instead
    const NextApp = require("./app/App").default;

    root.render(<NextApp />);
  });
}
