import { Checkbox, Flex, Form, Input } from "antd";
import { difference } from "lodash";
import { saveAs } from "file-saver";
import { useMutation } from "react-query";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import slugify from "slugs";
import styled from "styled-components";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  DatePicker,
  FormProfileLeiNumber,
  FormProfileLocation,
  FormProfileName,
  FormProfileProposedContacts,
  FormProfileType,
  Modal,
  notification,
  Space,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { datetime, PROFILE_STATE } from "@gfw/core";
import { Text } from "@gfw/orion";

import ActionMergeProfiles from "@app/resources/Profile/components/ActionMergeProfiles";
import ActionRejectProposed from "@app/resources/Profile/components/ActionRejectProposed";
import RevertMerge from "@app/resources/Profile/components/ActionRevertMerge";

const StyledActions = styled.div`
  margin-top: 64px;
`;
function ProfileDetail({ profile, refetch }) {
  const [isDumping, setDump] = useState(false);
  const [form] = Form.useForm();

  const isProposed = profile.state === PROFILE_STATE.PROPOSED;
  const isDraft = profile.state === PROFILE_STATE.DRAFT;
  const isInactive = profile.state === PROFILE_STATE.INACTIVE;
  const isOfficial = profile.state === PROFILE_STATE.OFFICIAL;
  const isMerged = profile?.mergeHistory?.length > 0;

  const { mutateAsync: handleSubmit, isLoading } = useMutation(
    async (values) => {
      const patchValues = {};
      if (values.name !== profile.name) {
        patchValues.name = values.name;
      }
      if (values.LEINumber !== profile.LEINumber) {
        patchValues.LEINumber = values.LEINumber?.trim();
      }
      if (values.state >= 0 && values.state !== profile.state) {
        patchValues.state = values.state;
      }
      if (values.isPremium !== profile.isPremium) {
        patchValues.isPremium = values.isPremium;
      }
      if (values.location !== profile.location) {
        patchValues.location = values.location;
      }
      if (
        difference(values.types, profile.types).length ||
        difference(profile.types, values.types).length
      ) {
        patchValues.types = values.types;
      }
      if (
        values.proposedContacts &&
        JSON.stringify(values.proposedContacts) !==
          JSON.stringify(profile.proposedContacts)
      ) {
        patchValues.proposedContacts = values.proposedContacts;
      }
      if (Object.keys(patchValues).length) {
        await apiClient.patch(`/profiles/${profile.id}`, patchValues);
        refetch?.();
      }
    },
    {
      onSuccess: () => notification.success({ message: `Profile updated` }),
      onError: (error) => {
        if (error?.response?.status === 409) {
          form.setFields([
            {
              name: "name",
              errors: [
                "A profile already exists with this name, type and location",
              ],
            },
            {
              name: "types",
              errors: [
                "A profile already exists with this name, type and location",
              ],
            },
            {
              name: "location",
              errors: [
                "A profile already exists with this name, type and location",
              ],
            },
          ]);
        }
        __DEPRECATED__ErrorHandler(error);
      },
    },
  );

  async function exportToExcel() {
    const response = await apiClient.get(`/profiles/${profile.id}`, {
      headers: {
        // tells the api we want excel back
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    });

    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });

    saveAs(
      file,
      `gfw-profile--${slugify(profile.name)}--${datetime.iso8601Date()}.xlsx`,
      { autoBom: true },
    );
  }

  async function dumpProfile() {
    setDump(true);
    try {
      const response = await apiClient.get(
        `/admin/profiles/${profile.oid}/dump`,
        {
          timeout: 2000000,
        },
      );

      const file = new Blob([JSON.stringify(response.data, null, 2)], {
        type: "text/plain;charset=UTF-8",
      });

      saveAs(
        file,
        `gfw-profile--${slugify(
          profile.name,
        )}--${datetime.iso8601Date()}-dump.json`,
        { autoBom: true },
      );
    } catch (e) {
      if (e.response.status === 403) {
        notification.error({
          message: "Oops, something went wrong",
          description: "You are not allowed to generate this kind of export",
        });
      } else {
        __DEPRECATED__ErrorHandler(e);
      }
    }
    setDump(false);
  }

  const { mutateAsync: createTempToken } = useMutation(
    async () => {
      const response = await apiClient.post(`/admin/profile/temp-token`, {
        profileId: profile.id,
      });
      return response;
    },
    {
      onSuccess: (response) => {
        const url = `${response?.data?.url}/access/${response?.data?.access_token}`;
        window.open(url, "_blank", "noopener, noreferrer");
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const { mutateAsync: refreshStats, isLoading: isRefreshing } = useMutation(
    async () => {
      await apiClient.put(`/profiles/${profile.id}/stats`);
      refetch?.();
    },
    {
      onSuccess: () => notification.success({ message: `Stats updated` }),
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const { mutateAsync: regenerateCounterpartyData } = useMutation(
    async () => {
      await apiClient.post(`/profile/counterparties-data/regenerate`, {
        profileOId: profile.oid,
      });
    },
    {
      onSuccess: () =>
        notification.success({
          message: `Counterparty data regeneration started`,
        }),
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const initialValues = {
    ...profile,
    LEINumber: profile.LEINumber,
    createdAt: profile.createdAt,
    draftedAt: profile.draftedAt,
    updatedAt: profile.updatedAt,
    lastConnectionAt: profile.lastConnectionAt,
  };

  useEffect(() => {
    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex gap="8px">
      <Card style={{ flex: 3, paddingTop: "12px", border: "none" }}>
        <Form
          autoComplete="off"
          form={form}
          initialValues={initialValues}
          labelCol={{ span: 4 }}
          onFinish={handleSubmit}
        >
          <div style={{ maxWidth: 1024 }}>
            <FormProfileName
              autoFocus={false}
              onSelect={(_, option) => {
                form.setFieldValue("LEINumber", option.lei);
                form.validateFields(["LEINumber"]);
              }}
            />
            <FormProfileLeiNumber />
            <FormProfileType />
            <FormProfileLocation />
            <Form.Item
              hasFeedback
              label="isPremium"
              name="isPremium"
              required={false}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              disabled={true}
              label="Created"
              name="createdAt"
              required={false}
            >
              <DatePicker disabled={true} style={{ display: "flex" }} />
            </Form.Item>
            {!isProposed && (
              <Form.Item
                disabled={true}
                label="Updated"
                name="updatedAt"
                required={false}
              >
                <DatePicker disabled={true} style={{ display: "flex" }} />
              </Form.Item>
            )}
            {!isProposed && (
              <Form.Item
                disabled={true}
                label="Last Connection"
                name="lastConnectionAt"
                required={false}
              >
                <DatePicker
                  disabled={true}
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ display: "flex" }}
                />
              </Form.Item>
            )}
            {isProposed && (
              <FormProfileProposedContacts name="proposedContacts" />
            )}
            {!!profile.proposedBy && (
              <FormProfileProposedContacts
                disabled={true}
                label="ProposedBy"
                name="proposedBy"
              />
            )}
            {!!profile.draftedAt && (
              <Form.Item
                disabled={true}
                label="DraftedAt"
                name="draftedAt"
                required={false}
              >
                <DatePicker
                  disabled={true}
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ display: "flex" }}
                />
              </Form.Item>
            )}
            {!!profile.draftedBy && (
              <Form.Item
                disabled={true}
                label="DraftedBy"
                name="draftedBy"
                required={false}
              >
                <Input disabled={true} />
              </Form.Item>
            )}
            <StyledActions>
              <Space>
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={form.submit}
                  type="primary"
                  width="120px"
                >
                  Update
                </Button>
                <Button onClick={() => form.resetFields()}>Reset</Button>
              </Space>
            </StyledActions>
          </div>
        </Form>
      </Card>
      <Card
        size="small"
        style={{ flex: 1, paddingTop: "12px", border: "none" }}
      >
        <Flex vertical>
          {!isProposed && (
            <Modal
              hideFooterBorder
              hideHeaderBorder
              onOk={createTempToken}
              title="Manage this profile"
              trigger={
                <Button block icon="eye" mb="md" type="primary">
                  Manage this profile
                </Button>
              }
            >
              <Text>
                Are you sure that you want to grant temporary access to this
                profile?
              </Text>
            </Modal>
          )}
          {(isOfficial || isInactive) && (
            <Button
              block
              icon="downloadFileIcon"
              mb="md"
              onClick={exportToExcel}
              type="primary"
            >
              Export as Excel
            </Button>
          )}
          {(isOfficial || isInactive) && (
            <Button
              block
              icon="refresh"
              loading={isRefreshing}
              mb="md"
              onClick={refreshStats}
            >
              Update stats
            </Button>
          )}
          {(isDraft || isOfficial) && (
            <Modal
              hideFooterBorder
              hideHeaderBorder
              onOk={() => handleSubmit({ state: PROFILE_STATE.INACTIVE })}
              title="Deactivate profile"
              trigger={
                <Button block icon="deactivateProfile" mb="md">
                  Deactivate
                </Button>
              }
            >
              <Text>
                Are you sure that you want to deactivate this profile?
              </Text>
            </Modal>
          )}
          {isInactive && (
            <Modal
              hideFooterBorder
              hideHeaderBorder
              onOk={() => handleSubmit({ state: PROFILE_STATE.OFFICIAL })}
              title="Reactivate profile"
              trigger={
                <Button block mb="md">
                  Re Activate
                </Button>
              }
            >
              <Text>Are you sure that you want to activate this profile?</Text>
            </Modal>
          )}
          {isProposed && (
            <Modal
              hideFooterBorder
              hideHeaderBorder
              onOk={() => handleSubmit({ state: PROFILE_STATE.DRAFT })}
              title="Accept profile"
              trigger={
                <Button block mb="md" type="primary">
                  Accept
                </Button>
              }
            >
              <Text>Are you sure that you want to accept this profile?</Text>
            </Modal>
          )}
          {isProposed && <ActionRejectProposed profile={profile} />}
          <ActionMergeProfiles profile={profile} />
          <Button
            block
            icon="password"
            loading={isDumping}
            mb="md"
            onClick={dumpProfile}
          >
            Dump profile
          </Button>
          {isMerged && <RevertMerge profile={profile} />}
          <Modal
            hideFooterBorder
            hideHeaderBorder
            onOk={regenerateCounterpartyData}
            title="Regenerate counterparty data"
            trigger={
              <Button block icon="sync" mb="md">
                Regenerate cp data
              </Button>
            }
          >
            <Text>
              Are you sure that you want to regenerate counterparty data for
              this profile?
            </Text>
          </Modal>
        </Flex>
      </Card>
    </Flex>
  );
}

ProfileDetail.propTypes = {
  profile: PropTypes.object,
  refetch: PropTypes.func,
};

export default ProfileDetail;
