import { countries as _countries } from "../../countries";
import { findContinentsBy } from "../../continents";
import { IChoice } from "../questions/ChoiceQuestion";
import { map } from "lodash";

export const countries: string[] = map(_countries, "name").sort();

function continents(searchTerm: string): IChoice[] {
  return findContinentsBy(searchTerm).map<IChoice>((continent) => ({
    value: continent.name,
  }));
}
