import { Validator } from "@app/validation/type";

const checksumValidator = (leiNumber: string) => {
  let buffer = 0;

  for (let i = 0; i < leiNumber.length; ++i) {
    const charCode = leiNumber.charCodeAt(i);
    let multiplier, offset;

    if (charCode >= "A".charCodeAt(0)) {
      multiplier = 100;
      offset = "A".charCodeAt(0) - 10;
    } else {
      multiplier = 10;
      offset = "0".charCodeAt(0);
    }

    buffer *= multiplier;
    buffer -= offset;
    buffer += charCode;
    buffer %= 97;
  }

  return buffer === 1;
};

const isLeiNumberValid: Validator = (leiNumber: string) => {
  return (
    /^[A-Z0-9]{18}[0-9]{2}$/.test(leiNumber) && checksumValidator(leiNumber)
  );
};

export { isLeiNumberValid };
