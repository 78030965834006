import { TextQuestion, YesNoQuestion } from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.SETTLEMENT_AND_TRADING,
  questions: [
    new YesNoQuestion({
      key: "latest-ssi",
      label: "Latest SSI details uploaded to this profile?",
      No: [TextQuestion.Explain("latest-ssi-other")],
    }),
    new YesNoQuestion({
      key: "lse",
      label: "Connection to LSE?",
    }),
    new YesNoQuestion({
      key: "bb-tsox-tradeweb-available",
      label: "Available in Bloomberg TSOX/Tradeweb?",
    }),
  ],
};

export default category;
