import { Card } from "antd";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import styled from "styled-components";

import { Button, Icon, Loading, Tabs } from "@gfw/corvus";
import {
  selectorUsersByOId,
  userGetByOId,
  userGetNotifications,
} from "@gfw/backend-connector";

import {
  EditUser,
  UserBrowserTests,
  UserDetail,
  UserNotifications,
  UserProfilesList,
  UserSubscriptions,
} from "./screens";

const TabTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
`;

// This page is shown at /users/:id
function UserPage() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const tab = useParams()["*"];
  const navigate = useNavigate();

  const user = useSelector(selectorUsersByOId(id));

  const fetchUser = (userOId) => {
    dispatch(userGetByOId({ userOId }));
    dispatch(userGetNotifications({ userOId }));
  };

  useEffect(() => {
    fetchUser(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const items = [
    {
      label: (
        <TabTitle>
          <Icon fs="lg" mr="sm" type="detail" />
          User
        </TabTitle>
      ),
      key: "read",
    },
    {
      label: (
        <TabTitle>
          <Icon fs="lg" mr="sm" type="profile" />
          Profiles
        </TabTitle>
      ),
      key: "profiles",
    },
    {
      label: (
        <TabTitle>
          <Icon fs="lg" mr="sm" type="outgoing" />
          Mail subscriptions
        </TabTitle>
      ),
      key: "subscriptions",
    },
    {
      label: (
        <TabTitle>
          <Icon fs="lg" mr="sm" type="email" />
          Notifications
        </TabTitle>
      ),
      key: "notifications",
    },
    {
      label: (
        <TabTitle>
          <Icon fs="lg" mr="sm" type="cog" />
          Debug
        </TabTitle>
      ),
      key: "debug",
    },
  ];

  const onChange = (tabId) => {
    navigate(`/users/${id}/${tabId}`);
  };

  if (!user) return <Loading />;

  return (
    <Card>
      <Header>
        <Card.Meta
          description={user.username}
          title={
            <>
              <Icon mr="sm" type="user" />
              {user.firstName} {user.lastName}
            </>
          }
        />
        <Button
          icon="refresh"
          loading={user.isLoading}
          onClick={() => fetchUser(id)}
          type={"link"}
        >
          Refresh
        </Button>
      </Header>
      <Tabs activeKey={tab} items={items} onChange={onChange} />
      <Routes>
        <Route element={<UserProfilesList />} path="profiles" />
        <Route element={<UserSubscriptions />} path="subscriptions" />
        <Route element={<UserNotifications />} path="notifications" />
        <Route element={<UserDetail fetchUser={fetchUser} />} path="*" />
        <Route element={<UserBrowserTests />} path="debug" />
        <Route element={<EditUser />} path="edit" />
        <Route element={<Navigate to="read" />} index />
      </Routes>
    </Card>
  );
}

export default UserPage;
