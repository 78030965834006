import {
  QuestionGroup,
  TextQuestion,
  ChoiceQuestion,
  YesNoQuestion,
  BooleanQuestion,
} from "@app/questionnaire/questions";
import { toChoices } from "@app/questionnaire/questions/ChoiceQuestion";
import { countries } from "@app/questionnaire/datasources";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.TYPE_BUSINESS_CLIENT_BASE,
  questions: [
    new QuestionGroup("Business in general", {
      variant: "noBorder",
      questions: [
        new TextQuestion({
          key: "milestones",
          label: "Business activities",
          isMultiLine: true,
        }),
        new TextQuestion({
          key: "distribution-strategy",
          label: "Fund offering type",
          isMultiLine: true,
        }),
      ],
    }),
    new QuestionGroup("Status of your entity", {
      questions: [
        new ChoiceQuestion({
          key: "roles-sales-marketing",
          label: "Pick all relevant roles",
          isMultipleChoice: true,
          choices: [
            ...toChoices([
              "Distributor",
              "Marketer/Introducer",
              "Financial Advisor (IFA)",
              "Wealth Manager",
              "Retail/Private Bank",
              "Broker",
              "Family Office",
              "Multi Manager/Fund of Funds",
              "Insurance Company",
              "Life Insurance Company",
              "Pension Scheme Manager",
              "Fund Platform Institutions (B2B)",
              "Fund Platform Retail (B2C)",
              "Custodian",
              "Sub-Custodian",
              "Nominee",
              "Acting capacity - primary direct",
              "Acting capacity - secondary - clearing through a separate entity",
            ]),
            {
              value: "Other",
              questions: [
                new TextQuestion({
                  key: "roles-sales-marketing-other",
                  label: "Please specify other role/s",
                }),
              ],
            },
          ],
        }),
      ],
    }),
    new QuestionGroup("Type of offering", {
      questions: [
        new QuestionGroup("Type of activities", {
          questions: [
            new BooleanQuestion({
              key: "typeOfActivitiesExecutionOnly",
              label: "Execution only",
              questions: [
                new TextQuestion({
                  key: "typeofActivitiesExecutionOnlySpecify",
                  label: "Specify how you enhance quality of service",
                }),
                new ChoiceQuestion({
                  key: "typeOfActivitiesExecutionOnlyCountriesOfOperations",
                  label: "Jurisdictions where the services are offered",
                  isMultipleChoice: true,
                  choices: countries,
                }),
                new ChoiceQuestion({
                  key: "typeOfActivitiesExecutionOnlyTypeOfClients",
                  label: "Type of clients",
                  isMultipleChoice: true,
                  threshold: 7,
                  choices: [
                    ...toChoices([
                      "Retail clients",
                      "High Net Worth Individuals",
                      "Professional investors",
                      "Financial institutes",
                      "IFAs",
                      "Investors in insurance linked products/plans",
                    ]),
                    {
                      value: "Other",
                      questions: [
                        TextQuestion.Specify(
                          "typeOfActivitiesExecutionOnlyTypeOfClientsOther",
                        ),
                      ],
                    },
                  ],
                }),
              ],
            }),
            new BooleanQuestion({
              key: "typeOfActivitiesDPM",
              label: "Discretionary Portfolio Management",
              questions: [
                new ChoiceQuestion({
                  key: "typeOfActivitiesDPMCountriesOfOperations",
                  label: "Jurisdictions where the services are offered",
                  isMultipleChoice: true,
                  choices: countries,
                }),
                new YesNoQuestion({
                  key: "typeOfActivitiesDPMDistributionActivities",
                  label:
                    "Do your activities constitute public distribution in the jurisdictions where you operate?",
                  Yes: [
                    new YesNoQuestion({
                      key: "typeOfActivitiesDPMDistributionActivitiesYes",
                      label:
                        "Do you ensure that funds are locally registered for distribution?",
                    }),
                  ],
                }),
                new ChoiceQuestion({
                  key: "typeOfActivitiesDPMTypeOfClients",
                  label: "Type of clients",
                  isMultipleChoice: true,
                  threshold: 7,
                  choices: [
                    ...toChoices([
                      "Retail clients",
                      "High Net Worth Individuals",
                      "Professional investors",
                      "Financial institutes",
                      "IFAs",
                      "Investors in insurance linked products/plans",
                    ]),
                    {
                      value: "Other",
                      questions: [
                        TextQuestion.Specify(
                          "typeOfActivitiesDPMTypeOfClientsOther",
                        ),
                      ],
                    },
                  ],
                }),
              ],
            }),
            new BooleanQuestion({
              key: "typeOfActivitiesAdvice",
              label: "Advice",
              questions: [
                // TODO: shouldn't this be a choice question really?? But hard right now with this being a text from legacy
                new TextQuestion({
                  key: "typeofActivitiesAdviceSpecify",
                  label: "Non-independent or Independent",
                }),
                new ChoiceQuestion({
                  key: "typeOfActivitiesAdviceCountriesOfOperations",
                  label: "Jurisdictions where the services are offered",
                  isMultipleChoice: true,
                  choices: countries,
                }),
                new YesNoQuestion({
                  key: "typeOfActivitiesAdviceDistributionActivities",
                  label:
                    "Do your activities constitute public distribution in the jurisdictions where you operate?",
                  Yes: [
                    new YesNoQuestion({
                      key: "typeOfActivitiesAdviceDistributionActivitiesYes",
                      label:
                        "Do you ensure that funds are locally registered for distribution?",
                    }),
                  ],
                }),
                new ChoiceQuestion({
                  key: "typeOfActivitiesAdviceTypeOfClients",
                  label: "Type of clients",
                  isMultipleChoice: true,
                  threshold: 7,
                  choices: [
                    ...toChoices([
                      "Retail clients",
                      "High Net Worth Individuals",
                      "Professional investors",
                      "Financial institutes",
                      "IFAs",
                      "Investors in insurance linked products/plans",
                    ]),
                    {
                      value: "Other",
                      questions: [
                        TextQuestion.Specify(
                          "typeOfActivitiesAdviceTypeOfClientsOther",
                        ),
                      ],
                    },
                  ],
                }),
              ],
            }),
            new BooleanQuestion({
              key: "typeOfActivitiesFundOfFunds",
              label: "Fund-of-funds",
              questions: [
                new ChoiceQuestion({
                  key: "typeOfActivitiesFundOfFundsCountriesOfOperations",
                  label: "Jurisdictions where the services are offered",
                  isMultipleChoice: true,
                  choices: countries,
                }),
                new YesNoQuestion({
                  key: "typeOfActivitiesFundOfFundsDistributionActivities",
                  label:
                    "Do your activities constitute public distribution in the jurisdictions where you operate?",
                  Yes: [
                    new YesNoQuestion({
                      key: "typeOfActivitiesFundOfFundsDistributionActivitiesYes",
                      label:
                        "Do you ensure that funds are locally registered for distribution?",
                    }),
                  ],
                }),
                new ChoiceQuestion({
                  key: "typeOfActivitiesFundOfFundsTypeOfClients",
                  label: "Type of clients",
                  isMultipleChoice: true,
                  threshold: 7,
                  choices: [
                    ...toChoices([
                      "Retail clients",
                      "High Net Worth Individuals",
                      "Professional investors",
                      "Financial institutes",
                      "IFAs",
                      "Investors in insurance linked products/plans",
                    ]),
                    {
                      value: "Other",
                      questions: [
                        TextQuestion.Specify(
                          "typeOfActivitiesFundOfFundsTypeOfClientsOther",
                        ),
                      ],
                    },
                  ],
                }),
              ],
            }),
            new BooleanQuestion({
              key: "typeOfActivitiesInsuranceProducts",
              label: "Insurance products",
              questions: [
                new ChoiceQuestion({
                  key: "typeOfActivitiesInsuranceProductsCountriesOfOperations",
                  label: "Jurisdictions where the services are offered",
                  isMultipleChoice: true,
                  choices: countries,
                }),
                new YesNoQuestion({
                  key: "typeOfActivitiesInsuranceProductsDistributionActivities",
                  label:
                    "Do your activities constitute public distribution in the jurisdictions where you operate?",
                  Yes: [
                    new YesNoQuestion({
                      key: "typeOfActivitiesInsuranceProductsDistributionActivitiesYes",
                      label:
                        "Do you ensure that funds are locally registered for distribution?",
                    }),
                  ],
                }),
                new ChoiceQuestion({
                  key: "typeOfActivitiesInsuranceProductsTypeOfClients",
                  label: "Type of clients",
                  isMultipleChoice: true,
                  threshold: 7,
                  choices: [
                    ...toChoices([
                      "Retail clients",
                      "High Net Worth Individuals",
                      "Professional investors",
                      "Financial institutes",
                      "IFAs",
                      "Investors in insurance linked products/plans",
                    ]),
                    {
                      value: "Other",
                      questions: [
                        TextQuestion.Specify(
                          "typeOfActivitiesInsuranceProductsTypeOfClientsOther",
                        ),
                      ],
                    },
                  ],
                }),
              ],
            }),
            new BooleanQuestion({
              key: "typeOfActivitiesOther",
              label: "Other",
              questions: [
                new TextQuestion({
                  key: "typeofActivitiesOtherSpecify",
                  label: "Please specify",
                }),
                new ChoiceQuestion({
                  key: "typeOfActivitiesOtherCountriesOfOperations",
                  label: "Jurisdictions where the services are offered",
                  isMultipleChoice: true,
                  choices: countries,
                }),
                new YesNoQuestion({
                  key: "typeOfActivitiesOtherDistributionActivities",
                  label:
                    "Do your activities constitute public distribution in the jurisdictions where you operate?",
                  Yes: [
                    new YesNoQuestion({
                      key: "typeOfActivitiesOtherDistributionActivitiesYes",
                      label:
                        "Do you ensure that funds are locally registered for distribution?",
                    }),
                  ],
                }),
                new ChoiceQuestion({
                  key: "typeOfActivitiesOtherTypeOfClients",
                  label: "Type of clients",
                  isMultipleChoice: true,
                  threshold: 7,
                  choices: [
                    ...toChoices([
                      "Retail clients",
                      "High Net Worth Individuals",
                      "Professional investors",
                      "Financial institutes",
                      "IFAs",
                      "Investors in insurance linked products/plans",
                    ]),
                    {
                      value: "Other",
                      questions: [
                        TextQuestion.Specify(
                          "typeOfActivitiesOtherTypeOfClientsOther",
                        ),
                      ],
                    },
                  ],
                }),
              ],
            }),
          ],
        }),
        new QuestionGroup("Type of products", {
          questions: [
            new ChoiceQuestion({
              key: "typeOfProducts",
              label: "Choose all the activities for which you are regulated",
              isMultipleChoice: true,
              threshold: 7,
              choices: [
                ...toChoices([
                  "UCITS",
                  "Alternative Investment Funds (AIF)",
                  "Hedge Funds",
                  "CIS (Qualifying Collective Investment Scheme under the Asian CIS framework)",
                  "Structured products",
                ]),
                {
                  value: "Unit linked life insurance product",
                  questions: [
                    TextQuestion.Specify("typeOfProductsWrapped", true),
                  ],
                },
                {
                  value: "Other",
                  questions: [TextQuestion.Specify("typeOfFundsOther", true)],
                },
              ],
            }),
          ],
        }),
      ],
    }),
    new QuestionGroup("Distribution network", {
      questions: [
        new ChoiceQuestion({
          key: "natureOfSalesForce",
          label: "Nature of sales force",
          isMultipleChoice: true,
          choices: [
            ...toChoices([
              "Representative office",
              "Branch",
              "Subsidiary with own employees",
              "Tied agents",
            ]),
            {
              value: "Sub-distributors",
              questions: [
                new ChoiceQuestion({
                  key: "total-sub-distributors",
                  label: "Number of sub-distributors",
                  choices: toChoices([
                    "< 50",
                    "50-100",
                    "101-200",
                    "201-300",
                    "301-500",
                    "501-1000",
                    "1001-2000",
                    "> 2000",
                  ]),
                }),
                new ChoiceQuestion({
                  key: "typeSubDistrubutors",
                  label: "Type of Sub-distributors",
                  isMultipleChoice: true,
                  choices: [
                    ...toChoices([
                      "Financial Advisor (IFA)",
                      "Wealth Manager",
                      "Retail/Private Bank",
                      "Broker",
                      "Family Office",
                      "Multi Manager/Fund of Funds",
                      "Insurance Company",
                      "Life Insurance Company",
                      "Pension Scheme Manager",
                    ]),
                    {
                      value: "Fund Platform",
                      questions: [
                        new ChoiceQuestion({
                          key: "fund-plattform-sub-distributors",
                          label: "Type of fund platform",
                          choices: [
                            ...toChoices([
                              "Fund Supermarket (retail)",
                              "Financial Instutional Clients",
                              "Insurance Wrap Provider",
                            ]),
                            {
                              value: "Other",
                              questions: [
                                TextQuestion.Specify(
                                  "fund-plattform-sub-distributors-other",
                                ),
                              ],
                            },
                          ],
                        }),
                      ],
                    },
                    {
                      value: "Other",
                      questions: [
                        TextQuestion.Specify("other-sub-distributors"),
                      ],
                    },
                  ],
                }),
              ],
            },
            {
              value: "Other",
              questions: [TextQuestion.Specify("natureOfSalesForceOther")],
            },
          ],
        }),
        new ChoiceQuestion({
          key: "restrictedmarkets",
          label: "Black listed markets",
          isMultipleChoice: true,
          choices: countries,
        }),
      ],
    }),
  ],
};

export default category;
