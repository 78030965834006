import { initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: false,
    lng: "en",
  });

export default i18n;
