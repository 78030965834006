import type { IQuestionnaireItem, ItemRule } from "@app/constants";

import {
  checkLenRule,
  checkMaxRule,
  checkMinRule,
} from "./custom-questionnaire-rules";
import { messagesKeys } from "../messages";

export const validateItemRule = (
  item: IQuestionnaireItem, // TODO: use the item's type instead of typeof value
  rule: ItemRule,
  value: unknown,
  joi: any,
): string | null => {
  switch (rule.name) {
    case "required":
      // Since we use joi <presence flag to handle require, if we've made it here, value is not undefined
      return null;
    case "len": {
      return checkLenRule(item, value, rule.value);
    }
    case "max": {
      return checkMaxRule(item, value, rule.value);
    }
    case "min": {
      return checkMinRule(item, value, rule.value);
    }
    case "email": {
      const res = joi.string().tldEmail().validate(value);
      const { error } = res;
      return error ? messagesKeys.CQAnswers.stringNotEmail : null;
    }
    case "url": {
      const { error } = joi.string().url().validate(value);
      return error ? messagesKeys.CQAnswers.stringNotUrl : null;
    }
    case "lei": {
      const { error } = joi.string().lei().validate(value);
      return error ? messagesKeys.common.stringNotLei : null;
    }
  }
  return "Unknow rule";
};
