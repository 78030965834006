/* eslint-disable no-unused-vars */
enum BROADCAST_STATE {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}
enum BROADCAST_TYPE {
  INQUIRY = "BroadcastInquiry",
  STATEMENT = "BroadcastStatement",
}
enum BROADCAST_REQUEST_STATE {
  NEW = "NEW",
  READ = "READ",
  ANSWERED = "ANSWERED",
  REVIEWED = "REVIEWED",
}
enum BROADCAST_REQUEST_TYPE {
  INQUIRY = "BroadcastInquiryRequest",
  STATEMENT = "BroadcastStatementRequest",
}

export {
  BROADCAST_STATE,
  BROADCAST_TYPE,
  BROADCAST_REQUEST_STATE,
  BROADCAST_REQUEST_TYPE,
};
