import {
  QuestionnaireQuestion,
  IQuestionnaireQuestion,
} from "./QuestionnaireQuestion";
import { ChoiceQuestion } from "./ChoiceQuestion";

export interface IYesNoQuestion extends IQuestionnaireQuestion {
  /**
   *  Configure what should happen if yes is chosen. Allows for more questions to to show.
   */
  Yes?: QuestionnaireQuestion[];
  /**
   *  Configure what should happen if no is chosen. Allows for more questions to to show.
   */
  No?: QuestionnaireQuestion[];
}
/**
 * A Yes|No Question is a type of choice question with only 2 valid options (Yes or No)
 */
export class YesNoQuestion extends ChoiceQuestion {
  constructor({ Yes, No, ...options }: IYesNoQuestion) {
    super({
      ...options,
      isMultipleChoice: false,
      choices: [
        ChoiceQuestion.Choice("Yes", {
          questions: Yes,
        }),
        ChoiceQuestion.Choice("No", {
          questions: No,
        }),
      ],
    });
  }
}
