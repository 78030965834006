import { Observer, useLocalObservable } from "mobx-react";
import { pick } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import gql from "graphql-tag";
import React from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  Grid,
  Icon,
  Loading,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { Text } from "@gfw/orion";

import { NewsProviderForm } from "@app/resources/News/components";
import { newsProviderModel } from "@app/resources/News/utils";
import {
  Page,
  PageTitle,
  PageTitleTypo,
} from "@app/resources/generic/components";

const NEWSPROVIDER_QUERY = gql`
  query NewsProvider($id: ID!) {
    NewsProvider(id: $id) {
      id
      name
      homepage
      image
      iso2
    }
  }
`;

function EditNewsProviderPage() {
  const { id } = useParams();

  const navigate = useNavigate();

  const { isLoading } = useQuery(
    ["newsArticle"],
    async () => {
      const response = await apiClient.graphql(NEWSPROVIDER_QUERY, {
        variables: { id },
      });

      if (response?.NewsProvider) {
        const { NewsProvider } = response;
        const providerData = pick(NewsProvider, [
          "name",
          "homepage",
          "image",
          "iso2",
        ]);

        provider.updateAll(providerData);
      } else {
        return { error: "error" };
      }
    },
    { refetchOnWindowFocus: false },
  );

  const provider = useLocalObservable(() => newsProviderModel);

  const editNewsProviderHandler = async () => {
    try {
      await apiClient.patch(`/news/providers/${id}`, provider.getSnapshot());
      notification.success({ message: `The provider has been edited` });
    } catch (error) {
      __DEPRECATED__ErrorHandler(error);
    }
  };

  const deleteNewsProviderHandler = async () => {
    try {
      await apiClient.delete(`/news/providers/${id}`);
      notification.success({ message: `The provider has been deleted` });
      navigate("/news");
    } catch (error) {
      __DEPRECATED__ErrorHandler(error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <PageTitle>
        <Icon mr="sm" type="news" />
        <PageTitleTypo>Edit News Provider</PageTitleTypo>
      </PageTitle>
      <Card>
        <Grid.Row gutter={64}>
          <Grid.Col flex={4}>
            <NewsProviderForm provider={provider} />
            <Observer>
              {() => (
                <Button
                  disabled={provider.invalid}
                  mt="lg2"
                  onClick={editNewsProviderHandler}
                  type="primary"
                >
                  Update
                </Button>
              )}
            </Observer>
          </Grid.Col>
          <Grid.Col flex={1}>
            <Text>Actions</Text>
            <Button
              block
              danger
              mt="sm"
              onClick={deleteNewsProviderHandler}
              type="primary"
            >
              Delete
            </Button>
          </Grid.Col>
        </Grid.Row>
      </Card>
    </Page>
  );
}

export { EditNewsProviderPage };
