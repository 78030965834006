import { sortBy, compact, difference, clone } from "lodash";

import categories from "./categories";
import {
  QuestionnaireCategory,
  IQuestionnaireCategory,
} from "./categories/QuestionnaireCategory";
import warning from "tiny-warning";
import { Questionnaire } from "./Questionnaire";
import { QuestionGroup } from "./questions/QuestionGroup";
import { QuestionnaireQuestion } from "./questions";
import {
  convertSnapshotToQuestion,
  isQuestionGroup,
} from "./questionnaire-operations";
import { QUESTIONNAIRE_CATEGORIES } from "../constants/values/questionnaire";
import { byId } from "../profile-types/profile-types";
import { getConfig } from "../config";

const KNOWN_CATEGORIES_WE_DONT_SUPPORT = [
  QUESTIONNAIRE_CATEGORIES.SUMMARY_1.id,
  QUESTIONNAIRE_CATEGORIES.SUMMARY_2.id,
];
const { isProduction } = getConfig();

function getCategoryById(id: string) {
  const category = categories.find((category) => category.id === id);

  if (!isProduction) {
    warning(
      category,
      `[@gfw:questionnaire] Category with id "${id}" has no mapping. This might indicate an error in the DB of profile types.`,
    );
  }
  return clone(category);
}

function hasAnyQuestions<T extends QuestionGroup | QuestionnaireCategory>(
  source: T,
) {
  return source.getQuestions().length > 0;
}

// filters the questions for a category or group so it only includes the specified questions
function includeOnlyQuestionsThatMatchTheseKeys<
  T extends QuestionGroup | QuestionnaireCategory,
>(source: T, keys: string[]) {
  const _target = clone(source);

  const _questions = source.questions.reduce<QuestionnaireQuestion[]>(
    (questions, question) => {
      if (isQuestionGroup(question)) {
        const _group = includeOnlyQuestionsThatMatchTheseKeys(question, keys);
        if (hasAnyQuestions(_group)) {
          return [...questions, _group];
        } else {
          return questions;
        }
      } else if (keys.includes(question.key)) {
        return [...questions, question];
      } else {
        return questions;
      }
    },
    [],
  );

  _target.questions = _questions;

  return _target;
}

function buildQuestionnaireFromQuestions(keys: string[]): Questionnaire {
  // we're going to build a questionnaire that only has the questions and categories that these keys represent
  const _categories = categories.reduce<QuestionnaireCategory[]>(
    (categories, category) => {
      const _category = includeOnlyQuestionsThatMatchTheseKeys(category, keys);

      if (hasAnyQuestions(_category)) {
        return [...categories, _category];
      } else {
        return categories;
      }
    },
    [],
  );

  return new Questionnaire(sortBy(_categories, "position"));
}

function buildQuestionnaireFromProfileType(profileType: string): Questionnaire {
  const ProfileCategory = byId(profileType);

  return buildQuestionnaireFromCategories(ProfileCategory.forms);
}

function buildQuestionnaireFromCategories(
  ids: readonly string[],
): Questionnaire {
  // build the questionnaire by mapping the ids we got to categories
  const _categories = compact(
    difference(ids, KNOWN_CATEGORIES_WE_DONT_SUPPORT).map((id) =>
      getCategoryById(id),
    ),
  );

  return new Questionnaire(sortBy(_categories, "position"));
}

function buildQuestionnaireFromSnapshot(snapshot: {
  categories: IQuestionnaireCategory[];
}): Questionnaire {
  const snapshotCategories = snapshot.categories.map((category) => {
    return new QuestionnaireCategory({
      ...category,
      questions: category.questions.map((question) => {
        return convertSnapshotToQuestion(question);
      }),
      updatedAt: category.updatedAt,
    });
  });

  return new Questionnaire(sortBy(snapshotCategories, "position"));
}

// a questionnaire can be built/created in these ways:
//  1. from the top => through categories
//  2. from the bottom => through questions
//  3. from a snapshot
//  4. from a profile type
export {
  buildQuestionnaireFromProfileType,
  buildQuestionnaireFromQuestions,
  buildQuestionnaireFromCategories,
  buildQuestionnaireFromSnapshot,
};
