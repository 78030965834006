export default [
  {
    name: "Afghanistan",
    code: "AF",
    location: {
      mapType: "asia",
      coordinates: [69.11, 34.28],
    },
  },
  { name: "Åland Islands", code: "AX" },
  {
    name: "Albania",
    code: "AL",
    location: {
      mapType: "europe",
      coordinates: [19.49, 41.18],
    },
  },
  {
    name: "Algeria",
    code: "DZ",
    location: {
      mapType: "africa",
      coordinates: [3.08, 36.42],
    },
  },
  {
    name: "American Samoa",
    code: "AS",
    location: {
      mapType: "asia",
      coordinates: [170.43, 14.16],
    },
  },
  {
    name: "Andorra",
    code: "AD",
    location: {
      mapType: "europe",
      coordinates: [1.32, 42.31],
    },
  },
  {
    name: "Angola",
    code: "AO",
    location: {
      mapType: "africa",
      coordinates: [13.15, 8.5],
    },
  },
  {
    name: "Anguilla",
    code: "AI",
    location: {
      mapType: "north-america",
      coordinates: [-63, 18.2233],
    },
  },
  {
    name: "Antarctica",
    code: "AQ",
    location: {
      mapType: "world",
      coordinates: [-58.86386407, -62.17388669],
    },
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    location: {
      mapType: "north-america",
      coordinates: [-61.48, 17.2],
    },
  },
  {
    name: "Argentina",
    code: "AR",
    location: {
      mapType: "south-america",
      coordinates: [-58.3816, -34.6037],
    },
  },
  {
    name: "Armenia",
    code: "AM",
    location: {
      mapType: "europe",
      coordinates: [44.31, 40.1],
    },
  },
  {
    name: "Aruba",
    code: "AW",
    location: {
      mapType: "north-america",
      coordinates: [70.02, 12.32],
    },
  },
  {
    name: "Australia",
    code: "AU",
    location: {
      mapType: "asia",
      coordinates: [149.08, -35.15],
    },
  },
  {
    name: "Austria",
    code: "AT",
    location: {
      mapType: "europe",
      coordinates: [16.22, 48.12],
    },
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    location: {
      mapType: "europe",
      coordinates: [49.56, 40.29],
    },
  },
  {
    name: "Bahamas",
    code: "BS",
    location: {
      mapType: "central-america",
      coordinates: [-77.2, 25.05],
    },
  },
  {
    name: "Bahrain",
    code: "BH",
    location: {
      mapType: "asia",
      coordinates: [50.3, 26.1],
    },
  },
  {
    name: "Bangladesh",
    code: "BD",
    location: {
      mapType: "asia",
      coordinates: [90.26, 23.43],
    },
  },
  {
    name: "Barbados",
    code: "BB",
    location: {
      mapType: "central-america",
      coordinates: [-59.3, 13.05],
    },
  },
  {
    name: "Belarus",
    code: "BY",
    location: {
      mapType: "europe",
      coordinates: [27.3, 53.52],
    },
  },
  {
    name: "Belgium",
    code: "BE",
    location: {
      mapType: "europe",
      coordinates: [4.21, 50.51],
    },
  },
  {
    name: "Belize",
    code: "BZ",
    location: {
      mapType: "central-america",
      coordinates: [-88.3, 17.18],
    },
  },
  {
    name: "Benin",
    code: "BJ",
    location: {
      mapType: "africa",
      coordinates: [2.42, 6.23],
    },
  },
  {
    name: "Bermuda",
    code: "BM",
    location: {
      mapType: "north-america",
      coordinates: [-64.78393742, 32.29419029],
    },
  },
  {
    name: "Bhutan",
    code: "BT",
    location: {
      mapType: "asia",
      coordinates: [89.45, 27.31],
    },
  },
  {
    name: "Bolivia",
    code: "BO",
    location: {
      mapType: "south-america",
      coordinates: [-68.1193, -16.4897],
    },
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    location: {
      mapType: "europe",
      coordinates: [18.26, 43.52],
    },
  },
  {
    name: "Botswana",
    code: "BW",
    location: {
      mapType: "africa",
      coordinates: [25.57, 24.45],
    },
  },
  {
    name: "Bouvet Island",
    code: "BV",
    location: {
      mapType: "world",
      coordinates: [3.413194, -54.423199],
    },
  },
  {
    name: "Brazil",
    code: "BR",
    location: {
      mapType: "south-america",
      coordinates: [-47.8825, -15.7942],
    },
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    location: {
      mapType: "africa",
      coordinates: [72.422859, -7.3195],
    },
  },
  {
    name: "British Virgin Islands",
    code: "VG",
    location: {
      mapType: "north-america",
      coordinates: [-64.618103, 18.436539],
    },
  },
  {
    name: "Brunei Darussalam",
    code: "BN",
    location: {
      mapType: "asia",
      coordinates: [115, 4.52],
    },
  },
  {
    name: "Bulgaria",
    code: "BG",
    location: {
      mapType: "europe",
      coordinates: [23.2, 42.45],
    },
  },
  {
    name: "Burkina Faso",
    code: "BF",
    location: {
      mapType: "africa",
      coordinates: [1.3, 12.15],
    },
  },
  {
    name: "Burundi",
    code: "BI",
    location: {
      mapType: "africa",
      coordinates: [29.18, 3.16],
    },
  },
  {
    name: "Cambodia",
    code: "KH",
    location: {
      mapType: "asia",
      coordinates: [104.55, 11.33],
    },
  },
  {
    name: "Cameroon",
    code: "CM",
    location: {
      mapType: "africa",
      coordinates: [11.35, 3.5],
    },
  },
  {
    name: "Canada",
    code: "CA",
    location: {
      mapType: "north-america",
      coordinates: [-96.88332178, 50.15002545],
    },
  },
  {
    name: "Cape Verde",
    code: "CV",
    location: {
      mapType: "africa",
      coordinates: [-25.0000092, 16.88376141],
    },
  },
  {
    name: "Cayman Islands",
    code: "KY",
    location: {
      mapType: "north-america",
      coordinates: [-81.24, 19.2],
    },
  },
  {
    name: "Central African Republic",
    code: "CF",
    location: {
      mapType: "africa",
      coordinates: [18.35, 4.23],
    },
  },
  {
    name: "Chad",
    code: "TD",
    location: {
      mapType: "africa",
      coordinates: [14.59, 12.1],
    },
  },
  {
    name: "Chile",
    code: "CL",
    location: {
      mapType: "south-america",
      coordinates: [-70.6693, -33.4489],
    },
  },
  {
    name: "China",
    code: "CN",
    location: {
      mapType: "asia",
      coordinates: [116.2, 39.55],
    },
  },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  {
    name: "Colombia",
    code: "CO",
    location: {
      mapType: "south-america",
      coordinates: [-74.0721, 4.711],
    },
  },
  {
    name: "Comoros",
    code: "KM",
    location: {
      mapType: "africa",
      coordinates: [43.233333, -11.7],
    },
  },
  {
    name: "Congo",
    code: "CG",
    location: {
      mapType: "africa",
      coordinates: [13.55400049, -4.164002942],
    },
  },
  { name: "Cook Islands", code: "CK" },
  {
    name: "Costa Rica",
    code: "CR",
    location: {
      mapType: "central-america",
      coordinates: [-84.02, 9.55],
    },
  },
  {
    name: "Cote D'Ivoire",
    code: "CI",
    location: {
      mapType: "africa",
      coordinates: [5.17, 6.49],
    },
  },
  {
    name: "Croatia",
    code: "HR",
    location: {
      mapType: "europe",
      coordinates: [15.58, 45.5],
    },
  },
  {
    name: "Cuba",
    code: "CU",
    location: {
      mapType: "central-america",
      coordinates: [-82.22, 23.08],
    },
  },
  {
    name: "Cyprus",
    code: "CY",
    location: {
      mapType: "europe",
      coordinates: [33.25, 35.1],
    },
  },
  {
    name: "Czech Republic",
    code: "CZ",
    location: {
      mapType: "europe",
      coordinates: [14.22, 50.05],
    },
  },
  {
    name: "Denmark",
    code: "DK",
    location: {
      mapType: "europe",
      coordinates: [12.34, 55.41],
    },
  },
  {
    name: "Djibouti",
    code: "DJ",
    location: {
      mapType: "africa",
      coordinates: [42.2, 11.08],
    },
  },
  {
    name: "Dominica",
    code: "DM",
    location: {
      mapType: "north-america",
      coordinates: [-61.24, 15.2],
    },
  },
  {
    name: "Dominican Republic",
    code: "DO",
    location: {
      mapType: "central-america",
      coordinates: [-69.59, 18.3],
    },
  },
  {
    name: "Ecuador",
    code: "EC",
    location: {
      mapType: "south-america",
      coordinates: [-78.4678, -0.1807],
    },
  },
  {
    name: "Egypt",
    code: "EG",
    location: {
      mapType: "africa",
      coordinates: [31.14, 30.01],
    },
  },
  {
    name: "El Salvador",
    code: "SV",
    location: {
      mapType: "central-america",
      coordinates: [-88.9167, 13.8333],
    },
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    location: {
      mapType: "africa",
      coordinates: [8.5, 3.45],
    },
  },
  {
    name: "Eritrea",
    code: "ER",
    location: {
      mapType: "africa",
      coordinates: [38.55, 15.19],
    },
  },
  {
    name: "Estonia",
    code: "EE",
    location: {
      mapType: "europe",
      coordinates: [24.48, 59.22],
    },
  },
  {
    name: "Ethiopia",
    code: "ET",
    location: {
      mapType: "africa",
      coordinates: [38.42, 9.02],
    },
  },
  {
    name: "Falkland Islands (Malvinas)",
    code: "FK",
    location: {
      mapType: "south-america",
      coordinates: [-59.51, -51.4],
    },
  },
  {
    name: "Faroe Islands",
    code: "FO",
    location: {
      mapType: "europe",
      coordinates: [-6.56, 62.05],
    },
  },
  {
    name: "Fiji",
    code: "FJ",
    location: {
      mapType: "world",
      coordinates: [178.3, -18.06],
    },
  },
  {
    name: "Finland",
    code: "FI",
    location: {
      mapType: "europe",
      coordinates: [25.03, 60.15],
    },
  },
  {
    name: "France",
    code: "FR",
    location: {
      mapType: "europe",
      coordinates: [2.2, 48.5],
    },
  },
  {
    name: "French Guiana",
    code: "GF",
    location: {
      mapType: "south-america",
      coordinates: [52.18, 5.05],
    },
  },
  {
    name: "French Polynesia",
    code: "PF",
    location: {
      mapType: "world",
      coordinates: [-149.34, -17.32],
    },
  },
  { name: "French Southern Territories", code: "TF" },
  {
    name: "Gabon",
    code: "GA",
    location: {
      mapType: "africa",
      coordinates: [4.164002942, 2.152997996],
    },
  },
  {
    name: "Gambia",
    code: "GM",
    location: {
      mapType: "world",
      coordinates: [16.4, 13.28],
    },
  },
  {
    name: "Georgia",
    code: "GE",
    location: {
      mapType: "europe",
      coordinates: [44.5, 41.43],
    },
  },
  {
    name: "Germany",
    code: "DE",
    location: {
      mapType: "europe",
      coordinates: [13.25, 52.3],
    },
  },
  {
    name: "Ghana",
    code: "GH",
    location: {
      mapType: "africa",
      coordinates: [-2.336003416, 7.335998991],
    },
  },
  {
    name: "Gibraltar",
    code: "GI",
    location: {
      mapType: "europe",
      coordinates: [-5.37807483, 36.13243495],
    },
  },
  {
    name: "Greece",
    code: "GR",
    location: {
      mapType: "europe",
      coordinates: [23.46, 37.58],
    },
  },
  {
    name: "Greenland",
    code: "GL",
    location: {
      mapType: "central-america",
      coordinates: [-51.35, 64.1],
    },
  },
  {
    name: "Grenada",
    code: "GD",
    location: {
      mapType: "central-america",
      coordinates: [-61.75, 12.05],
    },
  },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  {
    name: "Guatemala",
    code: "GT",
    location: {
      mapType: "central-america",
      coordinates: [-90.522713, 14.4],
    },
  },
  {
    name: "Guernsey",
    code: "GG",
    location: {
      mapType: "europe",
      coordinates: [2.33, 49.26],
    },
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    location: {
      mapType: "africa",
      coordinates: [-15.45, 11.45],
    },
  },
  {
    name: "Guinea",
    code: "GN",
    location: {
      mapType: "africa",
      coordinates: [-13.49, 9.29],
    },
  },
  {
    name: "Guyana",
    code: "GY",
    location: {
      mapType: "south-america",
      coordinates: [-58.12, 6.5],
    },
  },
  {
    name: "Haiti",
    code: "HT",
    location: {
      mapType: "central-america",
      coordinates: [-72.2, 18.4],
    },
  },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  {
    name: "Honduras",
    code: "HN",
    location: {
      mapType: "central-america",
      coordinates: [-87.202438, 14.05],
    },
  },
  {
    name: "Hong Kong",
    code: "HK",
    location: {
      mapType: "asia",
      coordinates: [114.1850093, 22.3049809],
    },
  },
  {
    name: "Hungary",
    code: "HU",
    location: {
      mapType: "europe",
      coordinates: [19.05, 47.29],
    },
  },
  {
    name: "Iceland",
    code: "IS",
    location: {
      mapType: "europe",
      coordinates: [-21.57, 64.1],
    },
  },
  {
    name: "India",
    code: "IN",
    location: {
      mapType: "asia",
      coordinates: [73.81800065, 15.491997],
    },
  },
  {
    name: "Indonesia",
    code: "ID",
    location: {
      mapType: "asia",
      coordinates: [106.49, 6.09],
    },
  },
  {
    name: "Iran",
    code: "IR",
    location: {
      mapType: "asia",
      coordinates: [51.3, 35.44],
    },
  },
  {
    name: "Iraq",
    code: "IQ",
    location: {
      mapType: "asia",
      coordinates: [44.3, 33.2],
    },
  },
  {
    name: "Ireland",
    code: "IE",
    location: {
      mapType: "europe",
      coordinates: [-6.15, 53.21],
    },
  },
  { name: "Isle of Man", code: "IM" },
  {
    name: "Israel",
    code: "IL",
    location: {
      mapType: "asia",
      coordinates: [35.12, 31.47],
    },
  },
  {
    name: "Italy",
    code: "IT",
    location: {
      mapType: "europe",
      coordinates: [12.29, 41.54],
    },
  },
  {
    name: "Jamaica",
    code: "JM",
    location: {
      mapType: "central-america",
      coordinates: [-76.7936, 18],
    },
  },
  {
    name: "Japan",
    code: "JP",
    location: {
      mapType: "asia",
      coordinates: [133.9170865, 34.67202964],
    },
  },
  {
    name: "Jersey",
    code: "JE",
    location: {
      mapType: "europe",
      coordinates: [-2.1, 49.18333333],
    },
  },
  {
    name: "Jordan",
    code: "JO",
    location: {
      mapType: "asia",
      coordinates: [35.52, 31.57],
    },
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    location: {
      mapType: "asia",
      coordinates: [71.3, 51.1],
    },
  },
  {
    name: "Kenya",
    code: "KE",
    location: {
      mapType: "africa",
      coordinates: [36.48, -1.17],
    },
  },
  {
    name: "Kiribati",
    code: "KI",
    location: {
      mapType: "world",
      coordinates: [173, 1.3],
    },
  },
  {
    name: "Kuwait",
    code: "KW",
    location: {
      mapType: "asia",
      coordinates: [48, 29.3],
    },
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    location: {
      mapType: "asia",
      coordinates: [74.46, 42.54],
    },
  },
  {
    name: "Laos",
    code: "LA",
    location: {
      mapType: "asia",
      coordinates: [102.36, 17.58],
    },
  },
  {
    name: "Latvia",
    code: "LV",
    location: {
      mapType: "europe",
      coordinates: [24.08, 56.53],
    },
  },
  {
    name: "Lebanon",
    code: "LB",
    location: {
      mapType: "asia",
      coordinates: [35.31, 33.53],
    },
  },
  {
    name: "Lesotho",
    code: "LS",
    location: {
      mapType: "africa",
      coordinates: [27.3, -29.18],
    },
  },
  {
    name: "Liberia",
    code: "LR",
    location: {
      mapType: "africa",
      coordinates: [-10.47, 6.18],
    },
  },
  {
    name: "Libyan Arab Jamahiriya",
    code: "LY",
    location: {
      mapType: "africa",
      coordinates: [13.07, 32.49],
    },
  },
  {
    name: "Liechtenstein",
    code: "LI",
    location: {
      mapType: "europe",
      coordinates: [9.31, 47.08],
    },
  },
  {
    name: "Lithuania",
    code: "LT",
    location: {
      mapType: "europe",
      coordinates: [25.19, 54.38],
    },
  },
  {
    name: "Luxembourg",
    code: "LU",
    location: {
      mapType: "europe",
      coordinates: [6.09, 49.37],
    },
  },
  {
    name: "Macao",
    code: "MO",
    location: {
      mapType: "asia",
      coordinates: [113.33, 22.12],
    },
  },
  {
    name: "Macedonia",
    code: "MK",
    location: {
      mapType: "europe",
      coordinates: [20.9700789, 42.00923037],
    },
  },
  {
    name: "Madagascar",
    code: "MG",
    location: {
      mapType: "africa",
      coordinates: [50.16659135, -14.26617186],
    },
  },
  {
    name: "Malawi",
    code: "MW",
    location: {
      mapType: "africa",
      coordinates: [33.48, -14.0],
    },
  },
  {
    name: "Malaysia",
    code: "MY",
    location: {
      mapType: "asia",
      coordinates: [101.41, 3.09],
    },
  },
  {
    name: "Maldives",
    code: "MV",
    location: {
      mapType: "asia",
      coordinates: [73.28, 4],
    },
  },
  {
    name: "Mali",
    code: "ML",
    location: {
      mapType: "africa",
      coordinates: [-3.666582684, 16.41699404],
    },
  },
  {
    name: "Malta",
    code: "MT",
    location: {
      mapType: "europe",
      coordinates: [14.51471065, 35.89973248],
    },
  },
  {
    name: "Marshall Islands",
    code: "MH",
    location: {
      mapType: "world",
      coordinates: [171.3800002, 7.103004311],
    },
  },
  {
    name: "Martinique",
    code: "MQ",
    location: {
      mapType: "north-america",
      coordinates: [6.09, 49.37],
    },
  },
  {
    name: "Mauritania",
    code: "MR",
    location: {
      mapType: "africa",
      coordinates: [-12.70700053, 22.67900113],
    },
  },
  {
    name: "Mauritius",
    code: "MU",
    location: {
      mapType: "africa",
      coordinates: [57.51663367, -20.31619017],
    },
  },
  {
    name: "Mayotte",
    code: "YT",
  },
  {
    name: "Mexico",
    code: "MX",
    location: {
      mapType: "north-america",
      coordinates: [-116.0092603, 30.76405113],
    },
  },
  {
    name: "Micronesia",
    code: "FM",
  },
  {
    name: "Moldova",
    code: "MD",
    location: {
      mapType: "europe",
      coordinates: [28.907089, 47.00367],
    },
  },
  {
    name: "Monaco",
    code: "MC",
    location: {
      mapType: "europe",
      coordinates: [7.406913173, 43.73964569],
    },
  },
  {
    name: "Mongolia",
    code: "MN",
    location: {
      mapType: "asia",
      coordinates: [106.2000006, 50.24999712],
    },
  },
  { name: "Montserrat", code: "MS" },
  {
    name: "Morocco",
    code: "MA",
    location: {
      mapType: "africa",
      coordinates: [-5.909985801, 35.02038047],
    },
  },
  {
    name: "Mozambique",
    code: "MZ",
    location: {
      mapType: "africa",
      coordinates: [33.95001013, -16.09954832],
    },
  },
  {
    name: "Myanmar",
    code: "MM",
    location: {
      mapType: "asia",
      coordinates: [98, 22],
    },
  },
  {
    name: "Namibia",
    code: "NA",
    location: {
      mapType: "africa",
      coordinates: [15.95099754, -21.43600193],
    },
  },
  { name: "Nauru", code: "NR" },
  {
    name: "Nepal",
    code: "NP",
    location: {
      mapType: "asia",
      coordinates: [82.18330255, 28.35000004],
    },
  },
  {
    name: "Netherlands Antilles",
    code: "AN",
  },
  {
    name: "Netherlands",
    code: "NL",
    location: {
      mapType: "europe",
      coordinates: [6.550002585, 53.00000109],
    },
  },
  {
    name: "New Caledonia",
    code: "NC",
    location: {
      mapType: "world",
      coordinates: [166.4442852, -22.26252776],
    },
  },
  {
    name: "New Zealand",
    code: "NZ",
    location: {
      mapType: "world",
      coordinates: [174.46, 41.19],
    },
  },
  {
    name: "Nicaragua",
    code: "NI",
    location: {
      mapType: "central-america",
      coordinates: [-86.251389, 12.06],
    },
  },
  {
    name: "Niger",
    code: "NE",
    location: {
      mapType: "africa",
      coordinates: [2.06, 13.27],
    },
  },
  {
    name: "Nigeria",
    code: "NG",
    location: {
      mapType: "africa",
      coordinates: [7.32, 9.05],
    },
  },
  { name: "Niue", code: "NU" },
  {
    name: "Norfolk Island",
    code: "NF",
    location: {
      mapType: "world",
      coordinates: [168.43, 45.2],
    },
  },
  { name: "North Korea", code: "KP" },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    location: {
      mapType: "world",
      coordinates: [145.45, 15.12],
    },
  },
  {
    name: "Norway",
    code: "NO",
    location: {
      mapType: "europe",
      coordinates: [10.45, 59.55],
    },
  },
  {
    name: "Oman",
    code: "OM",
    location: {
      mapType: "asia",
      coordinates: [58.36, 23.37],
    },
  },
  {
    name: "Pakistan",
    code: "PK",
    location: {
      mapType: "asia",
      coordinates: [73.1, 33.4],
    },
  },
  {
    name: "Palau",
    code: "PW",
    location: {
      mapType: "world",
      coordinates: [134.28, 7.2],
    },
  },
  {
    name: "Palestinian Territories",
    code: "PS",
    location: {
      mapType: "asia",
      coordinates: [35.25, 32],
    },
  },
  {
    name: "Panama",
    code: "PA",
    location: {
      mapType: "central-america",
      coordinates: [-79.25, 9],
    },
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    location: {
      mapType: "world",
      coordinates: [147.08, 9.24],
    },
  },
  {
    name: "Paraguay",
    code: "PY",
    location: {
      mapType: "south-america",
      coordinates: [-57.3, -25.1],
    },
  },
  {
    name: "Peru",
    code: "PE",
    location: {
      mapType: "south-america",
      coordinates: [-77, -12],
    },
  },
  {
    name: "Philippines",
    code: "PH",
    location: {
      mapType: "asia",
      coordinates: [121.03, 14.4],
    },
  },
  { name: "Pitcairn", code: "PN" },
  {
    name: "Poland",
    code: "PL",
    location: {
      mapType: "europe",
      coordinates: [21.0, 52.13],
    },
  },
  {
    name: "Portugal",
    code: "PT",
    location: {
      mapType: "europe",
      coordinates: [-9.1, 38.42],
    },
  },
  {
    name: "Puerto Rico",
    code: "PR",
    location: {
      mapType: "central-america",
      coordinates: [-66.07, 18.28],
    },
  },
  {
    name: "Qatar",
    code: "QA",
    location: {
      mapType: "asia",
      coordinates: [51.35, 25.15],
    },
  },
  { name: "Reunion", code: "RE" },
  {
    name: "Romania",
    code: "RO",
    location: {
      mapType: "europe",
      coordinates: [26.1, 44.27],
    },
  },
  {
    name: "Russian Federation",
    code: "RU",
    location: {
      mapType: "world",
      coordinates: [37.35, 55.45],
    },
  },
  {
    name: "Rwanda",
    code: "RW",
    location: {
      mapType: "africa",
      coordinates: [30.04, -1.59],
    },
  },
  { name: "Saint Helena", code: "SH" },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    location: {
      mapType: "north-america",
      coordinates: [-62.43, 17.17],
    },
  },
  {
    name: "Saint Lucia",
    code: "LC",
    location: {
      mapType: "north-america",
      coordinates: [-60.58, 14.02],
    },
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    location: {
      mapType: "world",
      coordinates: [56.12, 46.46],
    },
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    location: {
      mapType: "world",
      coordinates: [-61.1, 13.1],
    },
  },
  {
    name: "Samoa",
    code: "WS",
    location: {
      mapType: "world",
      coordinates: [-171.5, -13.5],
    },
  },
  {
    name: "San Marino",
    code: "SM",
    location: {
      mapType: "europe",
      coordinates: [12.3, 43.55],
    },
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    location: {
      mapType: "asia",
      coordinates: [46.42, 24.41],
    },
  },
  {
    name: "Senegal",
    code: "SN",
    location: {
      mapType: "africa",
      coordinates: [17.29, 14.34],
    },
  },
  {
    name: "Serbia and Montenegro",
    code: "CS",
    location: {
      mapType: "europe",
      coordinates: [19.68002844, 46.07001609],
    },
  },
  { name: "Seychelles", code: "SC" },
  {
    name: "Sierra Leone",
    code: "SL",
    location: {
      mapType: "africa",
      coordinates: [-13.17, 8.3],
    },
  },
  {
    name: "Singapore",
    code: "SG",
    location: {
      mapType: "asia",
      coordinates: [103.8558207, 1.293033466],
    },
  },
  {
    name: "Slovakia",
    code: "SK",
    location: {
      mapType: "europe",
      coordinates: [17.07, 48.1],
    },
  },
  {
    name: "Slovenia",
    code: "SI",
    location: {
      mapType: "europe",
      coordinates: [14.33, 46.04],
    },
  },
  {
    name: "Solomon Islands",
    code: "SB",
    location: {
      mapType: "world",
      coordinates: [159.57, -9.27],
    },
  },
  {
    name: "Somalia",
    code: "SO",
    location: {
      mapType: "africa",
      coordinates: [45.25, 2.02],
    },
  },
  {
    name: "South Africa",
    code: "ZA",
    location: {
      mapType: "africa",
      coordinates: [28.12, -25.44],
    },
  },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  {
    name: "South Korea",
    code: "KR",
    location: {
      mapType: "asia",
      coordinates: [127.6897147, 36.93525067],
    },
  },
  {
    name: "Spain",
    code: "ES",
    location: {
      mapType: "europe",
      coordinates: [-3.45, 40.25],
    },
  },
  {
    name: "Sri Lanka",
    code: "LK",
    location: {
      mapType: "asia",
      coordinates: [81, 7],
    },
  },
  {
    name: "Sudan",
    code: "SD",
    location: {
      mapType: "africa",
      coordinates: [32.35, 15.31],
    },
  },
  {
    name: "Suriname",
    code: "SR",
    location: {
      mapType: "south-america",
      coordinates: [-55.1, 5.5],
    },
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    location: {
      mapType: "europe",
      coordinates: [15.723717, 78.698105],
    },
  },
  {
    name: "Swaziland",
    code: "SZ",
    location: {
      mapType: "africa",
      coordinates: [31.06, -26.18],
    },
  },
  {
    name: "Sweden",
    code: "SE",
    location: {
      mapType: "europe",
      coordinates: [18.03, 59.2],
    },
  },
  {
    name: "Switzerland",
    code: "CH",
    location: {
      mapType: "europe",
      coordinates: [7.28, 46.57],
    },
  },
  {
    name: "Syrian Arab Republic",
    code: "SY",
    location: {
      mapType: "asia",
      coordinates: [38, 35],
    },
  },
  {
    name: "Taiwan",
    code: "TW",
    location: {
      mapType: "asia",
      coordinates: [121.2988889, 24.9575],
    },
  },
  {
    name: "Tajikistan",
    code: "TJ",
    location: {
      mapType: "asia",
      coordinates: [68.48, 38.33],
    },
  },
  {
    name: "Tanzania",
    code: "TZ",
    location: {
      mapType: "africa",
      coordinates: [35, -6],
    },
  },
  {
    name: "Thailand",
    code: "TH",
    location: {
      mapType: "asia",
      coordinates: [100.35, 13.45],
    },
  },
  { name: "Timor-Leste", code: "TL" },
  {
    name: "Togo",
    code: "TG",
    location: {
      mapType: "africa",
      coordinates: [1.2, 6.09],
    },
  },
  { name: "Tokelau", code: "TK" },
  {
    name: "Tonga",
    code: "TO",
    location: {
      mapType: "world",
      coordinates: [-174, -21.1],
    },
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    location: {
      mapType: "central-america",
      coordinates: [-61, 11],
    },
  },
  {
    name: "Tunisia",
    code: "TN",
    location: {
      mapType: "africa",
      coordinates: [10.11, 36.5],
    },
  },
  {
    name: "Turkey",
    code: "TR",
    location: {
      mapType: "europe",
      coordinates: [32.54, 39.57],
    },
  },
  {
    name: "Turkmenistan",
    code: "TM",
    location: {
      mapType: "asia",
      coordinates: [57.5, 38],
    },
  },
  { name: "Turks and Caicos Islands", code: "TC" },
  {
    name: "Tuvalu",
    code: "TV",
    location: {
      mapType: "world",
      coordinates: [179.13, -8.31],
    },
  },
  {
    name: "U.S. Virgin Islands",
    code: "VI",
    location: {
      mapType: "north-america",
      coordinates: [32.3, -0.2],
    },
  },
  {
    name: "Uganda",
    code: "UG",
    location: {
      mapType: "africa",
      coordinates: [32.3, -0.2],
    },
  },
  {
    name: "Ukraine",
    code: "UA",
    location: {
      mapType: "europe",
      coordinates: [30.28, 50.3],
    },
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    location: {
      mapType: "asia",
      coordinates: [54.22, 24.28],
    },
  },
  {
    name: "United Kingdom",
    code: "GB",
    location: {
      mapType: "europe",
      coordinates: [0.05, 51.36],
    },
  },
  { name: "United States Minor Outlying Islands", code: "UM" },
  {
    name: "United States",
    code: "US",
    location: {
      mapType: "north-america",
      coordinates: [-77.02, 39.91],
    },
  },
  {
    name: "Uruguay",
    code: "UY",
    location: {
      mapType: "south-america",
      coordinates: [-56.1645, -34.9011],
    },
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    location: {
      mapType: "asia",
      coordinates: [69.1, 41.2],
    },
  },
  {
    name: "Vanuatu",
    code: "VU",
    location: {
      mapType: "world",
      coordinates: [168.18, 17.45],
    },
  },
  {
    name: "Venezuela",
    code: "VE",
    location: {
      mapType: "south-america",
      coordinates: [-66.9036, 10.4806],
    },
  },
  {
    name: "Viet Nam",
    code: "VN",
    location: {
      mapType: "asia",
      coordinates: [105.55, 21.05],
    },
  },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  {
    name: "Yemen",
    code: "YE",
    location: {
      mapType: "asia",
      coordinates: [48, 15],
    },
  },
  {
    name: "Zambia",
    code: "ZM",
    location: {
      mapType: "africa",
      coordinates: [28.16, -15.28],
    },
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    location: {
      mapType: "africa",
      coordinates: [31.02, -17.43],
    },
  },
];
