import {
  YesNoQuestion,
  ChoiceQuestion,
  TextQuestion,
  QuestionGroup,
  BooleanQuestion,
} from "@app/questionnaire/questions";
import { toChoices } from "@app/questionnaire/questions/ChoiceQuestion";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.MIFID2,
  questions: [
    new YesNoQuestion({
      key: "MIFID2Entity",
      label: "Is your entity, directly or indirectly, subject to MIFID 2?",
      Yes: [
        new QuestionGroup("Target Market", {
          questions: [
            new YesNoQuestion({
              key: "MIFID2Entity-yes-target-market-defined",
              label: "Has your entity defined a Target Market?",
            }),
            new YesNoQuestion({
              key: "MIFID2Entity-yes-target-market-impl",
              label:
                "Have you implemented a policy to ensure you offer the funds within your defined Target Market?",
              Yes: [
                TextQuestion.Explain(
                  "MIFID2Entity-yes-target-market-impl-explain",
                ),
              ],
              No: [
                TextQuestion.Explain(
                  "MIFID2Entity-yes-target-market-impl-explain",
                ),
              ],
            }),
            new YesNoQuestion({
              key: "MIFID2Entity-yes-target-market-offer",
              label:
                "Are you likely to offer the funds outside of the target market of the funds?",
              Yes: [
                new TextQuestion({
                  key: "MIFID2Entity-target-market-freq",
                  label:
                    "To which frequency will you report back this offer outside of the Target Market?",
                  isMultiLine: true,
                }),
              ],
            }),
          ],
        }),
        new QuestionGroup("Cost & Charges", {
          questions: [
            new YesNoQuestion({
              key: "MIFID2Entity-yes-cost-and-charges-disclose",
              label:
                "Does your entity disclose the cost & charges to the end-client?",
              No: [
                TextQuestion.Explain(
                  "MIFID2Entity-yes-cost-and-charges-disclose-no",
                ),
              ],
            }),
          ],
        }),
        new QuestionGroup("Activity & Inducements", {
          questions: [
            new QuestionGroup("Type of activities", {
              variant: "noBorder",
              questions: [
                new BooleanQuestion({
                  key: "MIFID2Entity-yes-activity-and-inducements-typeOfActivitiesExecutionOnly",
                  label: "Execution only",
                  questions: [
                    new ChoiceQuestion({
                      key: "MIFID2Entity-yes-activity-and-inducements-typeOfActivitiesExecutionOnlyTypeOfClients",
                      label: "Type of clients",
                      isMultipleChoice: true,
                      choices: toChoices([
                        "Retail clients",
                        "Professional investors",
                        "Eligible counterparties",
                      ]),
                      questions: [
                        TextQuestion.Explain(
                          "MIFID2Entity-yes-activity-and-inducements-typeOfActivitiesExecutionOnlyTypeOfClientsOther",
                        ),
                      ],
                    }),
                    new ChoiceQuestion({
                      key: "MIFID2Entity-yes-activity-and-inducements-typeOfActivitiesExecutionOnlyInducement",
                      label: "Inducement",
                      isMultipleChoice: true,
                      choices: [
                        ChoiceQuestion.Choice(
                          "Inducements are kept by our entity",
                          {
                            questions: [
                              new YesNoQuestion({
                                key: "MIFID2Entity-yes-activity-and-inducements-typeOfActivitiesExecutionOnlyInducement-disclose",
                                label: "Do you disclose amount to end-client?",
                                No: [
                                  TextQuestion.Explain(
                                    "MIFID2Entity-yes-activity-and-inducements-typeOfActivitiesExecutionOnlyInducement-disclose-explain",
                                  ),
                                ],
                              }),
                              new YesNoQuestion({
                                key: "MIFID2Entity-yes-activity-and-inducements-typeOfActivitiesExecutionOnlyInducement-quality",
                                label: "Do you ensure quality enhancement?",
                                No: [
                                  TextQuestion.Explain(
                                    "MIFID2Entity-yes-activity-and-inducements-typeOfActivitiesExecutionOnlyInducement-quality-explain",
                                  ),
                                ],
                              }),
                            ],
                          },
                        ),
                        "Inducements are paid back to the end-client",
                        "Inducements are prohibited under local laws and regulation",
                      ],
                    }),
                  ],
                }),
                new BooleanQuestion({
                  key: "mifid2-dpm",
                  label: "Discretionary Portfolio Management",
                  questions: [
                    new ChoiceQuestion({
                      key: "mifid2-dpm-TypeOfClients",
                      label: "Type of clients",
                      isMultipleChoice: true,
                      choices: [
                        "Retail clients",
                        "Professional investors",
                        "Eligible counterparties",
                      ],
                      questions: [
                        TextQuestion.Explain("mifi2-dpm-TypeOfClientsOther"),
                      ],
                    }),
                    new ChoiceQuestion({
                      key: "mifid2-dpm-inducement",
                      label: "Inducement",
                      isMultipleChoice: true,
                      choices: [
                        "The total amount of Inducements is paid back to the end-client",
                        "Inducements are prohibited under local laws and regulation",
                      ],
                    }),
                  ],
                }),
                new BooleanQuestion({
                  key: "mifid2-advice",
                  label: "Advice",
                  questions: [
                    new ChoiceQuestion({
                      key: "mifid2-advice-dependency",
                      label: "Are you non-Independent or independent?",
                      choices: [
                        ChoiceQuestion.Choice("Non-independent", {
                          questions: [
                            new ChoiceQuestion({
                              key: "mifid2-advice-nidTypeOfClients",
                              label: "Type of clients",
                              isMultipleChoice: true,
                              choices: [
                                "Retail clients",
                                "Professional investors",
                                "Eligible counterparties",
                              ],
                              questions: [
                                TextQuestion.Explain(
                                  "mifid2-advice-nidTypeOfClientsOther",
                                ),
                              ],
                            }),
                            new ChoiceQuestion({
                              key: "mifid2-advice-nidInducement",
                              label: "Inducement",
                              isMultipleChoice: true,
                              choices: [
                                ChoiceQuestion.Choice(
                                  "Inducements are kept by our entity",
                                  {
                                    questions: [
                                      new YesNoQuestion({
                                        key: "mifid2-advice-nidInducement-disclose",
                                        label:
                                          "Do you disclose amount to end-client?",
                                        No: [
                                          TextQuestion.Explain(
                                            "mifid2-advice-nidInducement-disclose-explain",
                                          ),
                                        ],
                                      }),
                                      new YesNoQuestion({
                                        key: "mifid2-advice-nidInducement-quality",
                                        label:
                                          "Do you ensure quality enhancement?",
                                        No: [
                                          TextQuestion.Explain(
                                            "mifid2-advice-nidInducement-quality-explain",
                                          ),
                                        ],
                                      }),
                                    ],
                                  },
                                ),
                                "Inducements are paid back to the end-client",
                                "Inducements are prohibited under local laws and regulation",
                              ],
                            }),
                          ],
                        }),
                        ChoiceQuestion.Choice("Independent", {
                          questions: [
                            new ChoiceQuestion({
                              key: "mifid2-advice-TypeOfClients",
                              label: "Type of clients",
                              isMultipleChoice: true,
                              choices: [
                                "Retail clients",
                                "Professional investors",
                                "Eligible counterparties",
                              ],
                              questions: [
                                TextQuestion.Explain(
                                  "mifi2-advice-TypeOfClientsOther",
                                ),
                              ],
                            }),
                            new ChoiceQuestion({
                              key: "mifid2-advice-inducement",
                              label: "Inducement",
                              isMultipleChoice: true,
                              choices: [
                                "The total amount of Inducements is paid back to the end-client",
                                "Inducements are prohibited under local laws and regulation",
                              ],
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
                new BooleanQuestion({
                  key: "mifid2-NA",
                  label: "Not applicable",
                  questions: [TextQuestion.Explain("mifid2-NA-explain")],
                }),
              ],
            }),
          ],
        }),
      ],
      No: [
        new ChoiceQuestion({
          key: "MIFID2Entity-no",
          label: "Specify other body of appointment",
          isMultipleChoice: true,
          choices: [
            ...toChoices([
              "Insurance company",
              "No directly or indirectly offering funds to EU investors",
            ]),
            {
              value: "Other",
              questions: [TextQuestion.Explain("MIFID2Entity-no-explain")],
            },
          ],
        }),
      ],
    }),
  ],
};

export default category;
