import { useMutation } from "react-query";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Banner } from "@gfw/corvus";
import { BANNER_TYPE } from "@gfw/core";

function AppBanner() {
  const { mutateAsync: getActiveBanners, data: banner = {} } = useMutation(
    async () => {
      const response = await apiClient.get(
        `/banners/active/${BANNER_TYPE.BANNER}`,
      );
      return response.data;
    },
  );

  React.useEffect(() => {
    getActiveBanners();
  }, [getActiveBanners]);

  return <Banner banner={banner} />;
}

export default AppBanner;
