/* eslint-disable no-unused-vars */
const NOTIFICATION_JOB_COLLECTION = "notificationsJobs";

enum NOTIFICATION_TYPE {
  MAIL = "mail",
}

enum NOTIFICATION_MAIL_TEMPLATE {
  ACCESS_RIGHTS_REQUEST_COMPLETED,
  ACCESS_RIGHTS_REQUEST_PENDING,
  USER_NEW_CONNECTION,
  ACTIVITY_REPORT_WEEKLY,
  USER_FORGOT_PASSWORD,
  GENERIC,
  VIRUS_FOUND,
  USER_INVITE,
  DUE_DILIGENCE_REQUEST_CANCELED,
  DUE_DILIGENCE_REQUEST_COMPLETED,
  DUE_DILIGENCE_REQUEST_CREATED,
  DUE_DILIGENCE_REQUEST_REMINDER,
  BROADCAST_CREATED,
  BROADCAST_REMINDER,
  BROADCAST_LAST_REMINDER,
  DOCUMENT_EXPIRED,
  DOCUMENT_ABOUT_TO_EXPIRE,
  DOCUMENT_UPLOAD_REQUESTED,
  CORRESPONDENCE_NEW_MESSAGE,
  COUNTERPARTY_UPDATE,
  NEXT_REVIEW_REMINDER,
  PROFILE_CLAIMED,
  PROFILE_MERGED,
  PROPOSED_PROFILE_APPROVED,
  PROPOSED_PROFILE_DECLINED,
  USER_ASSIGNMENT_NEW_OR_REOPENED,
  USER_ASSIGNMENT_COMPLETED,
  USER_ASSIGNMENT_NO_ACTIONS_REQUIRED_ANYMORE,
  USER_ASSIGNMENT_CANCELED,
  USER_ASSIGNMENT_DECLINED,
}

enum NOTIFICATION_STATUS {
  NETWORK_ERROR = -1,
  SUCCESS = 200,
  NOT_SUBSCRIBED = 204,
  BAD_REQUEST = 400,
  NOT_AUTHORIZED = 401,
  FORBIDDEN = 403,
  INTERNAL_ERROR = 500,
}

enum NOTIFICATION_MAILJET_EVENTS {
  SENT = "sent",
  OPEN = "open",
  CLICK = "click",
  BOUNCE = "bounce",
  BLOCKED = "blocked",
  SPAM = "spam",
  UNSUB = "unsub",
}

const NOTIFICATION_BACK_OFF = [6, 6, 6, 13, 13, 20, 20, 30];

export {
  NOTIFICATION_BACK_OFF,
  NOTIFICATION_JOB_COLLECTION,
  NOTIFICATION_MAIL_TEMPLATE,
  NOTIFICATION_MAILJET_EVENTS,
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE,
};
