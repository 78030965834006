import { tlds } from "@hapi/tlds";
import type { ExtensionFactory } from "joi";

import { isLeiNumberValid } from "../validators/validateLeiNumber";
import { messages, messagesKeys } from "../messages";

export const joiStringExtension: ExtensionFactory = (joi) => ({
  type: "string",
  base: joi.string().max(32768),
  messages: {
    [messagesKeys.common.stringNotLei]:
      messages.common[messagesKeys.common.stringNotLei],
  },
  rules: {
    lei: {
      validate: (value, helpers) => {
        const isValidLei = isLeiNumberValid(value);
        if (!isValidLei) {
          return helpers.error(messagesKeys.common.stringNotLei);
        }
      },
    },
    tldEmail: {
      validate: (value: string, helpers) => {
        const res = joi
          .string()
          .email({ tlds: { allow: tlds } })
          .validate(value);
        if (res.error) {
          return helpers.error(messagesKeys.CQAnswers.stringNotEmail);
        }
      },
    },
    url: {
      validate: (value: string, helpers) => {
        const res = joi
          .string()
          .uri({
            scheme: ["http", "https"],
            domain: { tlds: { allow: tlds } },
          })
          .validate(value);
        if (res.error) {
          return helpers.error(messagesKeys.CQAnswers.stringNotUrl);
        }
      },
    },
  },
});
