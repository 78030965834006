import { ChoiceQuestion, TextQuestion, YesNoQuestion } from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.INVESTMENT_SELECTION,
  questions: [
    //1
    new YesNoQuestion({
      key: "conductResearchExternalSources",
      label:
        "Do you conduct research with the support of external sources (external data providers sources, investment advisors or consultant for research?)",
      Yes: [
        new TextQuestion({
          key: "conductResearchExternalSourcesYes",
          label:
            "Please describe to the process selection of the external investment advisor",
        }),
      ],
    }),
    //2
    new YesNoQuestion({
      key: "guidelinesCriteriaInstrumentsPortfolio",
      label:
        "Do you have documented guidelines detailing what specific criteria and instruments must meet to be eligible for each portfolio?",
    }),
    //3
    new YesNoQuestion({
      key: "investmentCommitteeGuidelines",
      label:
        "Is there an investment committee defining these investment guidelines?",
      Yes: [
        new TextQuestion({
          key: "investmentCommitteeGuidelinesYes",
          label: "How frequently does this committee meet?",
        }),
      ],
    }),
    //4
    new YesNoQuestion({
      key: "preTradepostTradeChecks",
      label:
        "Do you perform pre-trade and post-trade checks on Investment guidelines and Investment Restrictions?",
      Yes: [
        new TextQuestion({
          key: "preTradepostTradeChecksYes",
          label: "How are responsibilities allocated?",
        }),
      ],
    }),
    //5
    new TextQuestion({
      key: "percentageOfInvestmentGuidelinesMonitored",
      label:
        "What percentage of the investment guidelines and Investment Restrictions are monitored manually and automatically?",
    }),
    //6
    new TextQuestion({
      key: "systemAutomatedInvestmentGuidelines",
      label:
        "What system is used for the automated portion of the investment guidelines and Investment Restrictions monitoring?",
    }),
    //7
    new YesNoQuestion({
      key: "independentReviewInvestmentGuidelines",
      label:
        "Does somebody independent from the portfolio Manager review the coding of the investment guidelines and Investment Restrictions once they are entered into the automated system?",
      Yes: [
        new TextQuestion({
          key: "independentReviewInvestmentGuidelinesYes",
          label: "How often are they reviewed?",
        }),
      ],
    }),
    //8
    new YesNoQuestion({
      key: "investmentDerivativesUsed",
      label: "Do you use derivatives?",
      Yes: [
        new TextQuestion({
          key: "investmentDerivativesUsedYes",
          label: "what is the percentage of OTC versus ETD",
        }),
      ],
    }),
    //9
    new ChoiceQuestion({
      key: "performEMIRRequirements",
      label:
        "Do you perform on your own EMIR requirements (Timely confirmation, portfolio reconciliation, trade repository reporting) on behalf of your clients/funds?",
      isMultipleChoice: false,
      choices: [
        "Yes",
        {
          value: "No, we rely on another party",
          questions: [
            new TextQuestion({
              key: "performEMIRRequirementsNo",
              label: "How do you monitor this?",
            }),
          ],
        },
        {
          value: "N/A",
          questions: [TextQuestion.Explain("performEMIRRequirementsNA")],
        },
      ],
    }),
    //10
    new ChoiceQuestion({
      key: "performDoddFranckRequirements",
      label:
        "Do you perform on your own Dodd Franck requirements (Timely confirmation, portfolio reconciliation, trade repository reporting) on behalf of your clients/funds",
      isMultipleChoice: false,
      choices: [
        "Yes",
        {
          value: "No, we rely on another party",
          questions: [
            new TextQuestion({
              key: "performDoddFranckRequirementsNo",
              label: "How do you monitor this?",
            }),
          ],
        },
        {
          value: "N/A",
          questions: [TextQuestion.Explain("performDoddFranckRequirementsNA")],
        },
      ],
    }),
    //11
    new YesNoQuestion({
      key: "workWithRiskManagementSystem",
      label: "Do you work with a risk management system?",
      Yes: [
        new TextQuestion({
          key: "workWithRiskManagementSystemYes",
          label: "Which one?",
        }),
      ],
    }),
    //12
    new YesNoQuestion({
      key: "interveneInValuationProcess",
      label:
        "Do you intervene in the valuation process of securities held by the fund you manage?",
      Yes: [
        new TextQuestion({
          key: "interveneInValuationProcessYes",
          label:
            "Please describe and explain how you manage conflicts of interest",
        }),
      ],
    }),
  ],
};

export default category;
