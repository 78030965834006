export const messagesKeys = {
  CQAnswers: {
    numberOfAnswersTooSmall: "CQAnswer.numberOfAnswersTooSmall",
    numberOfAnswersTooBig: "CQAnswer.numberOfAnswersTooBig",
    numberTooSmall: "CQAnswer.numberTooSmall",
    numberTooBig: "CQAnswer.numberTooBig",
    stringTooSmall: "CQAnswer.stringTooSmall",
    stringTooBig: "CQAnswer.stringTooBig",
    stringNotExact: "CQAnswer.stringNotExact",
    stringNotUrl: "CQAnswer.stringNotUrl",
    stringNotEmail: "CQAnswer.stringNotEmail",
    dateTooSmall: "CQAnswer.dateTooSmall",
    dateTooBig: "CQAnswer.dateTooBig",
    required: "CQAnswer.required",
    noItem: "CQAnswer.noItem",
    invalidAnswerType: "CQAnswer.invalidAnswerType",
    answerDoesntMatchQuestion: "CQAnswer.answerDoesntMatchQuestion",
  },
  common: {
    stringNotLei: "common.stringNotLei",
  },
} as const;

export const messages = {
  CQAnswer: {
    [messagesKeys.CQAnswers.numberOfAnswersTooSmall]:
      "must be {{#limit}} answers or more",
    [messagesKeys.CQAnswers.numberOfAnswersTooBig]:
      "must be less than {{#limit}} answers",
    [messagesKeys.CQAnswers.numberTooSmall]:
      "must be a value equal to {{#limit}} or more",
    [messagesKeys.CQAnswers.numberTooBig]:
      "must be a value equal to {{#limit}} or less",
    [messagesKeys.CQAnswers.stringTooSmall]:
      "must be at least {{#limit}} characters",
    [messagesKeys.CQAnswers.stringTooBig]:
      "cannot be longer than {{#limit}} characters",
    [messagesKeys.CQAnswers.stringNotExact]:
      "must be exactly {{#limit}} characters",
    [messagesKeys.CQAnswers.stringNotUrl]: "is not a valid URL",
    [messagesKeys.CQAnswers.stringNotEmail]: "is not a valid email",
    [messagesKeys.CQAnswers.dateTooSmall]:
      "must be a value equal to {{#limit}} or more",
    [messagesKeys.CQAnswers.dateTooBig]:
      "must be a value equal to {{#limit}} or less",
    [messagesKeys.CQAnswers.required]: "is required",
    [messagesKeys.CQAnswers.noItem]:
      "you must provide the item to validate using .item(<ItemObject>)",
    [messagesKeys.CQAnswers.invalidAnswerType]:
      "{{#value}} is not a valid answer to {{#item}}",
    [messagesKeys.CQAnswers.answerDoesntMatchQuestion]:
      "{{#value}} does not answer to question {{#itemId}} of type {{#itemType}}",
  },
  common: {
    [messagesKeys.common.stringNotLei]: "is not a valid LEI number",
  },
} as const;
