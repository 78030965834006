import fs from "fs";
import path from "path";

export function getTestImage(type = "buffer") {
  const filePath = path.join(__dirname, "assets/tiny.png");
  const pngBuffer = fs.readFileSync(filePath);
  const pngBase64 = Buffer.from(pngBuffer).toString("base64");
  if (type === "blob") {
    return pngBase64;
  }
  return pngBuffer;
}
