import { DocumentTypes } from "@app/document-types";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const DOCUMENTS = DocumentTypes.types();

const profile = {
  id: "11d2365f-737e-4d64-afb3-1b662ba2d7a1",
  name: "Transfer Agent",
  forms: [
    QUESTIONNAIRE_CATEGORIES.AML_AND_CFT_PROCEDURES.id,
    QUESTIONNAIRE_CATEGORIES.CORPORATE_INFO.id,
    QUESTIONNAIRE_CATEGORIES.ESG.id,
    QUESTIONNAIRE_CATEGORIES.GOVERNANCE_RISK_COMPLIANCE.id,
    QUESTIONNAIRE_CATEGORIES.HUMAN_RESOURCES.id,
    QUESTIONNAIRE_CATEGORIES.IT_SECURITY_BUSINESS_CONTINUITY.id,
    QUESTIONNAIRE_CATEGORIES.MANAGEMENT_AND_OWNERSHIP.id,
    QUESTIONNAIRE_CATEGORIES.REGULATORY_STATUS.id,
    QUESTIONNAIRE_CATEGORIES.TRANSFER_AGENT_ACTIVITY.id,
  ],
  files: [],
  basicPack: {
    forms: [
      QUESTIONNAIRE_CATEGORIES.AML_AND_CFT_PROCEDURES.id,
      QUESTIONNAIRE_CATEGORIES.GOVERNANCE_RISK_COMPLIANCE.id,
      QUESTIONNAIRE_CATEGORIES.HUMAN_RESOURCES.id,
      QUESTIONNAIRE_CATEGORIES.IT_SECURITY_BUSINESS_CONTINUITY.id,
      QUESTIONNAIRE_CATEGORIES.MANAGEMENT_AND_OWNERSHIP.id,
      QUESTIONNAIRE_CATEGORIES.REGULATORY_STATUS.id,
      QUESTIONNAIRE_CATEGORIES.TRANSFER_AGENT_ACTIVITY.id,
    ],
    files: [
      DOCUMENTS.ACCOUNT_OPENING_PROCEDURE.id,
      DOCUMENTS.AUDITED_FINANCIAL_ANNUAL_REPORTS.id,
      DOCUMENTS.CERTIFICATE_OF_REGISTRATION.id,
      DOCUMENTS.CERTIFICATION_OD_LICENSES_BY_FSA.id,
      DOCUMENTS.INSURANCE_POLICIES.id,
      DOCUMENTS.ISAE_3402_REPORT.id,
      DOCUMENTS.LIST_OF_BENEFICIAL_OWNERS.id,
      DOCUMENTS.OWNERSHIP_OVERVIEW.id,
    ],
  },
};

export default profile;
