/* eslint-disable no-unused-vars */
import { ProfileTypes } from "../profile-types";

export enum QuestionnaireTagType {
  GENERIC = 0,
  PROFILE_TYPE,
  BASIC_PACK,
}

export interface IQuestionnaireTag {
  id: string;
  label: string;
  type: QuestionnaireTagType;
}

export interface IQuestionnaireTagsList {
  [key: string]: IQuestionnaireTag;
}

export type IQuestionnaireTagsIds = string[];
export const QUESTIONNAIRE_TAGS_IDS: IQuestionnaireTagsIds = [];
export const QUESTIONNAIRE_TAGS_LIST: IQuestionnaireTagsList = {
  isPublic: {
    id: "isPublic",
    label: "Public",
    type: QuestionnaireTagType.GENERIC,
  },
};

//initialize TAGS_IDS with predefined TAGS values
Object.keys(QUESTIONNAIRE_TAGS_LIST).forEach((tagKey) => {
  QUESTIONNAIRE_TAGS_IDS.push(tagKey);
});

const getBasickPackDetailByFromProfileType = (
  profileType: ProfileTypes.IProfileType,
) => ({
  id: `${profileType.id}_BP`,
  label: `${profileType.name} Basic Pack`,
  type: QuestionnaireTagType.BASIC_PACK,
});

// enrich tags with all profileTypes
ProfileTypes.items().map((profileType: ProfileTypes.IProfileType) => {
  //ProfileType
  const profileTypeTag = {
    id: profileType.id,
    label: profileType.name,
    type: QuestionnaireTagType.PROFILE_TYPE,
  };
  QUESTIONNAIRE_TAGS_LIST[profileTypeTag.id] = profileTypeTag;
  QUESTIONNAIRE_TAGS_IDS.push(profileTypeTag.id);
  //ProfileType Basic Pack
  const profileTypeBasicPackTag =
    getBasickPackDetailByFromProfileType(profileType);
  QUESTIONNAIRE_TAGS_LIST[profileTypeBasicPackTag.id] = profileTypeBasicPackTag;
  QUESTIONNAIRE_TAGS_IDS.push(profileTypeBasicPackTag.id);
});

export const getBasicPackTagsPerProfileTypes = (
  profileTypeIds: string[],
): string[] => {
  // check ProfileType exist
  return profileTypeIds.reduce((acc: string[], profileTypeId: string) => {
    const profileTypeInfo = ProfileTypes.byId(profileTypeId);
    if (profileTypeInfo) {
      // add profileType Id
      acc.push(profileTypeInfo.id);
      // add profileType basic pack Id
      acc.push(getBasickPackDetailByFromProfileType(profileTypeInfo).id);
    }
    return acc;
  }, []);
};
