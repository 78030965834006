import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Result, Tag } from "antd";
import { useQuery } from "react-query";
import gql from "graphql-tag";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import {
  Button,
  Card,
  Icon,
  Loading,
  NavigateLink,
  notification,
  Space,
  Tabs,
} from "@gfw/corvus";
import { FlexBox, Text } from "@gfw/orion";
import { PROFILE_STATE, profileStateToString } from "@gfw/core";

import { PageTitle, PageTitleTypo } from "@app/resources/generic/components";
import {
  ProfileModelFragment,
  ProfileProvider,
} from "@app/resources/Profile/profile-context";

import {
  ProfileCounterparties,
  ProfileCRM,
  ProfileDetail,
  ProfileMergeHistory,
  ProfileStats,
  ProfileUsers,
} from "./screens";

const PAGE_QUERY = gql`
  query ProfilePage($id: ID!) {
    Profile(id: $id) {
      id
      oid
      name
      LEINumber
      proposedContacts
      stats
      mergeHistory {
        mergedBy
        mergedAt
        unMergedBy
        unMergedAt
        name
        location
        types
        state
        createdAt
      }
      ...ProfileModel
    }
  }
  ${ProfileModelFragment}
`;

function CopyId({ id }) {
  return (
    <CopyToClipboard
      onCopy={() => notification.success({ message: "Copied to clipboard" })}
      text={id}
    >
      <Button icon="copy" tooltip="Copy to clipboard" type="link"></Button>
    </CopyToClipboard>
  );
}

const ProfilePageTitle = function ProfilePageTitle({ profile }) {
  const { id, name, isPremium, state } = profile;

  let color = "grey";
  let label = profileStateToString(state);
  if (state === PROFILE_STATE.DRAFT) {
    color = "blue";
  } else if (state === PROFILE_STATE.PROPOSED) {
    color = "purple";
  } else if (state === PROFILE_STATE.INACTIVE) {
    color = "red";
  } else if (state === PROFILE_STATE.OFFICIAL) {
    color = "green";
  }
  return (
    <PageTitle>
      <Space align="baseline" size="small">
        <Icon type="profile" />
        <FlexBox direction="column">
          <PageTitleTypo> {name} </PageTitleTypo>
          <FlexBox align="center">
            <Text fs={1}>{id}</Text>
            <CopyId id={id} />
          </FlexBox>
        </FlexBox>
        <FlexBox>
          {isPremium && <Tag color={"#42a5ac"}>premium</Tag>}
          <Tag color={color}>{label}</Tag>
        </FlexBox>
      </Space>
    </PageTitle>
  );
};

function ProfilePage() {
  const { id } = useParams();
  const tab = useParams()["*"];
  const navigate = useNavigate();

  const {
    data: Profile,
    refetch,
    isLoading,
  } = useQuery(
    ["profile", id],
    async () => {
      const response = await apiClient.graphql(PAGE_QUERY, {
        variables: { id },
      });

      if (response?.Profile) {
        return response.Profile;
      }
      return null;
    },
    { refetchOnWindowFocus: false },
  );

  if (isLoading) {
    return (
      <FlexBox align="center" h="100vh" justify="center" w="100vw">
        <Loading size="large" />
      </FlexBox>
    );
  }

  let items = [
    {
      label: (
        <FlexBox align="center">
          <Icon fs="lg" mr="sm" type="detail" />
          Profile
        </FlexBox>
      ),
      key: "details",
    },
    {
      label: (
        <FlexBox align="center">
          <Icon fs="lg" mr="sm" type="counterparties" />
          Counterparties
        </FlexBox>
      ),
      key: "counterparties",
    },
  ];
  if (Profile?.state !== PROFILE_STATE.PROPOSED) {
    items.push({
      label: (
        <FlexBox align="center">
          <Icon fs="lg" mr="sm" type="users" />
          Users
        </FlexBox>
      ),
      key: "users",
    });
  }
  items.push({
    label: (
      <FlexBox align="center">
        <Icon fs="lg" mr="sm" type="queryStats" />
        Stats
      </FlexBox>
    ),
    key: "stats",
  });
  items.push({
    label: (
      <FlexBox align="center">
        <Icon fs="lg" mr="sm" type="comment" />
        CRM
      </FlexBox>
    ),
    key: "crm",
  });
  if (Profile?.mergeHistory?.length) {
    items.push({
      label: (
        <FlexBox align="center">
          <Icon fs="lg" mr="sm" type="info" />
          Merge Info
        </FlexBox>
      ),
      key: "merge-history",
    });
  }

  const onChange = (tabId) => {
    navigate(`/profiles/${id}/${tabId}`);
  };

  return (
    <>
      {!Profile && (
        <Card>
          <Result
            extra={
              <FlexBox justify="center">
                <NavigateLink to="/profiles">Back</NavigateLink>
              </FlexBox>
            }
            status="404"
            subTitle="Sorry, this profile is not available in our system"
            title="Profile not found"
          />
        </Card>
      )}
      {!!Profile && (
        <ProfileProvider profile={Profile}>
          <ProfilePageTitle profile={Profile} />
          <Card tabs>
            <Tabs activeKey={tab} items={items} onChange={onChange} />
            <Routes>
              <Route
                element={<ProfileCounterparties profile={Profile} />}
                path="counterparties"
              />
              {Profile.state !== PROFILE_STATE.PROPOSED && (
                <Route
                  element={<ProfileUsers profile={Profile} />}
                  path="users"
                />
              )}
              <Route
                element={<ProfileStats profile={Profile} />}
                path="stats"
              />
              <Route
                element={<ProfileMergeHistory profile={Profile} />}
                path="merge-history"
              />
              <Route element={<ProfileCRM profile={Profile} />} path="crm" />
              <Route
                element={<ProfileDetail profile={Profile} refetch={refetch} />}
                path="details"
              />
              <Route element={<Navigate to="details" />} index />
            </Routes>
          </Card>
        </ProfileProvider>
      )}
    </>
  );
}

export default ProfilePage;
