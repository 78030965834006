/* eslint-disable no-unused-vars */
enum BRANDING {
  NAME = "Global Fund Watch",
  NAME_SHORT = "GFW",

  EMAIL_CST = "clientservice@globalfundwatch.com",
  EMAIL_NOREPLY = "noreply@globalfundwatch.com",
  EMAIL_DEV = "dev@globalfundwatch.com",
  DOMAIN_NAME = "globalfundwatch",
  TOP_LEVEL_DOMAIN = "com",
  isEuroclear = "",
}

// TEMP , should be removed for the deployment
enum NEW_BRANDING {
  NAME = "GlobalWatch",
  NAME_SHORT = "GW",
  DOMAIN_NAME = "euroclear-globalwatch",
  TOP_LEVEL_DOMAIN = "com",
  EMAIL_CST = "clientservice@euroclear-globalwatch.com",
  EMAIL_NOREPLY = "noreply@euroclear-globalwatch.com",
  EMAIL_DEV = "dev@euroclear-globalwatch.com",
  isEuroclear = "true",
}

function getBrandInfo() {
  if (window?.location?.host?.includes("globalfundwatch")) {
    return BRANDING;
  }
  return NEW_BRANDING;
}

export { BRANDING, NEW_BRANDING, getBrandInfo };
