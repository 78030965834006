/* eslint-disable @typescript-eslint/no-var-requires */
import fs from "fs";

const defaultConfig = { LOG_LEVEL: "debug" };
const defaultMdw = (env: any) => env;

export const getConfig = (validators = {}, middleware = defaultMdw): any => {
  if (fs?.existsSync) {
    const dotenv = require("dotenv");
    if (fs.existsSync("../../.env")) {
      dotenv.config({ path: "../../.env" });
    }
    if (fs.existsSync("../../.env.local")) {
      dotenv.config({ path: "../../.env.local", override: true });
    }
  }

  const { NODE_ENV } = process?.env || {};

  if (!NODE_ENV) {
    // it happens when core is called from react components
    return defaultConfig;
  }
  const { str, customCleanEnv, bool } = require("envalid");
  return customCleanEnv(
    process.env,
    {
      APP_URL: str({
        desc: "url to the app. Used in creating links in the emails",
        default: "http://localhost:8000",
      }),
      APP_VERSION: str({
        desc: "Version of the application",
        default: "1.0.0",
      }),
      ADMIN_URL: str({
        desc: "url to the admin application. Used for configuring CORS.",
        default: "http://localhost:8001",
      }),
      API_ADMIN_URL: str({
        desc: "url used by the Admin UI to join the backend",
        default: "",
      }),
      GFW_API_URL: str({
        desc: "base url to the api. In production this is set at runtime instead.",
        default: "http://localhost:8080", // port that the api runs on locally
      }),
      GFW_APP_URL: str({
        default: "http://localhost:8000",
      }),
      MONGO: str({
        desc: "connection string mongodb (excluding database and options)",
        default: "mongo:27017", // "mongo", the hosname, is required to be able to join the db from desktop
      }),
      MONGO_USERNAME: str({
        desc: "mongodb username",
        default: "gfw",
      }),
      MONGO_PASSWORD: str({
        desc: "mongodb password",
        default: "dev",
      }),
      MONGO_REPLICASET: str({
        desc: "name of mongodb replicaset",
        default: "",
        example: "rs0",
      }),
      MAINTENANCE_ENABLED: bool({
        desc: "boolean to know if maintenance is in progress or not",
        default: false,
      }),
      MAINTENANCE_END_DATETIME: str({
        desc: "Datetime as a string to communicate the end date to endusers",
        default: "2024-09-19T20:00:00.000Z",
      }),
      DATABASE_NAME: str({
        desc: "name of the database to create",
        default: "dev",
      }),
      NODE_ENV: str({
        choices: [
          "development",
          "staging",
          "pullrequest",
          "production",
          "test",
        ],
        default: "development",
      }),
      MAIL_HASH_SUBCRIPTION_KEY: str({
        desc: "use to generate hash subscription key",
        default: "gfw",
      }),
      LOG_LEVEL: str({
        desc: "set the log level for the application",
        example: "info",
        docs: "https://www.npmjs.com/package/winston",
        default: "info",
        choices: ["error", "warn", "info", "http", "verbose", "debug", "silly"],
      }),
      ...validators,
    },
    (env: any) => {
      const isDevelopment = env.NODE_ENV === "development";
      const isProduction = env.NODE_ENV === "production";
      const isPullRequest = env.NODE_ENV === "pullrequest";
      const isTest = env.NODE_ENV === "test";
      const isStaging = env.NODE_ENV === "staging";

      let MONGO_CONNECTIONSTRING = `mongodb://${env.MONGO_USERNAME}:${env.MONGO_PASSWORD}@${env.MONGO}/${env.DATABASE_NAME}?authSource=admin`;
      if (env.MONGO_REPLICASET) {
        MONGO_CONNECTIONSTRING = `${MONGO_CONNECTIONSTRING}&replicaSet=${env.MONGO_REPLICASET}`;
      }
      if (isTest) {
        env.LOG_LEVEL = "error";
      }

      if (isDevelopment || isPullRequest) {
        env.LOG_LEVEL = "debug";
      }

      return middleware({
        ...env,
        isDevelopment,
        isProduction,
        isPullRequest,
        isTest,
        isStaging,
        MONGO_CONNECTIONSTRING,
      });
    },
  );
};
