/* eslint-disable no-unused-vars */
enum ROLES {
  READONLY = 0,
  REGULAR = 100,
  FULL = 200,
  ADMIN_FULL = 300,
}

Object.freeze(ROLES);

const convertRolesToEnum = (roles: string[]) =>
  roles.map((role: any) => ROLES[role]);

export { convertRolesToEnum, ROLES };
