export default [
  {
    name: "Europe",
    code: "EU",
  },
  {
    name: "World",
    code: "WO",
  },
];
