import React from "react";

import { Icon } from "@gfw/corvus";

import {
  NewsArticleList,
  NewsProviderList,
  RssFeedList,
} from "@app/resources/News/components";
import {
  Page,
  PageTitle,
  PageTitleTypo,
} from "@app/resources/generic/components";

function NewsPage() {
  return (
    <Page>
      <PageTitle>
        <Icon mr="sm" type="news" />
        <PageTitleTypo>News</PageTitleTypo>
      </PageTitle>
      <NewsProviderList />
      <NewsArticleList />
      <RssFeedList />
    </Page>
  );
}

export { NewsPage };
