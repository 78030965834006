import { QuestionnaireQuestion } from "./QuestionnaireQuestion";
import { createLogger } from "@app/utils";

const log = createLogger("questionnaire:questions");

/**
 * A custom question that will do its own thing based on key instead.
 */
export class CustomQuestion extends QuestionnaireQuestion {
  type = "custom";

  clone(): QuestionnaireQuestion {
    return new CustomQuestion(this);
  }

  getQuestions(): QuestionnaireQuestion[] {
    return [this];
  }

  answerToString(): string {
    log.warn(`CustomQuestion ${this.key} has no specific answer`);
    return "";
  }

  preProcessSnapshotAnswer(answer?: any) {
    if (answer) {
      this.answer = answer;
    }
  }
}
