import { List } from "antd";
import React from "react";

import { FlexBox, Text } from "@gfw/orion";
import { Loading, Card as OverviewCard } from "@gfw/corvus";

import { ListControls } from "@app/resources/generic/components/ListControls";
import { ProfileItem } from "./ProfileItem";
import { StyledListItem } from "./Card.styles";

export function Card({
  title,
  actions,
  profiles = [],
  profileValue,
  isLoading,
  listControls,
}) {
  return (
    <OverviewCard
      extra={actions}
      size="small"
      title={<Text fs="md">{title.main}</Text>}
    >
      <FlexBox>
        <Text>{title.left}</Text>
        {title.right && <Text ml="auto">{title.right}</Text>}
      </FlexBox>
      {isLoading ? (
        <Loading />
      ) : (
        <List
          dataSource={profiles}
          renderItem={(profile) => (
            <StyledListItem>
              <ProfileItem
                profile={profile.node || profile}
                profileValue={profileValue}
              />
            </StyledListItem>
          )}
          split={false}
        ></List>
      )}
      {listControls && (
        <FlexBox justify="flex-end" mt="lg">
          <ListControls
            itemsPerPageState={listControls.itemsPerPageState}
            onNext={listControls.onNext}
            onPrevious={listControls.onPrevious}
            pageInfo={listControls.pageInfo}
            rowOptions={listControls.rowOptions}
          />
        </FlexBox>
      )}
    </OverviewCard>
  );
}
