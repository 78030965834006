import { QuestionnaireQuestion } from "../questions/QuestionnaireQuestion";
import { QuestionGroup } from "../questions/QuestionGroup";

function isQuestionGroup(
  question: QuestionnaireQuestion,
): question is QuestionGroup {
  return question.type === "group";
}

export interface IQuestionnaireCategory {
  id: string;
  name: string;
  icon?: string;
  questions: QuestionnaireQuestion[];
  types?: string[];
  updatedAt?: Date;
  public?: boolean;
}

export class QuestionnaireCategory {
  id: string;
  name: string;
  icon?: string;
  questions: QuestionnaireQuestion[];
  public?: boolean;
  types?: string[];
  updatedAt?: Date;

  constructor(category: IQuestionnaireCategory) {
    this.id = category.id;
    this.name = category.name;
    this.icon = category.icon;
    this.types = category.types;
    this.questions = category.questions;
    this.public = !!category.public;
    this.updatedAt = category.updatedAt;
  }

  // for now we need to expose this functionality so we can apply changes in the DD request UI...might change later on??
  applySnapshot(snapshot: { [key: string]: any }): void {
    for (const question of this.questions) {
      question.applySnapshot(snapshot);
    }
  }

  /**
   * Returns a list of all the questions in this category.
   * You can customize the depth it should use by supplying {depth: number}
   */
  getQuestions(options: { depth?: number } = {}): QuestionnaireQuestion[] {
    const opts = Object.assign({ depth: 1 }, options);

    if (opts.depth < 1) {
      // we only want the questions NOT buried inside any QuestionGroup
      return this.questions.filter((question) => !isQuestionGroup(question));
    }

    // this is the default
    return this.questions.reduce<QuestionnaireQuestion[]>(
      (questions, question) => {
        return [...questions, ...question.getQuestions(opts)];
      },
      [],
    );
  }
  getPublicQuestions(): QuestionnaireQuestion[] {
    return this.getQuestions().filter((question) => question.public);
  }
  hasPublicQuestions(): boolean {
    return this.getQuestions().some((question) => question.public);
  }

  /**
   * Returns only the groups (Questions that are QuestionGroups)
   */
  getGroups(): QuestionGroup[] {
    return this.questions.filter(isQuestionGroup);
  }

  getQuestion(key: string): QuestionnaireQuestion | undefined {
    return this.getQuestions().find((question) => question.key === key);
  }
}
