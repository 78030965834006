import React from "react";

import { Icon } from "@gfw/corvus";

import {
  Page,
  PageTitle,
  PageTitleTypo,
} from "@app/resources/generic/components";

import ProfilesList from "./ProfilesList";

function ProfileListPage() {
  return (
    <Page>
      <PageTitle>
        <Icon mr="sm" type="profiles" />
        <PageTitleTypo>Profiles</PageTitleTypo>
      </PageTitle>
      <ProfilesList />
    </Page>
  );
}

export default ProfileListPage;
