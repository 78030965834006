import { sortBy, keyBy } from "lodash";
import { PERMISSIONS } from "../constants/values/permissions";

type DocumentType = {
  category: string;
  name: string;
  id: string;
  hide?: boolean;
  extraPermissionRequired?: any;
};

type DocumentTypeFilter = {
  in: string[];
};

export const CATEGORIES = Object.freeze({
  CERTIFICATION_GUARANTEES: "certifications-guarantees",
  CORPORATE_DOCUMENTS: "corporate-documents",
  INDUSTRY_STANDARD_QUESTIONNAIRES: "industry-standard-questionnaires",
  OFFICIAL_IDENTIFICATION_DOCUMENTS: "official-identification-documents",
  POLICIES_GUIDELINES: "policies-guidelines",
  REPORTS: "reports",
  SHAREHOLDERS_GOVERNING_BODIES: "shareholders-governing-bodies",
  SIGNATORY_RIGHTS: "signatory-rights",
  OTHER_DOCUMENTS: "xyz-other-documents", //Hack: in order to sort the categories and  put this category at the end
});

export const DOCUMENTS_TYPES_LIST = Object.freeze({
  CERTIFICATE_OF_REGISTRATION: {
    category: CATEGORIES.CORPORATE_DOCUMENTS,
    name: "Certificate of Registration",
    id: "6ce5fc87-1293-4da2-862f-fd6e2cdc9303",
  },
  POWER_OF_ATTORNEY: {
    category: CATEGORIES.SIGNATORY_RIGHTS,
    name: "Power of Attorney",
    id: "8cc1771a-0313-4e3f-83ff-517239ee227a",
  },
  AUTHORIZED_SIGNATORY_LIST: {
    category: CATEGORIES.SIGNATORY_RIGHTS,
    name: "Authorized Signatory List",
    id: "9c1e4dd6-e93f-4e9d-a0bc-c4b7f6764752",
  },
  CERTIFICATION_OD_LICENSES_BY_FSA: {
    category: CATEGORIES.CORPORATE_DOCUMENTS,
    name: "Certification of licenses by FSA",
    id: "8287b9d9-b770-47e4-81db-dc377fd5caaa",
  },
  CERTIFICATION_OF_CEO: {
    category: CATEGORIES.SHAREHOLDERS_GOVERNING_BODIES,
    name: "Certification of CEO",
    id: "9eb3eed3-0254-49a0-b7de-85731f2eff98",
  },
  OTHER_DOCUMENT: {
    category: CATEGORIES.OTHER_DOCUMENTS,
    name: "Other document",
    id: "19211653-643c-4a41-a1e5-9f7b04c8a323",
  },
  ORGANIZATION_CHART: {
    category: CATEGORIES.SHAREHOLDERS_GOVERNING_BODIES,
    name: "Organization chart",
    id: "a791cffa-d9c3-4658-b451-b3ed8bf4eb52",
  },
  LIST_OF_BENEFICIAL_OWNERS: {
    category: CATEGORIES.SHAREHOLDERS_GOVERNING_BODIES,
    name: "List of Beneficial Owners",
    id: "3639ba6c-e9da-488d-8bd4-07add6d72b66",
  },
  AML_POLICY: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "AML/CFT policies & procedures",
    id: "0b423fda-73e1-4da2-ac14-c468e9d16280",
  },
  CERTIFICATION_OF_BOARD_MEMBERS: {
    category: CATEGORIES.SHAREHOLDERS_GOVERNING_BODIES,
    name: "Certification of board members",
    id: "6853f1b7-11ee-4581-8534-2d5a4afdaf70",
  },
  BEST_EXECUTION_POLICY: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Best Execution Policy",
    id: "7b5d1749-757d-4aed-81a3-32b81d2b5514",
  },
  REMUNERATION_POLICY: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Remuneration Policy",
    id: "8d73d852-65f3-46a0-904a-fded798b19e5",
  },
  GDPR_POLICIES: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "GDPR Policies",
    id: "a1011c31-320f-4f17-a0dd-39287bb91623",
  },
  ARTICLES_OF_ASSOCIATION: {
    category: CATEGORIES.CORPORATE_DOCUMENTS,
    name: "Articles of association",
    id: "8287b9d9-b770-47e4-81db-dc377fd5c639",
  },
  AUDITED_FINANCIAL_ANNUAL_REPORTS: {
    category: CATEGORIES.CORPORATE_DOCUMENTS,
    name: "Audited Financial Annual Reports",
    id: "8287b9d9-b770-47e4-81db-dc377fd5cccc",
  },
  CERTIFICATION_OF_EU_PASSPORT: {
    category: CATEGORIES.CORPORATE_DOCUMENTS,
    name: "Certification of EU-passport",
    id: "8287b9d9-b770-47e4-81db-dc377fd5cbbb",
  },
  BUSINESS_CONTINUITY_PLAN: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Business continuity Plan",
    id: "ecb9b942-b7bc-45f0-b58d-8357d6690e6a",
  },
  SELF_CERTIFICATION_OF_CRS_STATUS: {
    category: CATEGORIES.CERTIFICATION_GUARANTEES,
    name: "Self certification of CRS status",
    id: "09246633-643c-4a41-a1e5-9f7b04c8a8fa",
  },
  SELF_CERTIFICATION_OF_FATCA_STATUS: {
    category: CATEGORIES.CERTIFICATION_GUARANTEES,
    name: "Self certification of FATCA status",
    id: "b4562c84-9b3d-4665-b445-7c31227ea5f5",
  },
  INTERNAL_AUDIT_REPORT: {
    category: CATEGORIES.REPORTS,
    name: "Internal audit report",
    id: "09246633-643c-4a41-a1e5-9f7b04c8a322",
    multiple: true,
  },
  OTHER_REPORT: {
    category: CATEGORIES.REPORTS,
    name: "Other report",
    id: "09246633-643c-4a41-a1e5-9f7b04c8a323",
  },
  COMFORT_LETTERS: {
    category: CATEGORIES.CERTIFICATION_GUARANTEES,
    name: "Comfort letters",
    multiple: true,
    id: "63546633-643c-4a41-a1e5-9f7b04c8a345",
  },
  INSURANCE_POLICIES: {
    name: "Insurance Policies",
    category: CATEGORIES.CORPORATE_DOCUMENTS,
    id: "ac761d83-d20e-42fb-85f7-c813e3992e47",
  },
  FONDBOLAGENS_FORENING_DD_QUESTIONNAIRE: {
    category: CATEGORIES.INDUSTRY_STANDARD_QUESTIONNAIRES,
    name: "FondBolagens förening DD questionnaire",
    id: "0029d0c0-177e-11ea-a4c5-b31bb94aa0e9",
  },
  SOC1_REPORT: {
    category: CATEGORIES.REPORTS,
    name: "SOC1 Report",
    id: "7a785e12-1d74-443f-8570-d48b653f4476",
  },
  SAS_70_REPORT: {
    category: CATEGORIES.REPORTS,
    name: "SAS 70 report",
    id: "b1f38a4e-d687-4fc1-9617-9eb9c117f884",
  },
  ISAE_3402_REPORT: {
    category: CATEGORIES.REPORTS,
    name: "ISAE 3402 report",
    id: "6967cbac-fd29-4cdb-8e4c-fda8d9eda6cc",
  },
  ID_COPY: {
    category: CATEGORIES.OFFICIAL_IDENTIFICATION_DOCUMENTS,
    name: "ID copy",
    id: "09246653-643c-4a41-a1e5-9f7b04c8a323",
    extraPermissionRequired: PERMISSIONS.ID_COPY,
  },
  ID_COPY_OF_CEO: {
    category: CATEGORIES.OFFICIAL_IDENTIFICATION_DOCUMENTS,
    name: "ID Copy of CEO",
    id: "8b6c8d45-0c29-4d06-b945-c55281536fef",
    extraPermissionRequired: PERMISSIONS.ID_CEO,
  },
  ID_COPY_OF_BOARD_MEMBER: {
    category: CATEGORIES.OFFICIAL_IDENTIFICATION_DOCUMENTS,
    name: "ID Copy of Board Member",
    id: "71b4c30c-c6d3-468a-b21d-c8fd2390012f",
    extraPermissionRequired: PERMISSIONS.ID_BOARD_MEMBER,
  },
  ID_COPY_OF_UBO: {
    category: CATEGORIES.OFFICIAL_IDENTIFICATION_DOCUMENTS,
    name: "ID Copy of UBO",
    id: "03b80a6f-5cbd-4669-9c16-dc49cc97f085",
    extraPermissionRequired: PERMISSIONS.ID_UBO,
  },
  ID_COPY_OF_AUTHORIZED_SIGNATORY: {
    category: CATEGORIES.OFFICIAL_IDENTIFICATION_DOCUMENTS,
    name: "ID Copy of authorized signatory",
    id: "052ef7ed-3c60-4be1-b7a7-99d103c30a63",
    extraPermissionRequired: PERMISSIONS.ID_AUTHORIZED_SIGNATORY,
  },
  OTHER_PROOF_OF_IDENTITY: {
    category: CATEGORIES.OFFICIAL_IDENTIFICATION_DOCUMENTS,
    name: "Other proof of identity",
    id: "49c8776e-f8f8-4aff-a80e-f98431311ec4",
    extraPermissionRequired: PERMISSIONS.ID_OTHER,
  },
  CONFLICT_OF_INTEREST_POLICY: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Conflict of interest policy",
    id: "d4b26a2b-e2ae-4e5f-b08e-b4693f614a29",
  },
  CLIENTS_COMPLAINTS_HANDLING_PROCEDURE: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Clients' Complaints Handling procedure",
    id: "86bae9da-5f8b-4b5d-8fe5-9d5a8bad8a89",
  },
  PERSONAL_ACCOUNT_PROCEDURE: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Personal Account procedure",
    id: "351a3f9b-d0c6-4543-84d6-8e0e0500f882",
  },
  INVESTMENT_RESEARCH_PROCEDURE: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Investment Research procedure",
    id: "7daed206-a59e-4ea1-a4e3-5544ec7ba5d0",
  },
  RISK_MANAGEMENT_POLICY: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Risk Management policy",
    id: "6186c7f4-bf60-4485-9281-6eca534df4df",
  },
  RISK_APPROACH_POLICY: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Risk Approach policy",
    id: "dc417771-186c-48ef-b763-e7b1676e607e",
  },
  ANTI_BRIBERY_AND_CORRUPTION_POLICY: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Anti Bribery and Corruption policy",
    id: "7244a2ca-fbd0-43db-bcb3-7001099af7ad",
  },
  SHAREHOLDING_STRUCTURE_WITH_UBO: {
    category: CATEGORIES.SHAREHOLDERS_GOVERNING_BODIES,
    name: "Shareholding structure with UBO",
    id: "5d7c6fb1-6db1-4be5-a3cc-cc28476fd874",
  },
  MANAGEMENT_OVERVIEW: {
    category: CATEGORIES.SHAREHOLDERS_GOVERNING_BODIES,
    name: "Management Overview",
    id: "55b704dd-c30c-4b7f-96db-47fda4bca8b2",
  },
  OWNERSHIP_OVERVIEW: {
    category: CATEGORIES.SHAREHOLDERS_GOVERNING_BODIES,
    name: "Ownership overview",
    id: "b7194803-3bad-41f6-b168-90edc693dc62",
  },
  RESUME_OF_CEO: {
    category: CATEGORIES.OTHER_DOCUMENTS,
    name: "Resume of CEO",
    id: "7dcd9e35-06c1-4add-9ea3-0a127f0c7208",
  },
  RESUME_OF_CFO: {
    category: CATEGORIES.OTHER_DOCUMENTS,
    name: "Resume of CFO",
    id: "8495fa81-a62e-4a70-a3a5-0116d57ce9cd",
  },
  RESUME_OF_CHAIRMAN: {
    category: CATEGORIES.OTHER_DOCUMENTS,
    name: "Resume of Chairman",
    id: "014d3b98-8606-4104-a636-04884085dfc1",
  },
  RESUME_OF_BOARD_MEMBER: {
    category: CATEGORIES.OTHER_DOCUMENTS,
    name: "Resume of Board Member",
    id: "a5b4b119-47ad-4fe6-a1fc-10fc21f233b8",
  },
  RESUME_OF_KEY_STAFF: {
    category: CATEGORIES.OTHER_DOCUMENTS,
    name: "Resume of Key staff",
    id: "072b909b-c3fe-4b51-a24d-ec88e22b99be",
  },
  ACCOUNT_OPENING_PROCEDURE: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Account opening procedure",
    id: "43ffb1a3-6a81-405e-8f6f-b82649243192",
  },
  ESG_POLICY: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "ESG Policy",
    id: "3a552178-afd3-417b-93bf-437c938dc4e8",
  },
  CODE_OF_CONDUCT: {
    name: "Code of Conduct",
    category: CATEGORIES.POLICIES_GUIDELINES,
    id: "bef3583b-676e-42ba-92d2-890ded0d910c",
  },
  CYBER_SECURITY_POLICIES: {
    name: "Cyber Security Policies",
    category: CATEGORIES.POLICIES_GUIDELINES,
    id: "4cfee9da-b17a-4b19-9422-9aa0272fc97f",
  },
  FRAUD_PREVENTION_POLICY: {
    id: "5e7e2409-eb77-446f-842d-d0490cc0e717",
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Fraud prevention policy",
  },
  WOLFSBERG_QUESTIONNAIRE: {
    id: "925109b8-b468-4abd-82c0-db7679bb6286",
    category: CATEGORIES.INDUSTRY_STANDARD_QUESTIONNAIRES,
    name: "Wolfsberg questionnaire",
  },
  W_FORM: {
    id: "45c6708b-3a8e-4090-be55-822c830c70ed",
    category: CATEGORIES.CERTIFICATION_GUARANTEES,
    name: "W-Form",
  },
  LIST_OF_DIRECTORS: {
    id: "ae77afba-9222-485d-b700-14776a8d3897",
    category: CATEGORIES.SHAREHOLDERS_GOVERNING_BODIES,
    name: "List of directors",
  },
  AFG_AF2I: {
    id: "163392e0-b51a-4cdc-8e80-bfadf0bdc027",
    category: CATEGORIES.INDUSTRY_STANDARD_QUESTIONNAIRES,
    name: "AFG/Af2i questionnaire",
  },
  AVATAR: {
    hide: true,
    category: CATEGORIES.OTHER_DOCUMENTS,
    id: "da82f891-5df0-4e26-ab84-a5ab16dabe2e",
    name: "avatar",
  },
  SWIFT_CSP_SELF_DECLARATION: {
    category: CATEGORIES.CERTIFICATION_GUARANTEES,
    name: "SWIFT CSP Self declaration",
    id: "1c7958ec-c5ac-4cde-a844-cdffc0f111dc",
  },
  AFME_QUESTIONNAIRE: {
    category: CATEGORIES.INDUSTRY_STANDARD_QUESTIONNAIRES,
    name: "AFME questionnaire",
    id: "7afad1b6-8002-4de1-b591-a255bce09b58",
  },
  GCQ_CUSTODIANS_QUESTIONNAIRE: {
    category: CATEGORIES.INDUSTRY_STANDARD_QUESTIONNAIRES,
    name: "GCQ Global Custodians questionnaire",
    id: "3591c90f-e645-4337-81a4-7d5f4df093aa",
  },
  FSB_QUESTIONNAIRE: {
    category: CATEGORIES.INDUSTRY_STANDARD_QUESTIONNAIRES,
    name: "FSB document Financial Stability Board",
    id: "b6c48e14-edb9-4916-bf21-2b0c3f04bc5f",
  },
  POA_CEO: {
    category: CATEGORIES.OFFICIAL_IDENTIFICATION_DOCUMENTS,
    name: "Proof of address of CEO",
    id: "8030a342-e9c3-4587-9e06-68b04573b790",
  },
  POA_UBO: {
    category: CATEGORIES.OFFICIAL_IDENTIFICATION_DOCUMENTS,
    name: "Proof of address of UBO",
    id: "f5b123a1-ccfd-461c-83fe-a0d8c3d8795c",
  },
  ISSA: {
    category: CATEGORIES.INDUSTRY_STANDARD_QUESTIONNAIRES,
    name: "ISSA questionnaire",
    id: "8c33b918-316c-4907-a87c-3f4cc0e6e92e",
  },
  SANCTIONS_POLICY: {
    category: CATEGORIES.POLICIES_GUIDELINES,
    name: "Sanctions policy",
    id: "dab4c49b-672a-40b1-83e7-ff4fd5e8749d",
  },
});

const ALL_VISIBLE_DOCUMENT_TYPES = sortBy(
  Object.values(DOCUMENTS_TYPES_LIST).filter((doc: DocumentType) => !doc.hide),
  (item) => item.name.toLowerCase(),
);

const ALL_DOCUMENT_TYPES_OBJ = keyBy(ALL_VISIBLE_DOCUMENT_TYPES, "id");

const ALL_DOCUMENT_TYPES_BY_CATEGORY = sortBy(ALL_VISIBLE_DOCUMENT_TYPES, [
  "category",
  (item) => item.name.toLowerCase(),
]);

const categoriesObj: any = {};

ALL_DOCUMENT_TYPES_BY_CATEGORY.forEach((doc: DocumentType) => {
  if (doc.hide) return;
  if (!categoriesObj[doc.category]) {
    categoriesObj[doc.category] = {
      id: doc.category,
      items: [],
    };
  }
  categoriesObj[doc.category].items.push(doc);
});

const ALL_DOCUMENT_TYPES_PER_CATEGORIES = Object.keys(categoriesObj).map(
  (key) => categoriesObj[key],
);

const getDocTypesByPermissions = (permissions: string[]) => {
  const permissionsObj: any = {};

  ALL_DOCUMENT_TYPES_BY_CATEGORY.forEach((doc: DocumentType) => {
    if (doc.hide) return;
    if (!permissionsObj[doc.category]) {
      permissionsObj[doc.category] = {
        id: doc.category,
        items: [],
      };
    }
    if (doc.extraPermissionRequired) {
      for (const permission of permissions) {
        if (permission === doc.extraPermissionRequired.CREATE) {
          permissionsObj[doc.category].items.push(doc);
        }
      }
    } else {
      permissionsObj[doc.category].items.push(doc);
    }
  });

  return Object.keys(permissionsObj).map((key) => permissionsObj[key]);
};

/**
 * Get all document types as an array
 *
 */
export function items() {
  return ALL_VISIBLE_DOCUMENT_TYPES;
}

/**
 * Get all document types as an array
 *
 */
export function byCategories() {
  return ALL_DOCUMENT_TYPES_PER_CATEGORIES;
}

/**
 * Get all document types as an array
 *
 */
export function types() {
  return DOCUMENTS_TYPES_LIST;
}

/**
 * Get all document types ids without hide as an array
 *
 */

export const DOCUMENTS_TYPES_LIST_BY_ID = ALL_VISIBLE_DOCUMENT_TYPES.map(
  (doc) => doc.id,
);

/**
 * Get a document type by its id
 *
 */
export function byId(id: string) {
  return ALL_DOCUMENT_TYPES_OBJ[id];
}

/**
 * Get all document types by user permission
 *
 */
export function byPermissions(permissions: string[]) {
  return getDocTypesByPermissions(permissions);
}

// DocumentTypes.filter({in: counterparty.type.documentTypeIds}),
export function filter(
  filter: Partial<DocumentTypeFilter>,
): Array<DocumentType> {
  let matches = ALL_VISIBLE_DOCUMENT_TYPES;
  if ("in" in filter) {
    matches = matches.filter((x) => filter.in?.includes(x.id));
  }

  return sortBy(matches, "name");
}
