import {
  TextQuestion,
  ChoiceQuestion,
  YesNoQuestion,
  QuestionGroup,
} from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.SALES_RESTRICTIONS,
  questions: [
    new QuestionGroup("Territorial restrictions", {
      variant: "noBorder",
      questions: [
        new ChoiceQuestion({
          key: "maintainPolicies",
          // Distribution and marketing restrictions
          label:
            "Do you maintain policies and procedures to comply with local laws related to marketing and distribution of funds?",
          choices: [
            { value: "Yes" },
            {
              value: "No",
              questions: [TextQuestion.Explain("maintainPoliciesNo")],
            },
            {
              value: "Not applicable",
              questions: [TextQuestion.Explain("maintainPolicies-NA-explain")],
            },
          ],
        }),
        new ChoiceQuestion({
          key: "private_placement",
          // Private Placement
          label:
            "In case you place funds in countries where they are not authorized for public distribution, please confirm that you implemented an effective and appropriate process to comply with the placement rules and regulations applicable in such countries, including a process to ensure that the funds are underwritten or held solely by eligible investors?",
          choices: [
            { value: "Yes" },
            {
              value: "No",
              questions: [TextQuestion.Explain("private_placement_explain")],
            },
            {
              value: "Not applicable",
              questions: [TextQuestion.Explain("private_placement-NA-explain")],
            },
          ],
        }),
        new ChoiceQuestion({
          key: "TerritorialRestrictions",
          // Compliance with local laws
          label:
            "How do you secure that your policies and procedures are compliant with local laws at all times?",
          choices: [
            {
              value:
                "Review of policies and procedures by external advisors on a regular basis",
            },
            {
              value:
                "Review of policies and procedures by Internal legal advisors on a regular basis",
            },
            {
              value:
                "System for regular monitoring of local legal requirements",
            },
            { value: "Regular due diligence of sub-distributors" },
            {
              value: "Other",
              questions: [
                new TextQuestion({
                  key: "TerritorialRestrictionsOther",
                  label: "Specify other methods",
                }),
              ],
            },
          ],
        }),
        new ChoiceQuestion({
          key: "MIFIDTargetMarket",
          label:
            "Do you maintain procedures ensuring that you promote the funds in compliance with the target market definition?",
          choices: [
            {
              value: "Yes",
              questions: [
                new TextQuestion({
                  key: "MIFIDTargetMarketYes",
                  label: "Please describe",
                  isMultiLine: true,
                }),
              ],
            },
            {
              value: "No",
              questions: [TextQuestion.Explain("MIFIDTargetMarketOther")],
            },
            {
              value: "Not applicable",
              questions: [TextQuestion.Explain("MIFIDTargetMarket-NA-explain")],
            },
          ],
        }),
      ],
    }),
    new QuestionGroup("Client Type Restrictions", {
      //subtitle: "Describe your policies, procedures and systems, if any, to secure that the funds are offered to the right types of clients and that they are not offered to restricted client types. E.g. U.S. persons and tax residents"
      questions: [
        new ChoiceQuestion({
          key: "restrictedClientTypes",
          label: "Restricted client types",
          isMultipleChoice: true,
          choices: [
            { value: "No restricted types of clients" },
            { value: "U.S. persons" },
            {
              value: "Other",
              questions: [
                new TextQuestion({
                  key: "restrictedClientTypesOther",
                  label: "Specify other restricted clients",
                }),
              ],
            },
          ],
        }),
        new ChoiceQuestion({
          key: "clientTypesPoliciyProcessMaintenance",
          label:
            "Do you maintain policies and procedures to ensure that you comply with client restrictions, including those specified in the prospectus of the funds?",
          isMultipleChoice: false,
          choices: [
            { value: "Yes" },
            {
              value: "No",
              questions: [
                TextQuestion.Explain(
                  "clientTypesPoliciyProcessMaintenance_explain",
                ),
              ],
            },
            {
              value: "Not applicable",
              questions: [
                TextQuestion.Explain(
                  "clientTypesPoliciyProcessMaintenance-NA-explain",
                ),
              ],
            },
          ],
        }),
        new YesNoQuestion({
          key: "confirm_policy",
          label:
            "Please confirm that these policies and procedures integrate actions plans in the event of a change in the investor's situation after subscription.",
          No: [TextQuestion.Explain("confirm_policy_explain")],
        }),
      ],
    }),
    new QuestionGroup("Information to clients", {
      //subtitle: "Describe your policies, procedures and systems, if any, to secure that fund offering documents are offered to the clients in accordance with applicable laws (incl. prior to subscription where required). "
      questions: [
        new YesNoQuestion({
          key: "mandatoryInformationPolicyProcessMaintenance",
          label:
            "Do you maintain policy and procedures to ensure that you provide clients with all the fund documents in accordance with applicable laws?",
        }),
        new ChoiceQuestion({
          key: "StandardDocs",
          label:
            "Type of standard documentation (produced by fund manufacturers) provided to clients in relation to sales or marketing activities",
          isMultipleChoice: true,
          choices: [
            { value: "Fact sheet" },
            { value: "Prospectus" },
            { value: "KIID" },
            {
              value: "Other",
              questions: [
                new TextQuestion({
                  key: "StandardDocsOther",
                  label: "Specify other documents",
                }),
              ],
            },
          ],
        }),
      ],
    }),
    new QuestionGroup("Marketing material", {
      questions: [
        new ChoiceQuestion({
          key: "MarketingMaterial",
          label:
            "Do you produce your own marketing material for use in relation to distribution and marketing of the funds?",
          isMultipleChoice: false,
          choices: [
            {
              value: "Yes",
              questions: [
                new YesNoQuestion({
                  key: "marketing_material_ensure",
                  label:
                    "Do you ensure that any marketing material other than that provided by the fund manufacturers has been submitted to them and declared to be compliant by them prior to its communication to any third parties?",
                  No: [
                    TextQuestion.Explain("marketing_material_ensure_explain"),
                  ],
                }),
                new YesNoQuestion({
                  key: "MarketingMaterialMaintain",
                  label:
                    "Do you maintain procedures ensuring that your marketing materials are compliant with local laws and regulations on marketing?",
                  No: [TextQuestion.Explain("MarketingMaterialOther")],
                }),
              ],
            },
            {
              value:
                "No, only official fund documents from relevant manufacturers are used",
            },
            {
              value: "Other material",
              questions: [
                new TextQuestion({
                  key: "MarketingMaterialMaintainOther",
                  label: "Specify other type of material",
                  isMultiLine: true,
                }),
              ],
            },
            {
              value: "Not applicable",
              questions: [TextQuestion.Explain("MarketingMaterial-NA-explain")],
            },
          ],
        }),
      ],
    }),
    new QuestionGroup("Client protection", {
      questions: [
        new ChoiceQuestion({
          key: "MIFIDAndClientProtection",
          label:
            "Are you performing tests for suitability/appropriateness in order to secure that the products meet the client needs and the risk profile of the client?",
          //label: "Suitability & Appropriateness",
          isMultipleChoice: false,
          choices: [
            {
              value: "Yes",
              questions: [
                new ChoiceQuestion({
                  key: "MIFIDAndClientProtectionFreq",
                  label:
                    "When are suitability/appropriateness tests performed?",
                  choices: [
                    {
                      value:
                        "Before the establishment of the client relationship",
                    },
                    { value: "Annually and upon need" },
                    {
                      value: "Other frequency",
                      questions: [
                        new TextQuestion({
                          key: "MIFIDAndClientProtectionFreqOther",
                          label: "Specify other frequency",
                          isMultiLine: true,
                        }),
                      ],
                    },
                  ],
                }),
              ],
            },
            {
              value: "No",
              questions: [
                TextQuestion.Explain("MIFIDAndClientProtectionOther"),
              ],
            },
            {
              value: "Not applicable",
              questions: [
                TextQuestion.Explain("MIFIDAndClientProtection-NA-explain"),
              ],
            },
          ],
        }),
        new ChoiceQuestion({
          key: "MIFIDProductGovernance",
          //label: "Product Governance",
          label:
            "Do you maintain procedures ensuring effective control over the product governance process?",
          choices: [
            { value: "Yes" },
            {
              value: "No",
              questions: [TextQuestion.Explain("MIFIDProductGovernanceOther")],
            },
            {
              value: "Not applicable",
              questions: [TextQuestion.Explain("MIFIDProductGovernanceOther")],
            },
          ],
        }),
        new ChoiceQuestion({
          key: "InternalProcessForReviewAndApprovalOfNewFunds",
          label:
            "Does your firm have an internal process for the review and approval of new fund products to be distributed?",
          choices: [{ value: "No" }, { value: "Yes" }, { value: "N/A" }],
        }),
      ],
    }),
    new QuestionGroup("Best Execution", {
      //subtitle: "Describe your policies, processes and systems, if any, to secure compliance with rules related to Best Execution."
      questions: [
        new ChoiceQuestion({
          key: "bestExecutionPoliciesPolicyProcessMaintenance",
          label:
            "Do you maintain policy and procedures to secure compliance with rules related to best execution?",
          choices: [
            {
              value: "Yes",
              questions: [
                new TextQuestion({
                  key: "bestExecutionPoliciesPolicyProcessMaintenanceDescription",
                  label: "Please describe",
                }),
              ],
            },
            { value: "No" },
            {
              value: "Not applicable",
              questions: [
                TextQuestion.Explain(
                  "bestExecutionPoliciesPolicyProcessMaintenance-NA-explain",
                ),
              ],
            },
          ],
        }),
      ],
    }),
    new QuestionGroup("Market timing & Late trading", {
      questions: [
        new YesNoQuestion({
          key: "marketTimingPoliciesProcessMaintenance",
          label:
            "Do you maintain policies and procedures to secure compliance with rules related to market timing and late trading?",
          Yes: [
            new TextQuestion({
              key: "marketTimingPoliciesProcessMaintenanceDescription",
              label: "Please describe",
            }),
          ],
        }),
      ],
    }),
    new QuestionGroup("Handling of complaints", {
      //subtitle:"Describe your policies, procedures, to secure compliance with rules related to handling of client complaints, and confirm if you handle that internally or through a third party.",
      questions: [
        new YesNoQuestion({
          key: "HandlingOfClientPoliciesProcessMaintenance",
          label:
            "Do you maintain policy and procedure to secure compliance with rules related to handling of client complaints?",
          Yes: [
            new TextQuestion({
              key: "HandlingOfClientPoliciesProcessMaintenanceDescription",
              label:
                "Please describe process of escalation and storage of documents",
              isMultiLine: true,
            }),
          ],
        }),
        new ChoiceQuestion({
          key: "HandlingOfClientReport",
          label:
            "Do you report complaints regarding a certain fund to the fund manufacturer in question?",
          choices: [
            { value: "Yes" },
            {
              value: "No",
              questions: [TextQuestion.Explain("HandlingOfClientReportNo")],
            },
            {
              value: "Not applicable",
              questions: [
                TextQuestion.Explain("HandlingOfClientReport-NA-explain"),
              ],
            },
          ],
        }),
        new ChoiceQuestion({
          key: "written_agreement",
          label:
            "Do you ensure, through a written agreement, that your sub-distributors transmit to you any and all client complaints that they receive?",
          choices: [
            { value: "Yes" },
            {
              value: "No",
              questions: [TextQuestion.Explain("written_agreement_explain")],
            },
            {
              value: "Not applicable",
              questions: [TextQuestion.Explain("written_agreement-NA-explain")],
            },
          ],
        }),
      ],
    }),
    new QuestionGroup("Conflicts of interests", {
      questions: [
        new YesNoQuestion({
          key: "conflicts",
          label:
            "Have you implemented a policy and a procedure in order to secure that conflicts of interests are handled in accordance with applicable laws and rules?",
        }),
        new TextQuestion({
          key: "conflictsComment",
          label: "Comments",
          isMultiLine: true,
        }),
      ],
    }),
  ],
};

export default category;
