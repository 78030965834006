/**
 * List of all possible categories in the questionnaires that we build for different profile types.
 *
 * Ideally you should be able to use the value if you need the ID and the KEY if you need a translation string via i18n
 */

type QuestionnaireItem = {
  name: string;
  id: string;
  icon?: string;
  key?: string;
  hide?: boolean;
  public?: boolean;
};

type QuestionnaireDef = {
  [key: string]: QuestionnaireItem;
};

const questionnaireCategories: QuestionnaireDef = {
  MANAGEMENT_AND_OWNERSHIP: {
    id: "bf5c4e38-2906-46a1-9eef-52c678c72c6f",
    name: "Management & Ownership",
  },
  TRANSFER_AGENT_ACTIVITY: {
    id: "0c481d35-abe1-4b53-ba37-df1eb7a0b93e",
    name: "Transfer Agent Activity",
  },
  PROCESSES_AND_REPORTING: {
    id: "42376ac8-9de5-482f-b843-f94467590794",
    name: "Processes and Reporting",
  },
  ADDITIONAL_INFORMATION: {
    id: "bfd585d8-dc99-45ed-bd24-38e0eb575384",
    name: "Additional Information",
  },
  ESG: {
    id: "d2e5f5c5-0969-4bb2-a3a5-46d27a05d0ee'",
    name: "ESG",
    icon: "esg",
  },
  CORPORATE_INFO: {
    id: "4baf7b93-86f2-4f68-b852-75cce1736bdc",
    name: "Corporate",
    public: true,
  },
  REGULATORY_STATUS: {
    id: "35b77490-5c0f-4e82-9a26-c9d4df2d0cb9",
    name: "Regulatory Status",
  },
  GOVERNANCE_RISK_COMPLIANCE: {
    id: "4baf7b93-86f2-4f68-b852-75cce1736bd5",
    name: "Governance, Risk and Compliance",
  },
  TAX_INFORMATION: {
    id: "c14a2e25-b52a-4e7f-9937-30de7c6501fb",
    name: "Tax Information",
  },
  IT_SECURITY_BUSINESS_CONTINUITY: {
    id: "f2006e97-283b-4c4e-8953-7aeac3167155",
    name: "IT & Business Continuity",
  },
  AML_AND_CFT_INVESTMENT_PROCESS: {
    id: "5a270aa0-3779-11ea-9d90-c1c53492f0c0",
    name: "AML & CFT Investment Process",
  },
  INVESTMENT_SELECTION: {
    id: "16d98545-3440-4ceb-924f-c41930490925",
    name: "Investment Selection",
  },
  HUMAN_RESOURCES: {
    id: "523f9dea-6228-4170-9e68-66209c5952ba",
    name: "Human Resources",
  },
  INSTRUMENTS_AND_MARKETS: {
    id: "4baf7b93-86f2-4f68-b852-75cce1736aaa",
    name: "Instruments & Markets",
  },
  SETTLEMENT_AND_TRADING: {
    id: "4baf7b93-86f2-4f68-b852-75cce1736bbb",
    name: "Settlement & Trading",
  },
  FINANCIAL: { id: "ea464ea0-3b9a-11ea-941f-f3d4b3057049", name: "Financial" },
  MIFID2: { id: "8d395ed3-4465-4ffb-9bc0-5a126a3c8fMIFID", name: "MIFID II" },
  AML_AND_CFT_PROCEDURES: {
    id: "4baf7b93-86f2-4f68-b852-75cce1736bdf",
    name: "AML & CFT Procedures",
  },
  SALES_RESTRICTIONS: {
    id: "4baf7b93-86f2-4f68-b852-75cce1736bdg",
    name: "Sales Restrictions",
  },
  TYPE_BUSINESS_CLIENT_BASE: {
    id: "3e02050e-af28-4bbd-a9bf-9517185107d9",
    name: "Type of business & Client base",
  },
  KYC: {
    id: "3641d606-8cba-4fe3-bb54-bf5e52cbb112",
    name: "Due Diligence of counterparties",
  },
  ACTIVITY: { id: "372fc8f7-4fde-4a5f-8d6c-efdfcc321f09", name: "Activity" },
  INVESTMENT_INFORMATION: {
    id: "65cb30be-7671-43ca-9754-b4944e7e3147",
    name: "Investment Information",
  },
  SUMMARY_1: {
    id: "8d395ed3-4465-4ffb-9bc0-5a126a3c8f92",
    name: "Summary",
    hide: true,
  }, // summary (this is in the DB but we don't have any mapping for it})
  SUMMARY_2: {
    id: "8d395ed3-4465-4ffb-9bc0-5a126a3c8f93",
    name: "Summary",
    hide: true,
  }, // also summary (this is in the DB but we don't have any mapping for it})
};

const questionnaireById: QuestionnaireDef = {};
Object.keys(questionnaireCategories).forEach((key) => {
  const item = questionnaireCategories[key as keyof QuestionnaireDef];
  questionnaireById[item.id] = {
    key,
    ...item,
  };
});

const QUESTIONNAIRE_CATEGORIES = Object.freeze(questionnaireCategories);
const QUESTIONNAIRE_CATEGORIES_BY_ID = Object.freeze(questionnaireById);
const QUESTIONNAIRE_CATEGORIES_IDS = Object.keys(questionnaireById);
const QUESTIONNAIRE_CATEGORIES_PUBLIC_IDS = QUESTIONNAIRE_CATEGORIES_IDS.filter(
  (id: string) => QUESTIONNAIRE_CATEGORIES_BY_ID[id].public,
);

export {
  QUESTIONNAIRE_CATEGORIES,
  QUESTIONNAIRE_CATEGORIES_BY_ID,
  QUESTIONNAIRE_CATEGORIES_IDS,
  QUESTIONNAIRE_CATEGORIES_PUBLIC_IDS,
};
