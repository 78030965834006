import { DocumentTypes } from "@app/document-types";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const DOCUMENTS = DocumentTypes.types();

const profile = {
  name: "Management Company",
  id: "ac666a0d-2606-45a0-8fe0-68e05dfbd485",
  forms: [
    QUESTIONNAIRE_CATEGORIES.ADDITIONAL_INFORMATION.id,
    QUESTIONNAIRE_CATEGORIES.CORPORATE_INFO.id,
    QUESTIONNAIRE_CATEGORIES.ESG.id,
    QUESTIONNAIRE_CATEGORIES.GOVERNANCE_RISK_COMPLIANCE.id,
    QUESTIONNAIRE_CATEGORIES.HUMAN_RESOURCES.id,
    QUESTIONNAIRE_CATEGORIES.IT_SECURITY_BUSINESS_CONTINUITY.id,
    QUESTIONNAIRE_CATEGORIES.MANAGEMENT_AND_OWNERSHIP.id,
    QUESTIONNAIRE_CATEGORIES.REGULATORY_STATUS.id,
    QUESTIONNAIRE_CATEGORIES.TAX_INFORMATION.id,
  ],
  files: [],
  basicPack: {
    forms: [
      QUESTIONNAIRE_CATEGORIES.GOVERNANCE_RISK_COMPLIANCE.id,
      QUESTIONNAIRE_CATEGORIES.MANAGEMENT_AND_OWNERSHIP.id,
      QUESTIONNAIRE_CATEGORIES.REGULATORY_STATUS.id,
      QUESTIONNAIRE_CATEGORIES.TAX_INFORMATION.id,
    ],
    files: [
      DOCUMENTS.AUDITED_FINANCIAL_ANNUAL_REPORTS.id,
      DOCUMENTS.AUTHORIZED_SIGNATORY_LIST.id,
      DOCUMENTS.CERTIFICATE_OF_REGISTRATION.id,
      DOCUMENTS.CERTIFICATION_OD_LICENSES_BY_FSA.id,
      DOCUMENTS.ID_COPY_OF_UBO.id,
      DOCUMENTS.LIST_OF_BENEFICIAL_OWNERS.id,
      DOCUMENTS.OWNERSHIP_OVERVIEW.id,
      DOCUMENTS.SELF_CERTIFICATION_OF_FATCA_STATUS.id,
    ],
  },
};

export default profile;
