import React from "react";

import { NavBar as CorvusNavBar } from "@gfw/corvus";

import menuItems from "./navbar.config";

function NavBar() {
  return <CorvusNavBar menuItems={menuItems} />;
}

export default NavBar;
