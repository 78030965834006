import pick from "lodash/pick";
import warning from "tiny-warning";

import Corporate from "./corporate";
import RegulatoryStatus from "./regulatory-status";
import ManagementAndOwnership from "./management-and-ownership";
import TaxInformation from "./tax-information";
import InstrumentsAndMarkets from "./instruments-and-markets";
import TypeOfBusinessAndClientBase from "./type-of-business-and-client-base";
import AML_And_CFT_InvestmentProcess from "./aml-and-cft-investment-process";
import MIFID2 from "./mifid-2";
import Activity from "./activity";
import SettlementAndTrading from "./settlement-and-trading";
import AML_And_CFT_Procedures from "./aml-and-cft-procedures";
import InvestmentInformation from "./investment-information";
import SalesRestrictions from "./sales-restrictions";
import GovernanceRiskAndCompliance from "./governance-risk-and-compliance";
import IT_And_BusinessContinuity from "./it-and-business-continuity";
import Financial from "./financial";
import InvestmentSelection from "./investment-selection";
import AdditionalInformation from "./additional-information";
import DueDiligenceOfCounterparties from "./due-diligence-of-counterparties";
import TransferAgentActivity from "./transfer-agent-activity";
import processesAndReporting from "./processes-and-reporting";
import HumanResources from "./human-resources";
import ESG from "./esg";
import {
  QuestionnaireCategory,
  IQuestionnaireCategory,
} from "./QuestionnaireCategory";
import { YesNoQuestion } from "../questions";

export { IQuestionnaireCategory, QuestionnaireCategory };

export class SortableQuestionnaireCategory extends QuestionnaireCategory {
  constructor(
    category: IQuestionnaireCategory,
    public position: number,
  ) {
    super(category);
  }
}

// This is our hierarchy of categories...
// - from this we build questionnaires from category ids
const categories: Array<SortableQuestionnaireCategory> = [
  // NOTE!!
  // the order in which these are placed determines the order/position of them in the questionnaire (from 0 > array.length)
  // commented positions here just reflect what was in the DB as pos before
  Corporate, // 0
  RegulatoryStatus, // 1
  ManagementAndOwnership, // 2
  TaxInformation, // 3
  InstrumentsAndMarkets, // 4
  TypeOfBusinessAndClientBase, // 5
  AML_And_CFT_InvestmentProcess, // 6
  MIFID2, // 6
  Activity, //7
  SettlementAndTrading, // 9
  AML_And_CFT_Procedures, // 10
  InvestmentInformation, // 11
  DueDiligenceOfCounterparties, // 12
  SalesRestrictions, // 13
  GovernanceRiskAndCompliance, // 14
  IT_And_BusinessContinuity, // 16
  Financial, // 17
  InvestmentSelection, //18
  TransferAgentActivity, // 19
  processesAndReporting, // 20
  HumanResources, // 21
  ESG, // 22
  AdditionalInformation, // always last!!
].map(
  (category, position) => new SortableQuestionnaireCategory(category, position),
);

// this goes through all the categories and "lifts" up all the questions on top into this kind of structure:
/* { 
    QUESTION_1: { key, type, label, category: {id, name} },
    QUESTION_2: { key, type, label, category: {id, name} } 
    QUESTION_3: { key, type, label, category: {id, name} }  
} */
export const ALL_QUESTIONS = categories.reduce<{ [id: string]: any }>(
  (questions, category) => {
    return {
      ...questions,
      ...category
        .getQuestions({ depth: Infinity })
        .reduce((questions, question) => {
          const choices = [];
          if (question.type === "choice") {
            // for sanity check purpose
            const { choices: questionChoices } = question as YesNoQuestion;
            choices.push(...questionChoices.map(({ value }) => value));
          }
          const result: any = {
            ...pick(question, "key", "type", "label", "isMultipleChoice"),
            category: pick(category, "id", "name", "icon"),
          };
          if (choices.length) {
            result.choices = choices;
          }
          return {
            ...questions,
            [question.key]: result,
          };
        }, {}),
    };
  },
  {},
);

export function getQuestionnaireQuestionById(id: string) {
  const question = ALL_QUESTIONS[id];
  warning(
    !question,
    `[@gfw:core] Question with id "${id}" was not found while searching through the all questions we have mappings for.`,
  );

  return question;
}

export function getQuestionnaireCategoryByQuestionId(id: string) {
  return ALL_QUESTIONS[id]?.category?.id;
}

export function getQuestionnaireCategoryById(id: string) {
  return categories.find((category) => category.id === id);
}

export function getQuestionnaireCategoriesIds() {
  return categories.map((category) => category.id);
}

export function getQuestionnaireCategories() {
  return categories;
}

export default categories;
