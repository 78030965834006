/* eslint-disable no-unused-vars */
const PROFILE_EVENT = {
  INFORMATION_UPDATED: "INFORMATION_UPDATED",
  DOCUMENTS_ADDED: "DOCUMENTS_ADDED",
  DOCUMENTS_REMOVED: "DOCUMENTS_REMOVED",
  USER_ADDED: "USER_ADDED",
  USER_REMOVED: "USER_REMOVED",
} as const;

enum PROFILE_STATE {
  PROPOSED,
  DRAFT,
  OFFICIAL,
  INACTIVE = 100,
}

const profileStateToString = (state: number): string => {
  if (state === PROFILE_STATE.PROPOSED) return "Proposed";
  if (state === PROFILE_STATE.DRAFT) return "Draft";
  if (state === PROFILE_STATE.OFFICIAL) return "Active";
  if (state === PROFILE_STATE.INACTIVE) return "Inactive";
  return "Unknown";
};

enum PROFILE_DECLINED_REASONS {
  DUPLICATED,
}

export {
  PROFILE_STATE,
  PROFILE_EVENT,
  profileStateToString,
  PROFILE_DECLINED_REASONS,
};
