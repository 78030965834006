/* eslint-disable no-unused-vars */
enum ADMIN_ROLE {
  FULL = "ADMIN_FULL",
}

const getAdminRoles = () => Object.values(ADMIN_ROLE);

enum USER_ROLE {
  FULL = "FULL",
  REGULAR = "REGULAR",
  READONLY = "READONLY",
}

const getUserRoles = () => Object.values(USER_ROLE);

const ROLE_LEVEL = Object.freeze({
  READONLY: [USER_ROLE.READONLY, USER_ROLE.REGULAR, USER_ROLE.FULL],
  REGULAR: [USER_ROLE.REGULAR, USER_ROLE.FULL],
  FULL: [USER_ROLE.FULL],
  ADMIN: [ADMIN_ROLE.FULL],
  REGULAR_ADMIN: [USER_ROLE.REGULAR, USER_ROLE.FULL, ADMIN_ROLE.FULL],
  ALL: [USER_ROLE.READONLY, USER_ROLE.REGULAR, USER_ROLE.FULL, ADMIN_ROLE.FULL],
});

const customRoleLevel = (...args: any[]): string[] => {
  return args.reduce((acc, arg) => {
    if (typeof arg === "string" && !acc.includes(arg)) {
      acc.push(arg);
    } else if (typeof arg === "object" && arg.length) {
      arg.forEach((v: string) => {
        if (!acc.includes(v)) acc.push(v);
      });
    } else {
      throw new Error(`not an valid role ${arg}`);
    }
    return acc;
  }, []);
};

export {
  ADMIN_ROLE,
  customRoleLevel,
  getAdminRoles,
  getUserRoles,
  ROLE_LEVEL,
  USER_ROLE,
};
