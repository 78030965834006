import { Form } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import React from "react";
import styled from "styled-components";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  FormInput,
  Icon,
  ItemOwnerForm,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

import {
  Page,
  PageTitle,
  PageTitleTypo,
} from "@app/resources/generic/components";

const StyledForm = styled(Form)`
  max-width: 1024px;
`;

function CustomQuestionnaireNew() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutateAsync: createQuestionnaire, isLoading: isCreateLoading } =
    useMutation(
      () => {
        const values = form.getFieldsValue();
        delete values.name;
        return apiClient.post("questionnaire", { ...values, isRoot: true });
      },
      {
        onSuccess: ({ data }) => {
          notification.success({ message: "Questionnaire has been created" });
          navigate(`/questionnaire/${data._id}`);
        },
        onError: __DEPRECATED__ErrorHandler,
      },
    );

  return (
    <Page>
      <PageTitle>
        <Icon mr="xs" type="document" />
        <PageTitleTypo>Create questionnaire</PageTitleTypo>
      </PageTitle>
      <Card>
        <StyledForm
          autoComplete="off"
          data-form-type="other"
          form={form}
          labelCol={{ span: 6 }}
          onFinish={createQuestionnaire}
        >
          <ItemOwnerForm form={form} />
          <FormInput label="Title" name="label" required />
          <FormInput
            autoSize
            label="description"
            name="description"
            type="textarea"
          />
        </StyledForm>

        <Button
          data-testid="createNewCQ"
          loading={isCreateLoading}
          onClick={form.submit}
          type="primary"
        >
          Create
        </Button>
      </Card>
    </Page>
  );
}

export default CustomQuestionnaireNew;
