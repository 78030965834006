import _countries from "./countries"; // ref: https://datahub.io/core/country-list
import _regions from "./regions";
import { matchSorter } from "match-sorter";

type SearchableKeys = "name" | "code";

const all = [..._countries, ..._regions];

export function findCountriesBy(
  term: string,
  keys: SearchableKeys[] = ["name"],
) {
  return matchSorter(_countries, term, {
    keys,
  });
}

export function findRegionsBy(term: string, keys: SearchableKeys[] = ["name"]) {
  return matchSorter(all, term, {
    keys,
  });
}

export const countries = _countries;

// used by forms
export const countriesOptions = countries.map((country) => ({
  label: country.name,
  value: country.name,
}));
