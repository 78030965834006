import {
  QuestionGroup,
  YesNoQuestion,
  ChoiceQuestion,
  TextQuestion,
} from "@app/questionnaire/questions";
import { toChoices } from "@app/questionnaire/questions/ChoiceQuestion";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.AML_AND_CFT_INVESTMENT_PROCESS,
  questions: [
    new QuestionGroup("Investment Control", {
      questions: [
        new YesNoQuestion({
          key: "investment-control",
          label:
            "Before making a new investment, does your institution perform controls to ensure that the investment subject isn’t named on a sanction list of countries, territories, entities and individuals?",
          Yes: [
            new ChoiceQuestion({
              key: "investment-control-sanction-list",
              label: "Sanctions issued by",
              isMultipleChoice: true,
              choices: [
                ...toChoices([
                  "The European Union",
                  "The United Nations",
                  "The OFAC",
                ]),
                {
                  value: "Other",
                  questions: [
                    TextQuestion.Specify(
                      "investment-control-sanction-list-specify",
                    ),
                  ],
                },
              ],
            }),
          ],
          No: [TextQuestion.Explain("investment-control-no")],
        }),
      ],
    }),
    new QuestionGroup("Regular basis control", {
      questions: [
        new YesNoQuestion({
          key: "regular-basis-control",
          label:
            "Does your institution perform controls on a regular basis to ensure that none of your investment subjects are named on a sanction list of countries, territories, entities and individuals?",
          Yes: [
            new ChoiceQuestion({
              key: "regular-basis-control-how-often",
              label: "How often?",
              choices: [
                ...toChoices(["Daily", "Monthly", "Yearly"]),
                {
                  value: "Other",
                  questions: [
                    TextQuestion.Specify(
                      "regular-basis-control-how-often-other",
                    ),
                  ],
                },
              ],
            }),
            new ChoiceQuestion({
              key: "regular-basis-control-sanction-list",
              label: "Sanctions issued by",
              isMultipleChoice: true,
              choices: [
                ...toChoices([
                  "The European Union",
                  "The United Nations",
                  "The OFAC",
                ]),
                {
                  value: "Other",
                  questions: [
                    TextQuestion.Specify(
                      "regular-basis-control-sanction-list-specify",
                    ),
                  ],
                },
              ],
            }),
          ],
          No: [TextQuestion.Explain("regular-basis-control-no")],
        }),
      ],
    }),
    new QuestionGroup("Due Diligence before investment", {
      questions: [
        new YesNoQuestion({
          key: "dd-before-investment",
          label:
            "Does your institution undertake Due Diligence measures for an investment subject before making a new investment?",
          Yes: [
            new ChoiceQuestion({
              key: "dd-before-investment-measures",
              label: "What do the Due Diligence measures include?",
              isMultipleChoice: true,
              choices: [
                ...toChoices([
                  "AML & CTF",
                  "Anti-Corruption",
                  "Ethic",
                  "Sustainability",
                  "Human Rights",
                ]),
                {
                  value: "Other",
                  questions: [
                    TextQuestion.Specify("dd-before-investment-measures-other"),
                  ],
                },
              ],
            }),
          ],
          No: [TextQuestion.Explain("dd-before-investment-no")],
        }),
      ],
    }),
    new QuestionGroup("Monitoring rules", {
      questions: [
        new TextQuestion({
          key: "monitoring-rules",
          label:
            "What rules have you set up for monitoring transactions of your funds (Late-trading, Market-timing)?",
          isMultiLine: true,
        }),
      ],
    }),
    new QuestionGroup("Supervision procedures", {
      questions: [
        new TextQuestion({
          key: "supervision-procedures",
          label:
            "What procedures have your company put in place regarding the supervision of issuers / persons / countries under embargo / freezing of assets?",
          isMultiLine: true,
        }),
      ],
    }),
    new QuestionGroup("Monitoring personel", {
      questions: [
        new YesNoQuestion({
          key: "monitoring-personel",
          label:
            "Is there another person(s) in your organisation, other than the Portfolio Management team, who is in charge of the monitoring of subscription/withdrawal orders?",
          Yes: [
            new QuestionGroup(
              "Please provide the contact details of that person",
              {
                questions: [
                  new TextQuestion({
                    key: "monitoring-personel-contact-name",
                    label: "Name",
                  }),
                  new TextQuestion({
                    key: "monitoring-personel-contact-surname",
                    label: "Surname",
                  }),
                  new TextQuestion({
                    key: "monitoring-personel-contact-title",
                    label: "Title",
                  }),
                  new TextQuestion({
                    key: "monitoring-personel-contact-email",
                    label: "Email",
                  }),
                ],
              },
            ),
          ],
        }),
      ],
    }),
    new QuestionGroup("Monitoring frequency", {
      questions: [
        new TextQuestion({
          key: "monitoring-frequence",
          label:
            "How frequently is the monitoring of subscription/withdrawal orders performed?",
        }),
      ],
    }),
  ],
};

export default category;
