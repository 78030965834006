import { get } from "lodash";
import { IQuestionnaireSnapshot } from "../Questionnaire";

export interface IQuestionnaireQuestion {
  label: string;
  subtitle?: string;
  helpText?: string;
  key: string;
  public?: boolean;
}

export abstract class QuestionnaireQuestion {
  key: string;
  label: string;
  subtitle?: string;
  helpText?: string;
  answer?: any;
  public: boolean;
  abstract type: string;

  constructor(configuration: IQuestionnaireQuestion) {
    this.key = configuration.key;
    this.label = configuration.label;
    this.subtitle = configuration.subtitle;
    this.helpText = configuration.helpText;
    this.public = !!configuration.public;
  }

  applySnapshot(snapshot: IQuestionnaireSnapshot): void {
    this.preProcessSnapshotAnswer(get(snapshot, this.key));
    this.onSnapshotApplied(snapshot);
  }

  abstract getQuestions(options: { depth: number }): QuestionnaireQuestion[];
  abstract clone(): QuestionnaireQuestion;

  abstract answerToString(): string;

  protected onSnapshotApplied(_snapshot: IQuestionnaireSnapshot): void {}

  protected preProcessSnapshotAnswer(answer?: any): void {
    if (answer) {
      this.answer = String(answer);
    }
  }
}
