import winston, {
  createLogger as createWinstonLogger,
  format,
  transports,
  addColors,
} from "winston";
import { EventEmitter } from "events";
import { getConfig } from "../config/config";

// (node:8779) MaxListenersExceededWarning: Possible EventEmitter memory leak detected. 11 uncaughtException listeners added to [process].
// Use emitter.setMaxListeners() to increase limit
EventEmitter.defaultMaxListeners = 100;

const config = getConfig();

interface MetasType {
  [key: string]: any;
}

const getTime = () => {
  if (!config.isDevelopment) return "";
  const timestamp = new Date().toISOString().split("T")[1].replace("Z", "");
  return `${timestamp}: `;
};

class Logger {
  category: string;
  logger: any;
  prefix: string;
  metas: MetasType;
  constructor(
    category: string,
    level: string = config.LOG_LEVEL,
    metas: MetasType = {},
  ) {
    this.category = category;
    this.logger = console;
    this.prefix = config.isDevelopment ? `[${category || "core"}] ` : "";
    // you can add a dedicated column in Datadog to display the category
    this.metas = config.isDevelopment ? metas : { category, ...metas };

    if (typeof winston !== "undefined") {
      const { combine, simple, colorize, errors, json } = format;

      if (errors) {
        // not defined in case of gfw-ui
        addColors({
          error: "red",
          warn: "yellow",
          info: "cyan",
          debug: "blue",
        });

        const options = [errors({ stack: true })];
        if (!config.isDevelopment) {
          options.push(json());
        } else {
          options.push(colorize());
          options.push(simple());
        }

        const myConsole = new transports.Console({
          format: combine(...options),
        });
        this.logger = createWinstonLogger({
          level: config.isTest ? "emerg" : level,
          exceptionHandlers: [myConsole],
          transports: [myConsole],
        });
      }
    }
  }

  mergeMetas(metas = {}) {
    return { ...this.metas, ...metas };
  }

  debug(msg: string, metas?: MetasType) {
    this.logger.debug(
      `${getTime()}${this.prefix}${msg}`,
      this.mergeMetas(metas),
    );
  }

  warning(msg: string, metas?: MetasType) {
    this.logger.warn(
      `${getTime()}${this.prefix}${msg}`,
      this.mergeMetas(metas),
    );
  }

  warn(msg: string, metas?: MetasType) {
    this.logger.warn(
      `${getTime()}${this.prefix}${msg}`,
      this.mergeMetas(metas),
    );
  }

  info(msg: string, metas?: MetasType) {
    this.logger.info(
      `${getTime()}${this.prefix}${msg}`,
      this.mergeMetas(metas),
    );
  }

  error(msg: any, metas?: MetasType) {
    const message = msg?.stack || msg;
    this.logger.error(
      `${getTime()}${this.prefix}${message}`,
      this.mergeMetas({ ...metas, message, error: msg }),
    );
  }

  success(msg: string, metas?: MetasType) {
    this.logger.info(
      `${getTime()}${this.prefix}${msg}`,
      this.mergeMetas(metas),
    );
  }
}

const createLogger = (category: string, level?: string) =>
  new Logger(category, level);

export { createLogger, Logger };
