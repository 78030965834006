import { keyBy, sortBy, uniq } from "lodash";

import assetManager from "./data/asset-manager";
import broker from "./data/broker";
import custodian from "./data/custodian";
import distributor from "./data/distributor";
import fundAdministrator from "./data/fund-administrator";
import institutionalInvestor from "./data/institutional-investor";
import investmentFund from "./data/investment-fund";
import managementCompany from "./data/management-company";
import nomineeInvestors from "./data/nominee-investors";
import serviceProvider from "./data/service-provider";
import transferAgent from "./data/transfer-agent";

type BasicPack = {
  forms: Array<string>;
  files: Array<string>;
};

export type IProfileType = {
  readonly name: string;
  readonly id: string;
  readonly forms: ReadonlyArray<string>;
  readonly files: ReadonlyArray<string>;
  readonly basicPack: BasicPack;
};

const PROFILE_TYPES: ReadonlyArray<IProfileType> = sortBy(
  [
    assetManager,
    broker,
    custodian,
    distributor,
    fundAdministrator,
    institutionalInvestor,
    investmentFund,
    managementCompany,
    nomineeInvestors,
    serviceProvider,
    transferAgent,
  ],
  "name",
);

const PROFILE_TYPES_OBJ = Object.freeze(keyBy(PROFILE_TYPES, "id"));
const PROFILE_TYPES_NAME_OBJ = Object.freeze(keyBy(PROFILE_TYPES, "name"));
/**
 * Get all profile types as an array
 *
 */
export function items() {
  return PROFILE_TYPES;
}

/**
 * Get a profile type by its id
 *
 */
export function byId(id: string) {
  return PROFILE_TYPES_OBJ[id];
}

/**
 * Get a profile type by its id
 *
 */
export function byName(name: string) {
  return PROFILE_TYPES_NAME_OBJ[name];
}

/**
 * Get a profile type sorted by name
 *
 */
export function sortedByName() {
  return PROFILE_TYPES;
}

/**
 * Get profiles types info based on Ids
 *
 */
export function getTypesFromIds(ids: [string]) {
  const getTypesFromIdsArray = ids.map((id: string) => PROFILE_TYPES_OBJ[id]);
  return sortBy(getTypesFromIdsArray, "name");
}

/**
 * Get types names
 *
 */
export function getNames() {
  return PROFILE_TYPES.map((profileType: IProfileType) => profileType?.name);
}

/**
 * Get types names based on Ids
 *
 */
export function getNamesFromIds(ids: [string]) {
  return ids.map((id: string) => PROFILE_TYPES_OBJ[id]?.name);
}

/**
 * Get types names based on Ids
 *
 */
export function getIdsFromNames(names: [string]) {
  return names.map((name: string) => PROFILE_TYPES_NAME_OBJ[name]?.id);
}

/**
 * Get all profile types ids
 *
 */
export const IDS = Object.freeze(Object.keys(PROFILE_TYPES_OBJ));

/**
 * Get all merge basic pack from ids
 *
 */
export function getMergedTypeFromIds(ids: [string]) {
  if (!ids) return {};
  const basicPack: BasicPack = { forms: [], files: [] };
  const files: Array<string> = [];
  const forms: Array<string> = [];
  ids.map((id: string) => {
    basicPack.forms.push(...(PROFILE_TYPES_OBJ[id]?.basicPack?.forms || {}));
    basicPack.files.push(...(PROFILE_TYPES_OBJ[id]?.basicPack?.files || {}));
    forms.push(...(PROFILE_TYPES_OBJ[id]?.forms || {}));
  });
  return {
    forms: uniq(forms),
    files: uniq(files),
    basicPack: { forms: uniq(basicPack.forms), files: uniq(basicPack.files) },
  };
}
