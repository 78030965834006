import {
  QuestionGroup,
  ChoiceQuestion,
  TextQuestion,
  YesNoQuestion,
} from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.AML_AND_CFT_PROCEDURES,
  questions: [
    new QuestionGroup("Procedures for AML & CFT", {
      variant: "noBorder",
      questions: [
        // Approval of program
        new ChoiceQuestion({
          key: "ApprovalOfProgram",
          label: "Approval of program",
          isMultipleChoice: true,
          choices: [
            "Board of Directors",
            "Audit committee",
            "Compliance Function",
            "Internal auditor",
            "Risk committee",
            ChoiceQuestion.Choice("Independent third party", {
              questions: [TextQuestion.Specify("ApprovalOfProgramThirdParty")],
            }),
            ChoiceQuestion.Choice("Other", {
              questions: [TextQuestion.Specify("ApprovalOfProgramOther", true)],
            }),
          ],
        }),
        new QuestionGroup("Frequency of reviews", {
          questions: [
            new ChoiceQuestion({
              key: "aml-frequency-of-reviews",
              label:
                "How often is the program reviewed by the chosen governing bodies?",
              choices: [
                "At least once a year",

                ChoiceQuestion.Choice("Other", {
                  questions: [
                    TextQuestion.Specify("aml-frequency-of-reviews-other"),
                  ],
                }),
              ],
            }),
            new TextQuestion({
              key: "date_of_review",
              label: "When was the last review of the program conducted?",
            }),
          ],
        }),
        // Content of program
        new ChoiceQuestion({
          key: "ContentOfProgram",
          label:
            "Please mark whether any of the following areas ARE covered by your program.",
          isMultipleChoice: true,
          threshold: 10,
          choices: [
            ChoiceQuestion.Choice(
              "Appointment of officer in charge of coordinating and overseeing implementation of the policies",
              {
                questions: [
                  TextQuestion.Explain("COPAppointmentComplianceOfficer"),
                ],
              },
            ),
            ChoiceQuestion.Choice(
              "Procedures for KYC and risk assessment of clients including processes for reviewing and updating customer information according to their risk profile",
              {
                questions: [TextQuestion.Explain("COPKYCProcudures")],
              },
            ),
            ChoiceQuestion.Choice(
              "Processes to prevent, detect and report suspicious transactions to the local financial intelligence unit",
              {
                questions: [
                  TextQuestion.Explain("COPProcessesSuspiciousTransactions"),
                ],
              },
            ),
            ChoiceQuestion.Choice(
              "Enhanced measures for KYC in relation to high risk clients",
              {
                questions: [TextQuestion.Explain("COPEnhancedKYCMeasures")],
              },
            ),
            ChoiceQuestion.Choice(
              "Monitoring of relationships with Politically Exposed Persons (PEPs) incl. familiy and associates",
              {
                questions: [TextQuestion.Explain("COPPEPMonitoring")],
              },
            ),
            ChoiceQuestion.Choice(
              "Identification of Ultimate Beneficial Owners",
              {
                questions: [TextQuestion.Explain("COPUBOIdentification")],
              },
            ),
            ChoiceQuestion.Choice(
              "Screening of clients against sanctions lists",
              {
                questions: [TextQuestion.Explain("COPScreeningSanctionLists")],
              },
            ),
            ChoiceQuestion.Choice(
              "Screening of Ultimate Beneficial Owners against sanctions lists",
              {
                questions: [TextQuestion.Explain("COPUBOScreening")],
              },
            ),
            ChoiceQuestion.Choice(
              "Mandatory AML training for relevant persons",
              {
                questions: [TextQuestion.Explain("COPAMLTraining")],
              },
            ),
            ChoiceQuestion.Choice("Record retention", {
              questions: [TextQuestion.Explain("COPRecordRetention")],
            }),
          ],
        }),
        new YesNoQuestion({
          key: "ShellBankProhibition",
          label:
            "Does your entity have a policy and procedures to detect and prohibit any relationship with shell banks?",
          // help/tooltip: A shell bank is defined as a bank incoporated in a jurisdiction in which it has not physical presence and which is unaffiliated with a regulated financial group
          No: [
            new TextQuestion({
              key: "ShellBankProhibitionSpecify",
              label: "Specify how you are managing shell banks",
              isMultiLine: true,
            }),
          ],
        }),
        new YesNoQuestion({
          key: "RecordRetention",
          label:
            "Does your entity have record retention procedures that comply with applicable laws and rules?",
          Yes: [
            new ChoiceQuestion({
              key: "RecordRetentionYes",
              // Period of record retention (years)
              label:
                "What is the period of record retention for information related to AML & CFT?",
              choices: [
                ChoiceQuestion.Choice("1", {
                  label: "1 year",
                }),
                ChoiceQuestion.Choice("3", { label: "3 years" }),
                ChoiceQuestion.Choice("5", { label: "5 years" }),
                ChoiceQuestion.Choice("10", { label: "10 years" }),
                ChoiceQuestion.Choice("Other", {
                  questions: [
                    new TextQuestion({
                      key: "RecordRetentionYesOther",
                      label: "Specify relevant period",
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        new YesNoQuestion({
          key: "CoverageOfProgram",
          label:
            "Do your policies apply on activities performed via representative offices, branches, subsidiaries, both in the home country and in locations outside of that jurisdiction?",
        }),
        new YesNoQuestion({
          key: "salesOfConductConfirm",
          label: "Have you implemented a code of conduct?",
        }),
      ],
    }),
    new QuestionGroup("Risk Based Approach", {
      questions: [
        new YesNoQuestion({
          key: "RiskAnalysis",
          label:
            "Are your policies & procedures related to AML & CFT based on a risk analysis of criteria such as types of clients, products, services, distribution channel and geographical presence of clients?",
          No: [TextQuestion.Explain("RiskAnalysisNo")],
        }),
        new ChoiceQuestion({
          key: "FrequencyOfAnalysis",
          label: "How often is the risk analysis according to above performed?",
          choices: [
            "At least once a year",
            ChoiceQuestion.Choice("Other", {
              questions: [TextQuestion.Specify("FrequencyOfAnalysisOther")],
            }),
          ],
        }),
        new ChoiceQuestion({
          key: "CountryRiskAnalysis",
          label:
            "What external sources are used to assess whether relevant jurisdiction have satisfactory measures to combat money-laundering & financing of terrorism?",
          isMultipleChoice: true,
          choices: [
            "FATF list of high risk and non-cooperative jurisdictions",
            ChoiceQuestion.Choice("Other", {
              questions: [
                new TextQuestion({
                  key: "CountryRiskAnalysisOther",
                  label: "Please define other sources",
                  isMultiLine: true,
                }),
              ],
            }),
          ],
        }),
        new TextQuestion({
          key: "CountryRiskProcedures",
          label:
            "Describe your procedures to identify and measure country risks on a regular basis",
          isMultiLine: true,
        }),
        new YesNoQuestion({
          key: "CountryRiskSystems",
          label:
            "Do you use any systems to monitor country risks on a regular basis?",
          Yes: [
            new TextQuestion({
              key: "CountryRiskSystemsYes",
              label: "Specify the name of system and system supplier",
            }),
          ],
        }),
        new YesNoQuestion({
          key: "RiskBasedMeasures",
          label:
            "Do you determine appropriate measures in relation to each client based on the chosen level of risk?",
          No: [
            new TextQuestion({
              key: "RiskBasedMeasuresSpecify",
              label: "Please explain how measures are chosen",
              isMultiLine: true,
            }),
          ],
        }),
      ],
    }),
    new QuestionGroup("Screening", {
      questions: [
        new YesNoQuestion({
          key: "SanctionListScreeningStatus",
          label:
            "Does your company screen clients and transactions against sanctions list?",
          No: [TextQuestion.Explain("SanctionListScreeningStatusNo")],
        }),
        new ChoiceQuestion({
          key: "SanctionListScreening",
          label: "What is your current process for sanctions list screening?",
          choices: [
            "Automated / On-going",
            "Manual",
            "Automated / On-going and Manual",
            ChoiceQuestion.Choice("Other", {
              questions: [
                new TextQuestion({
                  key: "SanctionListScreeningOther",
                  label: "Describe your process for screening",
                  isMultiLine: true,
                }),
              ],
            }),
          ],
        }),
        new ChoiceQuestion({
          key: "sanction-list-screening-lists",
          label: "Which lists, if any, do you use for sanctions screening?",
          isMultipleChoice: true,
          choices: [
            "EU: European Union",
            "US: United States",
            "UN: United Nations",
            "UK: United Kingdom",
            ChoiceQuestion.Choice("Other", {
              questions: [
                TextQuestion.Specify("sanction-list-screening-lists-other"),
              ],
            }),
          ],
        }),
        new ChoiceQuestion({
          key: "PEPListScreening",
          label:
            "What is you current process for identification and screening of PEP and their associates?",
          // Politically Exposed Persons screening
          choices: [
            "Automated / On-going",
            "Manual",
            "Automated / On-going and Manual",
            ChoiceQuestion.Choice("Other", {
              questions: [
                new TextQuestion({
                  key: "PEPListScreeningOther",
                  label: "Describe your process",
                  isMultiLine: true,
                }),
              ],
            }),
          ],
        }),
        // TODO: shouldn't this also be a choice list? Hard to do with it being free text though
        new TextQuestion({
          key: "PEPListScreeningLists",
          label:
            "Which lists or systems, if any, do you use for screening of PEPs?",
          // Specify name and source of lists
          isMultiLine: true,
        }),
        new YesNoQuestion({
          key: "pep_presence",
          label:
            "In the presence of PEPs, do you require authorization to enter into a business relationship by a member of the executive body or any person authorized for that purpose by the executive body?",
          No: [TextQuestion.Explain("pep_presence_explain")],
        }),
      ],
    }),
  ],
};

export default category;
