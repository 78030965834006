import continents from "./continents.json";
import { matchSorter } from "match-sorter";

type SearchableKeys = "name";

export function findContinentsBy(
  term: string,
  keys: SearchableKeys[] = ["name"],
) {
  return matchSorter(continents, term, {
    keys: keys,
  });
}

export const allContinentNames = continents
  .map((continent) => continent.name)
  .sort();
