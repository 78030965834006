import {
  YesNoQuestion,
  TextQuestion,
  ChoiceQuestion,
  QuestionGroup,
} from "../questions";
import { countries } from "../datasources";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.REGULATORY_STATUS,
  questions: [
    new QuestionGroup("Licenses & Supervision", {
      variant: "noBorder",
      questions: [
        new YesNoQuestion({
          key: "under-supervision-fsa",
          label:
            "Are you supervised by a financial supervisory authority (i.e. AMF, CSSF,FCA, MAS, SEC etc.)",
          Yes: [
            new TextQuestion({
              key: "fsaNameAndCountry",
              label: "Name and Country of FSA",
            }),
            new TextQuestion({
              key: "fsaWebsite",
              label: "Website of FSA",
            }),
            new TextQuestion({
              key: "fsaRegNo",
              label: "Registration number with the FSA",
            }),
            new TextQuestion({
              key: "typeOfLicenses",
              label: "Type of licenses",
            }),
            new YesNoQuestion({
              key: "supervision-other-jurisdictions",
              label: "Are you supervised in other jurisdictions?",
              Yes: [
                new ChoiceQuestion({
                  key: "supervision-other-jurisdictions-countries",
                  label: "Specify jurisdictions",
                  isMultipleChoice: true,
                  choices: countries,
                }),
              ],
            }),
          ],
          No: [TextQuestion.Explain("typeOfLicensesExplain")],
        }),
        new YesNoQuestion({
          key: "upcoming_changes",
          label:
            "Are any significant changes planned in the short term in relation to your licenses and authorizations?",
          Yes: [TextQuestion.Explain("upcoming_changes_explain")],
        }),
        new YesNoQuestion({
          key: "local-markets-regulation-impact-business",
          label:
            "Could your business be affected by any local market regulations that are currently being put in place?",
          Yes: [
            TextQuestion.Explain(
              "local-markets-regulation-impact-business-explain",
            ),
          ],
        }),
      ],
    }),
    new QuestionGroup("History of supervision", {
      questions: [
        new YesNoQuestion({
          key: "visit_by_authority",
          label:
            "Have you been visited by your supervisory authority in the past 3 years?",
          Yes: [
            new TextQuestion({
              key: "visit_by_authority_explain",
              label:
                "Please describe the scope of the control (activities) and the material findings with their relevant remedial actions (including issues related to AML/CFT concerns).",
              isMultiLine: true,
            }),
          ],
        }),

        // Breaches, fines & investigations
        new YesNoQuestion({
          key: "breaches-fines-investigations",
          label:
            "Have you, your affiliates, or any of your directors and/or managers, been subject to any breaches, fines or investigation by a supervisory authority in the past 5 years?",
          Yes: [
            new TextQuestion({
              key: "bfi-reason",
              label:
                "Describe the reasons for such breach, fine or investigation.",
              isMultiLine: true,
            }),
            new TextQuestion({
              key: "bfi-actions",
              label:
                "What actions have been taken in order to amend defaulting areas?",
              isMultiLine: true,
            }),
          ],
        }),

        // Suspension of licenses
        new YesNoQuestion({
          key: "suspension-of-licenses",
          label:
            "Have you, your affiliates, or any of your directors and/or managers, been subject to a suspension or revocation of licenses in the past 5 years?",
          Yes: [
            new TextQuestion({
              key: "sol-reason",
              label: "Describe the reasons for such suspension.",
              isMultiLine: true,
            }),
            new TextQuestion({
              key: "sol-actions",
              label:
                "What actions have been taken in order to amend defaulting areas?",
              isMultiLine: true,
            }),
          ],
        }),

        // Litigations
        new YesNoQuestion({
          key: "litigations",
          label:
            "Have you, your affiliates, or any of your directors and/or managers, been subject to a litigation in the past 5 years?",
          Yes: [
            new TextQuestion({
              key: "litigations-reason",
              label: "Describe the reasons for such litigations.",
              isMultiLine: true,
            }),
            new TextQuestion({
              key: "litigations-actions",
              label:
                "What actions have been taken in order to amend defaulting areas?",
              isMultiLine: true,
            }),
          ],
        }),
      ],
    }),
  ],
};

export default category;
