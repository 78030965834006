import {
  QuestionnaireQuestion,
  IQuestionnaireQuestion,
} from "./QuestionnaireQuestion";

interface ITextQuestion extends IQuestionnaireQuestion {
  isMultiLine?: boolean;
}

export class TextQuestion extends QuestionnaireQuestion {
  type = "text";
  isMultiLine: boolean;

  constructor({ isMultiLine, ...options }: ITextQuestion) {
    super(options);

    this.isMultiLine = isMultiLine ?? false;
  }

  getQuestions(): QuestionnaireQuestion[] {
    return [this];
  }

  clone(): QuestionnaireQuestion {
    return new TextQuestion(this);
  }

  answerToString(): string {
    return this.answer || "Not Set";
  }

  /**
   * Quick way to create a "please explain" TextQuestion
   */
  public static Explain(key: string, isMultiLine = true): TextQuestion {
    return new TextQuestion({
      key,
      label: "Please explain",
      isMultiLine,
    });
  }

  /**
   * Quick way to create a "please specify" TextQuestion
   */
  public static Specify(key: string, isMultiLine = false): TextQuestion {
    return new TextQuestion({
      key,
      label: "Please specify",
      isMultiLine,
    });
  }
}
