import md5 from "md5";

import { getEnumValues } from "../constants";

import { getConfig } from "@app/config";

const { MAIL_HASH_SUBCRIPTION_KEY } = getConfig();

/* eslint-disable no-unused-vars */

enum SUBSCRIPTIONS_TYPES {
  ACTIVITY_REPORT = 1 << 0,
  ACCESS_RIGHT = 1 << 1,
  BROADCAST = 1 << 2,
  CORRESPONDENCE = 1 << 3,
  DOCUMENT = 1 << 4,
  COUNTERPARTY = 1 << 5,
  PROFILE = 1 << 6,
  DUE_DILIGENCE = 1 << 7,
  NEWS = 1 << 8,
}

const isEmailTypeSubscribed = (
  bitmask: number | undefined,
  subscriptionType: SUBSCRIPTIONS_TYPES,
) => {
  if (bitmask === undefined) return true;
  return !(bitmask & subscriptionType);
};

const subscribeEmailType = (
  bitmask: number,
  subscriptionType: SUBSCRIPTIONS_TYPES,
) => {
  if (!bitmask) return ALL_SUBSCRIPTIONS_ON;
  const newBitmask = bitmask ^ subscriptionType;
  return newBitmask;
};

const unsubscribeEmailType = (
  bitmask: number,
  subscriptionType: SUBSCRIPTIONS_TYPES,
) => {
  return (bitmask ?? ALL_SUBSCRIPTIONS_ON) | subscriptionType;
};

const ALL_SUBSCRIPTIONS_ON = 0;
const ALL_SUBSCRIPTIONS_OFF = getEnumValues(SUBSCRIPTIONS_TYPES).reduce(
  (acc, value) => (acc |= value as number),
  ALL_SUBSCRIPTIONS_ON,
);

const getSubscriptionHashKey = ({
  userOId,
  profileOId,
  subscriptionType,
}: {
  userOId: string;
  profileOId: string;
  subscriptionType: SUBSCRIPTIONS_TYPES;
}) => {
  if (!MAIL_HASH_SUBCRIPTION_KEY) {
    throw new Error("MAIL_HASH_SUBCRIPTION_KEY undefined");
  }
  return md5(
    `${MAIL_HASH_SUBCRIPTION_KEY}${userOId.toString()}${profileOId.toString()}${subscriptionType}`,
  );
};

export {
  ALL_SUBSCRIPTIONS_OFF,
  ALL_SUBSCRIPTIONS_ON,
  getSubscriptionHashKey,
  isEmailTypeSubscribed,
  subscribeEmailType,
  SUBSCRIPTIONS_TYPES,
  unsubscribeEmailType,
};
