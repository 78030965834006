import { compact, isEmpty, mapValues, omitBy } from "lodash";

const normalizeString = (str: string) => {
  return str?.trim() || undefined;
};

const normalizeArray = (arr: Array<unknown>) => {
  const compactArr = compact(arr);
  return compactArr.length > 0 ? compactArr : undefined;
};

const isEmptyValue = (val: unknown) => {
  if (
    typeof val === "number" ||
    typeof val === "boolean" ||
    val instanceof Date
  )
    return false;
  return isEmpty(val);
};

const normalizeObject = (obj: object) => {
  const cleaned = omitBy(obj, isEmptyValue);
  return isEmptyValue(cleaned) ? undefined : cleaned;
};

// Be carefull with the behavior cf comment below
export const stripEmpty = (input: unknown): unknown | undefined => {
  if (input instanceof Date) {
    return input;
  }
  if (typeof input === "string") {
    return normalizeString(input);
  }
  if (Array.isArray(input)) {
    return normalizeArray(input.map(stripEmpty));
  }
  // Be carefull while using this since almost everything is an object in js.
  if (input && typeof input === "object") {
    return normalizeObject(mapValues(input, stripEmpty));
  }
  // Fallack
  return input;
};
