import { Route, Routes } from "react-router-dom";
import React from "react";

import LoginPage from "@app/resources/LoginPage";

function PublicRouter() {
  return (
    <Routes>
      <Route element={<LoginPage />} path="*" />
    </Routes>
  );
}

export default PublicRouter;
