const ACCESS_RIGHTS_REQUEST_STATE = Object.freeze({
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
});

const ACCESS_RIGHTS_REQUEST_DIRECTION = Object.freeze({
  INCOMING: "INCOMING",
  OUTGOING: "OUTGOING",
});

const ACCESS_RIGHTS_REQUEST_DECISION = Object.freeze({
  APPROVED: "APPROVED",
  DENIED: "DENIED",
  MIXED: "MIXED",
});

const ACCESS_RIGHTS_ACTION = Object.freeze({
  ADD: "add",
  REMOVE: "remove",
});

export {
  ACCESS_RIGHTS_REQUEST_STATE,
  ACCESS_RIGHTS_REQUEST_DIRECTION,
  ACCESS_RIGHTS_REQUEST_DECISION,
  ACCESS_RIGHTS_ACTION,
};
