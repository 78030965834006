import { YesNoQuestion, TextQuestion } from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.ESG,
  questions: [
    new YesNoQuestion({
      key: "policyEsgFactors",
      label:
        "Do you have a policy that describes your approach to identifying and managing ESG factors?",
      No: [
        new TextQuestion({
          isMultiLine: true,
          key: "policyEsgFactorsIndicate",
          label:
            "Please indicate if you consider adopting an ESG policy, and how",
        }),
      ],
    }),
    new TextQuestion({
      isMultiLine: true,
      key: "describeEsgResponsibilities",
      label:
        "Please describe how (i) oversight responsibilities, and (ii) implementation responsibilities for ESG integration are structured within your organization",
    }),
    new TextQuestion({
      isMultiLine: true,
      key: "ESGListPersonInvolved",
      label:
        "Please list the persons involved and describe their role, position within the organization and how they are qualified for this role. Please also describe any external resources you may use",
    }),
    new YesNoQuestion({
      key: "ESGProvideTraining",
      label:
        "Do you provide training, assistance and/or external resources to your staff to help them understand and identify the relevance and importance of ESG factors?",
      Yes: [
        new TextQuestion({
          isMultiLine: true,
          key: "ESGProvideTrainingDescribe",
          label: "Please describe what level of training is provided",
        }),
      ],
    }),
    new YesNoQuestion({
      key: "MeasureEsgPerformance",
      label:
        "Do you measure whether your approach to ESG factors has affected the financial and/or ESG performance of your company",
      Yes: [
        new TextQuestion({
          isMultiLine: true,
          key: "MeasureEsgPerformanceDescribe",
          label: "Please describe how you are able to determine these outcomes",
        }),
      ],
    }),
    new TextQuestion({
      isMultiLine: true,
      key: "EsgChannelCommunicationChannels",
      label:
        "Which channels do you use to communicate ESG-related information?",
    }),
    new TextQuestion({
      isMultiLine: true,
      key: "EsgManagementIncludedInCompanyAgenda",
      label: "Is the management of ESG factors included on the company agenda?",
    }),
  ],
};

export default category;
