import {
  QuestionnaireQuestion,
  IQuestionnaireQuestion,
} from "./QuestionnaireQuestion";
import { IQuestionnaireSnapshot, applySnapshot } from "../Questionnaire";

interface IBooleanQuestion extends IQuestionnaireQuestion {
  /**
   * Questions that should show when the answer for this question is considered TRUE
   */
  questions?: QuestionnaireQuestion[];
}

/**
 * A Question that acts like a TRUE|FALSE (Boolean)
 */
export class BooleanQuestion extends QuestionnaireQuestion {
  type = "boolean";
  questions: QuestionnaireQuestion[];
  answer?: [string];

  constructor({ questions, ...options }: IBooleanQuestion) {
    super(options);

    this.questions = questions || [];
  }

  clone(): QuestionnaireQuestion {
    return new BooleanQuestion({
      ...this,
      questions: this.questions.map((question) => question.clone()),
    });
  }

  isTruthy() {
    return this.answer?.[0] === this.label;
  }

  answerToString(): string {
    if (this.answer) return "Yes";
    if (this.answer == null) return "Not Set";
    return "No";
  }

  getQuestions(options: { depth: number }): QuestionnaireQuestion[] {
    if (options.depth > 1) {
      return this.questions.reduce<QuestionnaireQuestion[]>(
        (questions, question) => {
          return [...questions, ...question.getQuestions(options)];
        },
        [this],
      );
    } else {
      return [this];
    }
  }

  onSnapshotApplied(snapshot: IQuestionnaireSnapshot): void {
    if (this.isTruthy()) {
      // continue applying the snapshot to my questions too
      applySnapshot(this.questions, snapshot);
    }
  }

  preProcessSnapshotAnswer(answer?: any) {
    if (answer && Array.isArray(answer)) {
      this.answer = [answer[0]];
    }
  }
}
