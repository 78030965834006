import { TextQuestion, ChoiceQuestion, QuestionGroup } from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.PROCESSES_AND_REPORTING,
  questions: [
    new TextQuestion({
      label:
        "Please explain when you will consider that an account is closed/inactivated/dormant?",
      key: "whenConsiderAccountClosed",
    }),
    new QuestionGroup("Process", {
      helpText:
        "How soon after the receipt of an instruction do you confirm its acceptance, for: (instruction are redemptions, switches, conversions, transfers, etc)",
      questions: [
        new ChoiceQuestion({
          label: "A daily priced fund",
          key: "processHowSoonConfirmAcceptanceDaily",
          isMultipleChoice: false,
          choices: ["24 H", "48 H", "72 H", "1 week", "more"],
        }),
        new ChoiceQuestion({
          label: "A weekly fund priced fund",
          key: "processHowSoonConfirmAcceptanceWeekly",
          isMultipleChoice: false,
          choices: ["24 H", "48 H", "72 H", "1 week", "more"],
        }),
        new ChoiceQuestion({
          label: "A monthly fund priced fund",
          key: "processHowSoonConfirmAcceptanceMonthly",
          isMultipleChoice: false,
          choices: ["24 H", "48 H", "72 H", "1 week", "more"],
        }),
        new ChoiceQuestion({
          label: "Other funds",
          key: "processHowSoonConfirmAcceptanceOther",
          isMultipleChoice: false,
          choices: ["24 H", "48 H", "72 H", "1 week", "more"],
        }),
        new ChoiceQuestion({
          label: "How is the acceptance of the instruction sent?",
          key: "processHowAcceptanceOfInstructionSent",
          isMultipleChoice: true,
          choices: [
            "Swift ISO 15022",
            "Swift ISO 20022",
            "FAX",
            "Letter",
            "Email",
            "Web portal",
            {
              value: "Other",
              questions: [
                TextQuestion.Explain(
                  "processHowAcceptanceOfInstructionSentExplain",
                ),
              ],
            },
          ],
        }),
      ],
    }),
    new QuestionGroup("Reporting", {
      helpText:
        "How soon after an order is processed do you send an initial contract note ?",
      questions: [
        new ChoiceQuestion({
          label: "A daily priced fund",
          key: "reportingHowSoonConfirmAcceptanceDaily",
          isMultipleChoice: false,
          choices: ["24 H", "48 H", "72 H", "1 week", "more"],
        }),
        new ChoiceQuestion({
          label: "A weekly fund priced fund",
          key: "reportingHowSoonConfirmAcceptanceWeekly",
          isMultipleChoice: false,
          choices: ["24 H", "48 H", "72 H", "1 week", "more"],
        }),
        new ChoiceQuestion({
          label: "A monthly fund priced fund",
          key: "reportingHowSoonConfirmAcceptanceMonthly",
          isMultipleChoice: false,
          choices: ["24 H", "48 H", "72 H", "1 week", "more"],
        }),
        new ChoiceQuestion({
          label: "Other funds",
          key: "reportingHowSoonConfirmAcceptanceOther",
          isMultipleChoice: false,
          choices: ["24 H", "48 H", "72 H", "1 week", "more"],
        }),
        new ChoiceQuestion({
          label: "How is the contract note sent?",
          key: "reportingHowAcceptanceOfInstructionSent",
          isMultipleChoice: true,
          choices: [
            "Swift ISO 15022",
            "Swift ISO 20022",
            "FAX",
            "Letter",
            "Email",
            "Web portal",
            {
              value: "Other",
              questions: [
                TextQuestion.Explain(
                  "reportingHowAcceptanceOfInstructionSentExplain",
                ),
              ],
            },
          ],
        }),
      ],
    }),
  ],
};

export default category;
