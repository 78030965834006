import dayjs from "dayjs";

/**
 * Formats the date as yyyy-MM-dd
 */
export function iso8601Date(
  date?: Date | number | string | dayjs.Dayjs,
  defaultValue?: string,
) {
  if (!date && typeof defaultValue !== "undefined") return defaultValue;
  try {
    return dayjs(date).format("YYYY-MM-DD");
  } catch (error) {
    if (error instanceof Error) return error.message;
    return "Invalid Date";
  }
}

/**
 * Formats the date as yyyy-MM-dd HHhMM
 */
export function iso8601DateTime(
  date?: Date | number | string | dayjs.Dayjs,
  defaultValue?: string,
) {
  if (!date && typeof defaultValue !== "undefined") return defaultValue;
  try {
    return dayjs(date).format("YYYY-MM-DD hh:mm");
  } catch (error) {
    if (error instanceof Error) return error.message;
    return "Invalid Date";
  }
}

/**
 * Calculates the difference between two date and returns a formatted string for difference
 *
 * @export
 * @param {Date} date
 * @param {*} [{ precise = false, compareDate = null }={}], precise determines whether today is shown as Today or more precise, pass in compareDate to use other date that Date.Now to compare with
 * @returns {String} returns a formatted string for difference
 */
export function formatTimeDifference(
  date: Date,
  { precise = false, compareDate = null } = {},
) {
  const comparedDate = compareDate || new Date();

  const after = dayjs(date).isAfter(dayjs(comparedDate));

  //TODO there is no reason to call other functions after one returns nonzero value
  const diffInMonths = dayjs(comparedDate).diff(date, "month");
  const diffInDays = dayjs(comparedDate).diff(date, "day");
  const diffInHours = dayjs(comparedDate).diff(date, "hour");
  const diffInMinutes = dayjs(comparedDate).diff(date, "minute");

  let plr;
  const diff = diffInMonths || diffInDays || diffInHours || diffInMinutes;

  //Add localization?
  if (!precise && !diffInMonths && !diffInDays) {
    return "Today";
  }

  if (diffInMonths) {
    plr = diffInMonths > 1 ? "months" : "month";
  } else if (diffInDays) {
    plr = diffInDays > 1 ? "days" : "day";
  } else if (diffInHours) {
    plr = diffInHours > 1 ? "hours" : "hour";
  } else {
    plr = diffInMinutes > 1 ? "minutes" : "minute";
  }

  return after ? `in ${diff} ${plr}` : `${diff} ${plr} ago`;
}

export default formatTimeDifference;
