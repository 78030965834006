export default [
  { key: "overview", path: "/overview", label: "Overview", icon: "dashboard" },
  { key: "profiles", path: "/profiles", label: "Profiles", icon: "profiles" },
  { key: "requests", path: "/requests", label: "Requests", icon: "requests" },
  { key: "users", path: "/users", label: "Users", icon: "users" },
  { key: "banner", path: "/banner", label: "Banner", icon: "banner" },
  {
    key: "custom",
    path: "/questionnaire",
    label: "Custom Questionnaire",
    icon: "document",
  },
  { key: "news", path: "/news", label: "News", icon: "news" },
  { key: "stats", path: "/stats", label: "Stats", icon: "queryStats" },
  { key: "logout", path: "/logout", label: "Log out", icon: "logout" },
];
