const SORT_ORDER = Object.freeze({
  ASC: "asc",
  ASC_INT: 1,
  DESC: "desc",
  DESC_INT: -1,
  ASCEND: "ascend",
  DESCEND: "descend",
});

const BASE_SORT_COLUMN = Object.freeze({
  sorter: true,
  showSorterTooltip: false,
  sortDirections: [SORT_ORDER.ASCEND, SORT_ORDER.DESCEND, SORT_ORDER.ASCEND],
});

const BASE_SORT_COLUMN_DESCEND = Object.freeze({
  sorter: true,
  showSorterTooltip: false,
  sortDirections: [SORT_ORDER.DESCEND, SORT_ORDER.ASCEND, SORT_ORDER.DESCEND],
});

export { SORT_ORDER, BASE_SORT_COLUMN, BASE_SORT_COLUMN_DESCEND };
