import {
  TextQuestion,
  ChoiceQuestion,
  YesNoQuestion,
  QuestionGroup,
} from "../questions";

import { IQuestionnaireQuestion } from "../questions/QuestionnaireQuestion";
import { IYesNoQuestion } from "../questions/YesNoQuestion";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

interface IYesNoNaQuestion extends IYesNoQuestion {
  Na?: IQuestionnaireQuestion[];
}

class YesNoNaQuestion extends YesNoQuestion {
  Na: IQuestionnaireQuestion[];
  constructor({ Na, ...configuration }: IYesNoNaQuestion) {
    //the type will still be Yes|No
    super({ ...configuration });
    this.Na = Na || [TextQuestion.Explain(this.key + "-na-explain")];
  }
}

const external_outsourcing = [
  new TextQuestion({
    key: "outsourcing-third-parties-list",
    label:
      "Please list the name of counterparties and the area of responsibilities for each party",
  }),
  new ChoiceQuestion({
    label:
      "Please confirm compliance of the following by the outsourcing partner",
    key: "outsourcing-third-parties-compliance",
    isMultipleChoice: true,
    choices: [
      "Client information is stored according to applicable laws by the outsourcing partner",
      "You have immediate access to client information upon request",
      "Results of KYC is reported to you before entering a client relationship and on a regular basis",
      {
        value: "Other",
        label: "Other",
        questions: [
          TextQuestion.Explain("outsourcing-third-parties-compliance-other"),
        ],
      },
    ],
  }),
];

const category = {
  ...QUESTIONNAIRE_CATEGORIES.KYC,
  questions: [
    new QuestionGroup("On-boarding of Counterparties incl. Sub-Distributors", {
      variant: "noBorder",
      questions: [
        new YesNoNaQuestion({
          label:
            "Do you have written instructions for the process of on-boarding counterparties who are involved in any of your processes related to marketing and/distribution of funds?",
          key: "initial-due-diligence",
          No: [
            new TextQuestion({
              label: "Describe how new counterparties are evaluated",
              key: "initial-due-diligence-no",
            }),
          ],
        }),
        new ChoiceQuestion({
          label:
            "Which type of information do you collect in relation to establishment of a relationship with a new Counterparty?",
          key: "relation-establishment-information",
          isMultipleChoice: true,
          choices: [
            "Proof of legal status of the entity",
            "Proof of signatories",
            "Proof of board members",
            "Business activites",
            "Ultimate Beneficial Owners",
            "Ownership structure",
            "Executive management",
            "Latest Annual Financial Statement - audited",
            "Expected turnover",
            {
              label: "Other",
              value: "Other",
              questions: [
                TextQuestion.Explain(
                  "relation-establishment-information-other",
                ),
              ],
            },
          ],
        }),
        new ChoiceQuestion({
          label: "Approval and risk-assessment of Counterparties",
          //subtitle:"Involved in approval and risk assessment",
          key: "approval-risk-assessment",
          isMultipleChoice: true,
          choices: [
            "Board of Directors",
            "CEO",
            "Risk Committee",
            "Money Laundering Reporting Officer",
            "Compliance Function",
            "Risk Manager",
            "Head of Legal",
            "Head of Sales",
            "CFO",
            {
              label: "Other",
              value: "Other",
              questions: [
                TextQuestion.Explain("approval-risk-assessment-other"),
              ],
            },
          ],
        }),
        new TextQuestion({
          label:
            "Describe your process for approval of Counterparties incl. sub-distributors",
          key: "approval-risk-assessment-process",
          isMultiLine: true,
        }),
        new ChoiceQuestion({
          label:
            "Is the due diligence of Counterparties performed internally or externally?",
          key: "outsourcing-third-parties",
          isMultipleChoice: false,
          choices: [
            "Internally (own staff)",
            ChoiceQuestion.Choice("Externally (Counterparty)", {
              questions: external_outsourcing,
            }),
            ChoiceQuestion.Choice("Both internally and externally", {
              questions: external_outsourcing,
            }),
            "Not applicable (no end investors as clients)",
          ],
        }),
      ],
    }),
  ],
};

export default category;
