import type { Readable } from "stream";

interface IConvertStreamToBase64DataUriOptions {
  mimeType?: string;
}

export const convertStreamToBase64DataUri = (
  stream: Readable,
  { mimeType = "image/png" }: IConvertStreamToBase64DataUriOptions = {},
) => {
  return new Promise((resolve, reject) => {
    const chunks: Array<any> = [];
    stream.on("data", (chunk) => chunks.push(chunk));
    stream.on("error", (e: unknown) => reject(e));
    stream.on("end", () => {
      const img = `data:${mimeType};base64,${Buffer.concat(chunks).toString(
        "base64",
      )}`;
      resolve(img);
    });
  });
};
