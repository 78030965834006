import {
  TextQuestion,
  ChoiceQuestion,
  YesNoQuestion,
  QuestionGroup,
} from "../questions";
import { IChoice } from "../questions/ChoiceQuestion";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

function HowOftenChoices(key: string): IChoice[] {
  return [
    { value: "At least annually and upon need" },
    {
      value: "Other",
      questions: [TextQuestion.Explain(key)],
    },
  ];
}

const category = {
  ...QUESTIONNAIRE_CATEGORIES.IT_SECURITY_BUSINESS_CONTINUITY,
  questions: [
    new QuestionGroup("Business Continuity Plan", {
      variant: "noBorder",
      questions: [
        new YesNoQuestion({
          key: "bcpPlan",
          label:
            "Do you maintain a Business Continuity Plan (BCP) according to applicable laws and rules related to your type of business?",
          No: [TextQuestion.Explain("bcpPlanOther")],
          Yes: [
            new ChoiceQuestion({
              key: "bcpPlanReview",
              label: "How often is the BCP reviewed?",
              isMultipleChoice: false,
              choices: HowOftenChoices("bcpPlanReviewOther"),
            }),
            new ChoiceQuestion({
              isMultipleChoice: false,
              key: "bcpPlanTested",
              label: "How often is the BCP tested?",
              choices: HowOftenChoices("bcpPlanTestedOther"),
            }),
            new TextQuestion({
              key: "bcPlanLastTest",
              label: "Please provide the date of the last test",
              isMultiLine: false,
            }),
            new TextQuestion({
              label:
                "Please explain the outcome if findings were identified during the last test",
              key: "bcPlanOutcome",
              isMultiLine: true,
            }),
            new ChoiceQuestion({
              label:
                "How much time do you need to be operational when BCP is activated?",
              key: "timeNeededForBCPActivation",
              isMultipleChoice: false,
              choices: [
                "Less than 4 hours",
                "Between 4 hours and 8 hours",
                "More than 8 hours",
              ],
            }),
            new YesNoQuestion({
              label: "Do you have a back-up business continuity site?",
              key: "bcPlanBackUp",
              Yes: [
                new ChoiceQuestion({
                  key: "bcPlanBackUpOperational",
                  label: "How long does it take to become operational?",
                  isMultipleChoice: false,
                  choices: [
                    { value: "< 2 Hours" },
                    { value: "< 6 Hours" },
                    { value: "< 1 Day" },
                    { value: "> 1 Day" },
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    }),
    new QuestionGroup("IT Disaster Recovery Plans", {
      questions: [
        new YesNoQuestion({
          key: "bcPlanDRP",
          label:
            "Please confirm that you have a IT Disaster Recovery Plan (DRP).",
          Yes: [
            new ChoiceQuestion({
              key: "DRPReviewHowOften",
              label: "How often is your DRP policy reviewed?",
              isMultipleChoice: false,
              choices: HowOftenChoices("DPRReviewHowOftenExplain"),
            }),
            new ChoiceQuestion({
              key: "DPRTestHowOften",
              label: "How often is the DRP tested?",
              isMultipleChoice: false,
              choices: HowOftenChoices("DPRTestHowOftenExplain"),
            }),
            new YesNoQuestion({
              label: "Is your DRP audited by your local regulator?",
              key: "DRPTestAudited",
            }),
          ],
        }),
      ],
    }),
    new QuestionGroup("IT & Data General Security", {
      questions: [
        new YesNoQuestion({
          label:
            "Do you maintain policies and procedures for systems and IT & Data security according to applicable laws and rules related to your type of business? ",
          key: "maintainPolicy",
          No: [TextQuestion.Explain("maintainPolicyNo")],
          Yes: [
            new ChoiceQuestion({
              key: "maintainPolicyYearly",
              label: "How often are the policies and procedures reviewed?",
              isMultipleChoice: false,
              choices: HowOftenChoices("maintainPolicyYearlyOther"),
            }),
            new ChoiceQuestion({
              key: "policyReviewYearly",
              label: "How often are the procedures tested?",
              isMultipleChoice: false,
              choices: HowOftenChoices("policyReviewYearlyOther"),
            }),
            new YesNoQuestion({
              label: "Are your IT & Data General Security procedures audited?",
              key: "areITGeneralSecurityProceduresAudited",
              Yes: [
                TextQuestion.Explain(
                  "areITGeneralSecurityProceduresAuditedExplain",
                ),
              ],
            }),
          ],
        }),
      ],
    }),
    new QuestionGroup("Data Protection", {
      questions: [
        new ChoiceQuestion({
          key: "subjectToGDP",
          label:
            "Is your entity, directly or indirectly, subject to a dedicated Data Protection Regulation?",
          isMultipleChoice: false,
          choices: [
            { value: "Yes GDPR" },
            { value: "Yes, another regulation" },
            {
              value: "No",
              questions: [TextQuestion.Explain("subjectToGDPNo")],
            },
          ],
        }),
        new TextQuestion({
          key: "descriptionOfPrivacyProgram",
          label:
            "Please describe the company’s Data Protection / Privacy Program",
          isMultiLine: true,
        }),
        new YesNoQuestion({
          key: "transferOutsideEU",
          label:
            "Does your entity, its outsourcers or service providers transfer data outside the EU?",
          Yes: [TextQuestion.Explain("transferOutsideEUYes")],
          No: [
            new YesNoQuestion({
              key: "minimumGuarantees",
              label:
                "Do you verify that your outsourcers, service providers or subcontractors offer the minimum guarantees required in terms of security and protection of personal data?",
              No: [TextQuestion.Explain("minimumGuaranteesNo")],
            }),
          ],
        }),
      ],
    }),
    new QuestionGroup("Data Protection Officer", {
      questions: [
        new TextQuestion({ key: "DPOName", label: "Name", isMultiLine: false }),
        new TextQuestion({
          key: "DPOSurname",
          label: "Surname",
          isMultiLine: false,
        }),
        new TextQuestion({
          key: "DPOEmail",
          label: "Email",
          isMultiLine: false,
        }),
        new TextQuestion({ key: "DPOFirm", label: "Firm", isMultiLine: false }),
      ],
    }),
    new QuestionGroup("Data Access Restrictions", {
      questions: [
        new YesNoQuestion({
          key: "DARProceduresIfBreach",
          label:
            "In the case that an employee violates your data protection policies, are they confronted with disciplinery procedures?",
          No: [TextQuestion.Explain("DARProceduresIfBreachedExplain")],
        }),
      ],
    }),
    // TODO: for this group of questions we need some way to signal to the ui that this is really just a heading...not a group
    new QuestionGroup("Technical restrictions", {
      helpText:
        "Have you put in place technical restrictions on your system to:",
      questions: [
        new YesNoQuestion({
          key: "DARDataToUSB",
          label: "Preclude the copying of data from your system to USB memory?",
          No: [TextQuestion.Explain("DARDataToUSBExplain")],
        }),
        new YesNoQuestion({
          key: "DARDataToEmail",
          label: "Preclude the copying of data from your system to emails?",
          No: [TextQuestion.Explain("DARDataToEmailExplain")],
        }),
        new YesNoQuestion({
          key: "DARDataToPrint",
          label: "Impede the printing of data from your systems?",
          No: [TextQuestion.Explain("DARDataToPrintExplain")],
        }),
      ],
    }),
    new QuestionGroup("Cyber Security", {
      questions: [
        new YesNoQuestion({
          key: "CSMaintainPolicies",
          label: "Do you maintain Cyber Security policy?",
          Yes: [
            new ChoiceQuestion({
              key: "CSReviewHowOften",
              label: "How often is your Cyber Security policy reviewed?",
              isMultipleChoice: false,
              choices: HowOftenChoices("CSReviewHowOftenExplain"),
            }),
            new ChoiceQuestion({
              key: "CSElements",
              label:
                "Which of the following elements are a part of your cybersecurity framework?",
              isMultipleChoice: true,
              choices: [
                "Vulnerability tests",
                "Penetration tests",
                "Testing of simulated emergency situations and their responses",
                ChoiceQuestion.Choice("Other", {
                  questions: [TextQuestion.Explain("CSElementsOtherExplain")],
                }),
              ],
            }),
            new ChoiceQuestion({
              key: "CSElementsHowOften",
              label: "How often are these elements tested?",
              isMultipleChoice: false,
              choices: [
                "At least annually or upon need",
                ChoiceQuestion.Choice("Other", {
                  questions: [
                    TextQuestion.Explain("CSElementsHowOftenExplain"),
                  ],
                }),
              ],
            }),
            new TextQuestion({
              key: "CSEarlyDetection",
              label:
                "What procedures does your company have to ensure early detection of a cyber attack?",
              isMultiLine: true,
            }),
          ],
        }),
      ],
    }),
  ],
};

export default category;
