import {
  TextQuestion,
  ChoiceQuestion,
  YesNoQuestion,
  QuestionGroup,
} from "../questions";
import { countries } from "../datasources";
import { toChoices } from "../questions/ChoiceQuestion";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.TAX_INFORMATION,
  questions: [
    new ChoiceQuestion({
      key: "taxdomicile",
      label: "Tax domicile",
      choices: countries,
    }),
    new ChoiceQuestion({
      key: "taxdomicileentity",
      label: "Countries in which your entity is taxable",
      choices: countries,
      isMultipleChoice: true,
    }),
    new TextQuestion({
      key: "taxid",
      label: "Tax identification number (TIN)",
    }),
    new QuestionGroup("FATCA Status", {
      // To avoid being withheld upon, foreign financial institutions (FFIs) may register with the IRS (Internal Revenue Service in the U.S.) and agree to report to the IRS certain information about their U.S. accounts, including accounts of certain non-U.S. entities with substantial U.S. owners.
      questions: [
        new YesNoQuestion({
          key: "usEntity",
          label: "Does your institution qualify as US person?",
        }),
        new YesNoQuestion({
          key: "usClient",
          label: "Are any of your clients a US person?",
          Yes: [
            new TextQuestion({
              key: "usClientExplain",
              label: "Please explain (which products, accounts,etc)",
            }),
          ],
        }),
        new ChoiceQuestion({
          key: "FatcaStatus",
          label: "FATCA status",
          choices: [
            ...toChoices([
              "Nonparticipating FFI",
              "Participating FFI",
              "Reporting Model 1 FFI",
              "Reporting Model 2 FFI",
              "Registered deemed-compliant FFI",
              "Territory financial institution",
              "Sponsored FFI",
              "Certified deemed-compliant nonregistering local bank",
              "Certified deemed-compliant FFI with only low-value accounts",
              "Certified deemed-compliant sponsored, closely held investment vehicle",
              "Certified deemed-compliant limited life debt investment entity",
              "Certified deemed-compliant investment advisors and investment managers",
              "Owner-documented FFI",
              "Restricted distributor",
              "Foreign central bank of issue",
              "Nonreporting IGA FFI",
              "Exempt retirement plans",
              "Excepted nonfinancial group entity",
              "Excepted nonfinancial start-up company",
              "Excepted nonfinancial entity in liquidation or bankruptcy",
              "Publicly traded NFFE or NFFE affiliate of a publicly traded corporation",
              "Excepted territory NFFE",
              "Active NFFE",
              "Passive NFFE",
              "Direct reporting NFFE",
              "Sponsored direct reporting NFFE",
            ]),
            {
              value: "Other",
              questions: [TextQuestion.Specify("FatcaStatusOther")],
            },
          ],
        }),
        new TextQuestion({
          key: "GiinNumber",
          label: "GIIN number",
        }),
      ],
    }),
  ],
};

export default category;
