import { Flex, Statistic } from "antd";
import { useMutation } from "react-query";
import React, { useEffect } from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  Grid,
  Icon,
  Modal,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { Text } from "@gfw/orion";

import {
  Page,
  PageTitle,
  PageTitleTypo,
} from "@app/resources/generic/components";

function StatsPage() {
  const {
    mutateAsync: getStats,
    isLoading: isFetching,
    data: stats,
  } = useMutation(async () => apiClient.get(`/admin/stats`), {
    onError: __DEPRECATED__ErrorHandler,
  });

  const { mutateAsync: refreshStats, isLoading: isRefreshing } = useMutation(
    async () => {
      await apiClient.put(`/admin/stats`, {
        timeout: 2000000,
      });
      getStats();
    },
    {
      onSuccess: () => notification.success({ message: `Stats  updated` }),
      onError: __DEPRECATED__ErrorHandler,
    },
  );
  useEffect(() => {
    getStats();
  }, [getStats]);

  return (
    <Page>
      <Flex justify="space-between">
        <PageTitle>
          <Icon mr="sm" type="queryStats" />
          <PageTitleTypo>Stats</PageTitleTypo>
        </PageTitle>
        <PageTitle>
          <Flex align="flex-end" gap="small" vertical>
            <Modal
              hideFooterBorder
              hideHeaderBorder
              onOk={refreshStats}
              title="Update stats"
              trigger={
                <Button
                  icon="refresh"
                  loading={isRefreshing || isFetching}
                  type="primary"
                >
                  Update stats
                </Button>
              }
            >
              <Text mb="md">
                Do you really want to update the stats for all profiles ?
              </Text>
              <Text fw="bold">
                It might generate performance issue on the app !
              </Text>
            </Modal>
            {stats?.data?.updatedAt && (
              <Flex align="baseline" justify="flex-end">
                Updated at:
                <Text fs="12px" mx="sm">
                  {stats.data.updatedAt}
                </Text>
              </Flex>
            )}
          </Flex>
        </PageTitle>
      </Flex>
      <Grid.Row gutter={[16, 16]}>
        <Grid.Col sm={12} xs={24}>
          <Card>
            <Statistic
              prefix={<Icon fs="md" mr="sm" type="users" />}
              title="Profiles"
              value={stats?.data?.profilesCount}
            />
          </Card>
        </Grid.Col>
        <Grid.Col sm={12} xs={24}>
          <Card>
            <Statistic
              prefix={<Icon fs="md" mr="sm" type="users" />}
              title="Users"
              value={stats?.data?.usersCount}
            />
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page>
  );
}

export default StatsPage;
