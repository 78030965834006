import { ChoiceQuestion, YesNoQuestion, QuestionGroup } from "../questions";
import { toChoices } from "../questions/ChoiceQuestion";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const s_and_p = toChoices([
  "AAA",
  "AA+",
  "AA",
  "AA-",
  "A+",
  "A",
  "A-",
  "BBB+",
  "BBB",
  "BBB-",
  "BB+",
  "BB",
  "BB-",
  "B+",
  "B",
  "B-",
  "CCC+",
  "CCC",
  "CCC-",
  "CC",
  "C",
  "D",
  "None",
]);

const moody = toChoices([
  "Aaa",
  "Aa1",
  "Aa2",
  "Aa3",
  "A1",
  "A2",
  "A3",
  "Baa1",
  "Baa2",
  "Baa3",
  "Ba1",
  "Ba2",
  "Ba3",
  "B1",
  "B2",
  "B3",
  "Caa1",
  "Caa2",
  "Caa3",
  "Ca",
  "C",
  "None",
]);

const fitch = toChoices([
  "AAA",
  "AA+",
  "AA",
  "AA-",
  "A+",
  "A",
  "A-",
  "BBB+",
  "BBB",
  "BBB-",
  "BB+",
  "BB",
  "BB-",
  "B+",
  "B",
  "B-",
  "CCC",
  "DDD",
  "DD",
  "D",
  "None",
]);

const category = {
  ...QUESTIONNAIRE_CATEGORIES.FINANCIAL,
  questions: [
    new QuestionGroup("Credit Rating", {
      questions: [
        new YesNoQuestion({
          key: "rated-by-agencies",
          label:
            "Do any of the leading credit rating agencies - S&P, Moody's, or Fitch - rate you?",
          Yes: [
            new QuestionGroup(
              "long-term credit ratings for the group/holding company",
              {
                questions: [
                  new ChoiceQuestion({
                    key: "s-and-p-group",
                    label: "S&P",
                    isMultipleChoice: false,
                    choices: s_and_p,
                  }),
                  new ChoiceQuestion({
                    key: "moody-group",
                    label: "Moody's",
                    isMultipleChoice: false,
                    choices: moody,
                  }),
                  new ChoiceQuestion({
                    key: "fitch-group",
                    label: "Fitch",
                    isMultipleChoice: false,
                    choices: fitch,
                  }),
                ],
              },
            ),
            new QuestionGroup(
              "long-term credit ratings for the transfer agency legal entity",
              {
                questions: [
                  new ChoiceQuestion({
                    key: "s-and-p-transfer-agency-entity",
                    label: "S&P",
                    isMultipleChoice: false,
                    choices: s_and_p,
                  }),
                  new ChoiceQuestion({
                    key: "moody-transfer-agency-entity",
                    label: "Moody's",
                    isMultipleChoice: false,
                    choices: moody,
                  }),
                  new ChoiceQuestion({
                    key: "fitch-transfer-agency-entity",
                    label: "Fitch",
                    isMultipleChoice: false,
                    choices: fitch,
                  }),
                ],
              },
            ),
            new QuestionGroup(
              "long-term credit ratings for the transfer agency local office",
              {
                questions: [
                  new ChoiceQuestion({
                    key: "s-and-p-transfer-agency-office",
                    label: "S&P",
                    isMultipleChoice: false,
                    choices: s_and_p,
                  }),
                  new ChoiceQuestion({
                    key: "moody-transfer-agency-office",
                    label: "Moody's",
                    isMultipleChoice: false,
                    choices: moody,
                  }),
                  new ChoiceQuestion({
                    key: "fitch-transfer-agency-office",
                    label: "Fitch",
                    isMultipleChoice: false,
                    choices: fitch,
                  }),
                ],
              },
            ),
          ],
        }),
      ],
    }),
  ],
};

export default category;
