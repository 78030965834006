function getKeys(obj: Record<string, unknown>) {
  return Object.keys(obj);
}

function getValues(obj: Record<string, unknown>) {
  return Object.values(obj);
}

function getEnumValues<T = unknown>(obj: Record<string, T>) {
  return Object.values(obj).filter((v) => typeof v === "number");
}

function getEntries(obj: Record<string, unknown>) {
  return Object.entries(obj);
}

export { getKeys, getValues, getEnumValues, getEntries };
