import { escapeRegExp } from "lodash";

const specialCharacters = escapeRegExp("\\!/@#$%^&*\"'?-_+,°|[]|");

const REGEX = Object.freeze({
  PASSWORD_FULL: `^(?=.{12,})(?=.*[0-9])(?=.*[${specialCharacters}])(?=.*[A-Z])`,
  SPECIAL_CHARACTER: specialCharacters,
});

export { REGEX };
