import {
  QuestionnaireQuestion,
  QuestionGroup,
  TextQuestion,
  CustomQuestion,
  BooleanQuestion,
  ChoiceQuestion,
} from "./questions";

export function isQuestionGroup(
  question: QuestionnaireQuestion,
): question is QuestionGroup {
  return question.type === "group";
}

function isTextQuestion(
  question: QuestionnaireQuestion,
): question is TextQuestion {
  return question.type === "text";
}

function isCustomQuestion(
  question: QuestionnaireQuestion,
): question is CustomQuestion {
  return question.type === "custom";
}

function isBooleanQuestion(
  question: QuestionnaireQuestion,
): question is BooleanQuestion {
  return question.type === "boolean";
}

function isChoiceQuestion(
  question: QuestionnaireQuestion,
): question is ChoiceQuestion {
  return question.type === "choice";
}

export function convertSnapshotToQuestion(
  snapshot: QuestionnaireQuestion,
): QuestionnaireQuestion {
  if (isQuestionGroup(snapshot)) {
    return new QuestionGroup(snapshot.label, {
      ...snapshot,
      questions: snapshot.questions.map(convertSnapshotToQuestion),
    });
  } else if (isTextQuestion(snapshot)) {
    let question = new TextQuestion(snapshot);
    question.answer = snapshot.answer;
    return question;
  } else if (isCustomQuestion(snapshot)) {
    let question = new CustomQuestion(snapshot);
    question.answer = snapshot.answer;
    return question;
  } else if (isBooleanQuestion(snapshot)) {
    let question = new BooleanQuestion({
      ...snapshot,
      questions: snapshot.questions.map(convertSnapshotToQuestion),
    });
    question.preProcessSnapshotAnswer(snapshot.answer);
    return question;
  } else if (isChoiceQuestion(snapshot)) {
    let question = new ChoiceQuestion({
      ...snapshot,
      questions: snapshot.questions.map(convertSnapshotToQuestion),
      choices: snapshot.choices.map((choice) => ({
        ...choice,
        questions: choice.questions?.map(convertSnapshotToQuestion),
      })),
    });
    question.preProcessSnapshotAnswer(snapshot.answer);
    return question;
  }

  throw new Error(
    `Can´t create question of type "${snapshot.type}" from snapshot.`,
  );
}
