import { USER_ROLE, ADMIN_ROLE, PERMISSIONS } from "@app/constants";
import { intersection } from "lodash";

// a permission is a string but it works like this {Entity/Resource}.{CRUD-operation} (e.g. Profile.create Profile.update, Profile.delete)

export const DOC_PERMISSIONS: Readonly<string[]> = Object.freeze([
  PERMISSIONS.ID_AUTHORIZED_SIGNATORY.CREATE,
  PERMISSIONS.ID_BOARD_MEMBER.CREATE,
  PERMISSIONS.ID_CEO.CREATE,
  PERMISSIONS.ID_COPY.CREATE,
  PERMISSIONS.ID_OTHER.CREATE,
  PERMISSIONS.ID_UBO.CREATE,
  PERMISSIONS.ID_AUTHORIZED_SIGNATORY.READ,
  PERMISSIONS.ID_BOARD_MEMBER.READ,
  PERMISSIONS.ID_CEO.READ,
  PERMISSIONS.ID_COPY.READ,
  PERMISSIONS.ID_OTHER.READ,
  PERMISSIONS.ID_UBO.READ,
  PERMISSIONS.ID_AUTHORIZED_SIGNATORY.DELETE,
  PERMISSIONS.ID_BOARD_MEMBER.DELETE,
  PERMISSIONS.ID_CEO.DELETE,
  PERMISSIONS.ID_COPY.DELETE,
  PERMISSIONS.ID_OTHER.DELETE,
  PERMISSIONS.ID_UBO.DELETE,
]);
export const READONLY_USER_PERMISSIONS: Readonly<string[]> = Object.freeze([
  PERMISSIONS.ACCESS_RIGHTS.READ,
  PERMISSIONS.BROADCAST.READ,
  PERMISSIONS.CHECKLIST.READ,
  PERMISSIONS.CONTACT.READ,
  PERMISSIONS.CORRESPONDENCE.READ,
  PERMISSIONS.COUNTERPARTY_FILE.READ,
  PERMISSIONS.COUNTERPARTY.READ,
  PERMISSIONS.DOCUMENT.READ,
  PERMISSIONS.DUE_DILIGENCE_CAMPAIGN.READ,
  PERMISSIONS.DUE_DILIGENCE_REPORT.READ,
  PERMISSIONS.DUE_DILIGENCE_REQUEST.READ,
  PERMISSIONS.BANNER.READ,
  PERMISSIONS.PROFILE_FILE.READ,
  PERMISSIONS.PROFILE_USER.READ,
  PERMISSIONS.PROFILE.READ,
  PERMISSIONS.REQUESTS.READ,
  PERMISSIONS.QUESTION_CATEGORY.READ,
  PERMISSIONS.QUESTION.READ,
  PERMISSIONS.SUBSCRIPTION.CREATE,
  PERMISSIONS.SUBSCRIPTION.DELETE,
  PERMISSIONS.SIGNATURE.READ,
  PERMISSIONS.USER_ASSIGNMENT.DELETE,
  PERMISSIONS.USER_ASSIGNMENT.READ,
  PERMISSIONS.USER_ASSIGNMENT.UPDATE,
]);
export const REGULAR_USER_PERMISSIONS: Readonly<string[]> = Object.freeze([
  ...READONLY_USER_PERMISSIONS,
  PERMISSIONS.ACCESS_RIGHTS.CREATE,
  PERMISSIONS.ACCESS_RIGHTS.UPDATE,
  PERMISSIONS.BROADCAST.CREATE,
  PERMISSIONS.BROADCAST.UPDATE,
  PERMISSIONS.CHECKLIST.CREATE,
  PERMISSIONS.CHECKLIST.DELETE,
  PERMISSIONS.CHECKLIST.UPDATE,
  PERMISSIONS.CORRESPONDENCE.CREATE,
  PERMISSIONS.CORRESPONDENCE.DELETE,
  PERMISSIONS.CORRESPONDENCE.UPDATE,
  PERMISSIONS.COUNTERPARTY.CREATE,
  PERMISSIONS.COUNTERPARTY.DELETE,
  PERMISSIONS.COUNTERPARTY.UPDATE,
  PERMISSIONS.DOCUMENT.CREATE,
  PERMISSIONS.DOCUMENT.DELETE,
  PERMISSIONS.DUE_DILIGENCE_CAMPAIGN.CREATE,
  PERMISSIONS.DUE_DILIGENCE_CAMPAIGN.DELETE,
  PERMISSIONS.DUE_DILIGENCE_CAMPAIGN.UPDATE,
  PERMISSIONS.DUE_DILIGENCE_REPORT.CREATE,
  PERMISSIONS.DUE_DILIGENCE_REPORT.DELETE,
  PERMISSIONS.DUE_DILIGENCE_REPORT.UPDATE,
  PERMISSIONS.DUE_DILIGENCE_REQUEST.CREATE,
  PERMISSIONS.DUE_DILIGENCE_REQUEST.DELETE,
  PERMISSIONS.DUE_DILIGENCE_REQUEST.UPDATE,
  PERMISSIONS.PROFILE_FILE.CREATE,
  PERMISSIONS.PROFILE_FILE.DELETE,
  PERMISSIONS.PROFILE_FILE.UPDATE,
  PERMISSIONS.PROFILE_INFO.UPDATE,
  PERMISSIONS.PROFILE.UPDATE,
  PERMISSIONS.QUESTION_CATEGORY.CREATE,
  PERMISSIONS.QUESTION_CATEGORY.DELETE,
  PERMISSIONS.QUESTION_CATEGORY.UPDATE,
  PERMISSIONS.QUESTION.CREATE,
  PERMISSIONS.QUESTION.DELETE,
  PERMISSIONS.QUESTION.UPDATE,
  PERMISSIONS.USER_ASSIGNMENT.CREATE,
]);
export const FULL_USER_PERMISSIONS: Readonly<string[]> = Object.freeze([
  ...REGULAR_USER_PERMISSIONS,
  ...DOC_PERMISSIONS,
  PERMISSIONS.CONTACT.CREATE,
  PERMISSIONS.CONTACT.DELETE,
  PERMISSIONS.CONTACT.UPDATE,
  PERMISSIONS.PROFILE_USER.CREATE,
  PERMISSIONS.PROFILE_USER.DELETE,
  PERMISSIONS.PROFILE_USER.UPDATE,
]);
export const PROFILE_PREMIUM_PERMISSIONS: Readonly<string[]> = Object([
  PERMISSIONS.PROFILE.CREATE,
]);
export const REGULAR_ADMIN_PERMISSIONS: Readonly<string[]> = Object.freeze([
  ...FULL_USER_PERMISSIONS,
  ...PROFILE_PREMIUM_PERMISSIONS,
  PERMISSIONS.ADMIN.READ,
  PERMISSIONS.ADMIN_STATS.READ,
  PERMISSIONS.ADMIN_STATS.UPDATE,
  PERMISSIONS.ARTICLE_PROVIDER.CREATE,
  PERMISSIONS.ARTICLE_PROVIDER.DELETE,
  PERMISSIONS.ARTICLE_PROVIDER.READ,
  PERMISSIONS.ARTICLE_PROVIDER.UPDATE,
  PERMISSIONS.ARTICLE.CREATE,
  PERMISSIONS.ARTICLE.READ,
  PERMISSIONS.ARTICLE.UPDATE,
  PERMISSIONS.CRM.CREATE,
  PERMISSIONS.CRM.DELETE,
  PERMISSIONS.CRM.READ,
  PERMISSIONS.CRM.UPDATE,
  PERMISSIONS.BANNER.CREATE,
  PERMISSIONS.BANNER.UPDATE,
  PERMISSIONS.BANNER.DELETE,
  PERMISSIONS.PROFILE_ADMIN.CREATE,
  PERMISSIONS.PROFILE_ADMIN.DELETE,
  PERMISSIONS.PROFILE_ADMIN.READ,
  PERMISSIONS.PROFILE_ADMIN.UPDATE,
  PERMISSIONS.PROFILE.DELETE,
  PERMISSIONS.RSSFEED.CREATE,
  PERMISSIONS.RSSFEED.DELETE,
  PERMISSIONS.RSSFEED.READ,
  PERMISSIONS.RSSFEED.UPDATE,
  PERMISSIONS.USER_ADMIN.CREATE,
  PERMISSIONS.USER_ADMIN.DELETE,
]);

/**
 * Check whether supplied user permissions grants access to permission
 *
 * @export
 * @param {string} userPermissions
 * @param {*} permissions
 * @returns
 */
export function hasPermissions(
  userPermissions: string[] | undefined,
  permissions: string,
) {
  if (!permissions) return true;
  // we support 'permission_1, permission_2' so let's check for that
  let perms = permissions.split(",").map((x: any) => x.trim());

  if (perms.length === 1) {
    // we also support 'permission_1 | permission_2' so we can end up with this:
    // [ 'permssion_1 | permission_2' ]
    // so let's check for that
    perms = perms[0].split("|").map((x: any) => x.trim());
    // if we still only have 1 element it's check for 1 permission
    if (perms.length === 1) {
      return userPermissions?.includes(perms[0]);
    } else {
      return intersection(userPermissions, perms).length > 0;
    }
  } else {
    return intersection(userPermissions, perms).length === perms.length;
  }
}

/**
 * Get the base permissions a user has from their role
 *
 * @export
 * @param {string} role
 * @returns
 */
export function getPermissionsFromRole(role: string, isPremium = false) {
  let userPermissions = READONLY_USER_PERMISSIONS;
  const roleUpperCase = role?.toUpperCase?.();
  if (roleUpperCase === USER_ROLE.REGULAR) {
    userPermissions = isPremium
      ? [...REGULAR_USER_PERMISSIONS, ...PROFILE_PREMIUM_PERMISSIONS]
      : REGULAR_USER_PERMISSIONS;
  } else if (roleUpperCase === USER_ROLE.FULL) {
    userPermissions = isPremium
      ? [...FULL_USER_PERMISSIONS, ...PROFILE_PREMIUM_PERMISSIONS]
      : FULL_USER_PERMISSIONS;
  } else if (roleUpperCase === ADMIN_ROLE.FULL) {
    userPermissions = REGULAR_ADMIN_PERMISSIONS;
  }

  return userPermissions;
}
