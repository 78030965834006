import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";

import { FlexBox, Text } from "@gfw/orion";
import { getFontSize, Icon } from "@gfw/corvus";
import { ProfileTypes } from "@gfw/core";

export const ProfileItem = styled(function ({
  profile,
  profileValue,
  ...props
}) {
  const { name, id, location, types } = profile;

  return (
    <Link
      {...props}
      css={`
        color: inherit;
      `}
      to={`/profiles/${id}/details`}
    >
      <FlexBox align="center">
        <Icon mr="sm" type="profile" />
        <FlexBox orientation="vertical">
          <Text typography="subtitle-2">{name}</Text>
          {types.length === 1 && (
            <Text opacity={0.9} typography="caption">
              {ProfileTypes.byId(types[0])?.name} - {location}
            </Text>
          )}
          {types.length !== 1 && (
            <Text opacity={0.9} typography="caption">
              {location}
            </Text>
          )}
        </FlexBox>
        <Text
          css={`
            font-size: ${getFontSize("md")};
            color: inherit;
          `}
          ml="auto"
          typography="subtitle-2"
        >
          {!!profileValue && profileValue(profile)}
        </Text>
      </FlexBox>
    </Link>
  );
})`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  ${Icon} {
    opacity: 0.5;
    font-size: ${getFontSize("xl2")};
  }
`;
