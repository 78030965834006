import {
  TextQuestion,
  ChoiceQuestion,
  QuestionGroup,
  YesNoQuestion,
} from "../questions";
import { countries } from "../datasources";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.CORPORATE_INFO,
  questions: [
    new QuestionGroup("Legal Entity Identification", {
      variant: "noBorder",
      questions: [
        new TextQuestion({
          key: "LEINumber",
          label: "LEI Number",
        }),
      ],
    }),
    new QuestionGroup("Corporate Information", {
      questions: [
        new TextQuestion({
          key: "legalName",
          label: "Legal name",
          public: true,
        }),
        new TextQuestion({
          key: "legalForm",
          label: "Legal form",
          isMultiLine: true,
        }),
        new TextQuestion({ key: "website", label: "Website", public: true }),
        new ChoiceQuestion({
          key: "domicile",
          label: "Domicile",
          choices: countries,
          public: true,
        }),
        new TextQuestion({
          key: "address",
          label: "Address",
          isMultiLine: true,
          public: true,
        }),
        new TextQuestion({
          key: "phone",
          label: "Phone number",
          public: true,
        }),
        new TextQuestion({
          key: "parentCompany",
          label: "Parent company",
          public: true,
        }),
      ],
    }),
    new QuestionGroup("Identification of the entity", {
      questions: [
        new TextQuestion({
          key: "corporateIdentityNumber",
          label: "Corporate identity number",
        }),
        new TextQuestion({
          key: "identityIssueAuthority",
          label: "Name of authority issuing corporate identity number",
        }),
        new TextQuestion({
          key: "incorporationDate",
          label: "Date of incorporation",
        }),
        new YesNoQuestion({
          key: "companyListed",
          label: "Is the company listed?",
          Yes: [
            new TextQuestion({
              key: "marketPlaceName",
              label: "Name of the market place",
            }),
            new TextQuestion({
              key: "marketPlaceWebsite",
              label: "Website of the market place",
            }),
          ],
        }),
      ],
    }),
  ],
};

export default category;
