enum CUSTOM_QUESTIONNAIRE_REQUEST_STATE {
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}
enum CUSTOM_QUESTIONNAIRE_REPORT_STATE {
  PENDING = "PENDING",
  REVIEWED = "REVIEWED",
}

export {
  CUSTOM_QUESTIONNAIRE_REQUEST_STATE,
  CUSTOM_QUESTIONNAIRE_REPORT_STATE,
};
