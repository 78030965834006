import { TextQuestion } from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.ADDITIONAL_INFORMATION,
  questions: [
    new TextQuestion({
      key: "generalComments",
      label: "General comments",
      isMultiLine: true,
    }),
  ],
};

export default category;
