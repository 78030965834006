/* eslint-disable no-unused-vars */

enum BANNER_TYPE {
  ALERT,
  BANNER,
}

enum BANNER_ALERT_TYPE {
  WARNING,
  INFO,
  SUCCESS,
  ERROR,
}

export { BANNER_TYPE, BANNER_ALERT_TYPE };
