const syncDataWithProfileInfo: any = Object.freeze({
  website: "website",
  domicile: "location",
  legalName: "name",
  LEINumber: "LEINumber",
});

const syncProfileInfoWithData = Object.keys(syncDataWithProfileInfo).reduce(
  (acc: any, key: string) => {
    const value: string = syncDataWithProfileInfo[key];
    acc[value] = key;
    return acc;
  },
  {},
);

export { syncDataWithProfileInfo, syncProfileInfoWithData };
