import { capitalize } from "lodash";
import { DatePicker, Flex, Form } from "antd";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  __DEPRECATED__ErrorHandler,
  Banner,
  Button,
  Card,
  FormInput,
  Icon,
  Modal,
  notification,
  Space,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { Text } from "@gfw/orion";

import { BANNER_ALERT_TYPE, BANNER_TYPE, getEnumValues } from "@gfw/core";
import {
  Page,
  PageTitle,
  PageTitleTypo,
} from "@app/resources/generic/components";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const StyledForm = styled(Form)`
  max-width: 1024px;
`;

const FormItem = styled(Form.Item)`
  flex: 1;
`;

function BannerPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();

  const [bannerToPreview, setBannerToPreview] = useState(undefined);
  const [isEditMode, setIsEditMode] = useState(false);

  const typeOptions = getEnumValues(BANNER_TYPE).map((value) => ({
    value: value,
    label: capitalize(BANNER_TYPE[value]),
  }));

  const alertTypeOptions = getEnumValues(BANNER_ALERT_TYPE).map((value) => ({
    value: value,
    label: capitalize(BANNER_ALERT_TYPE[value]),
  }));

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < dayjs().subtract(1, "day");
  };

  const onValuesChange = (values) => {
    const { bannerType } = values;

    if (bannerType === BANNER_TYPE.ALERT) {
      form.setFieldValue("isClosable", true);
    } else if (bannerType === BANNER_TYPE.BANNER) {
      form.setFieldValue("isClosable", false);
    }

    setBannerToPreview(form.getFieldsValue());
  };

  const { mutateAsync: getBanner, data: banner } = useMutation(
    async () => {
      const response = await apiClient.get(`/banners/${id}`);
      return response.data;
    },
    {
      onSuccess: (data) => {
        setBannerToPreview(data);
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const { mutateAsync: createBanner, isLoading: isCreateLoading } = useMutation(
    () => {
      const values = form.getFieldsValue();
      return apiClient.post("/banners", values);
    },
    {
      onSuccess: ({ data }) => {
        notification.success({ message: "Banner has been created" });
        navigate(`/banner/${data.id}`);
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const { mutateAsync: editBanner, isLoading: isEditLoading } = useMutation(
    () => {
      const values = form.getFieldsValue();
      return apiClient.patch(`/banners/${id}`, values);
    },
    {
      onSuccess: () => {
        notification.success({ message: "Changes saved successfully" });
        getBanner();
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const { mutateAsync: deleteBanner, isLoading: isDeleteLoading } = useMutation(
    () => apiClient.delete(`/banners/${id}`),
    {
      onSuccess: () => {
        notification.success({ message: "Banner deleted successfully" });
        navigate(`/banner`);
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const renderButton = () => {
    if (isEditMode) {
      return (
        <Modal
          hideFooterBorder
          hideHeaderBorder
          onOk={editBanner}
          title="Edit banner"
          trigger={
            <Button
              disabled={!form.getFieldValue("title")}
              loading={isEditLoading}
              type="primary"
            >
              Save
            </Button>
          }
        >
          <Text>
            Are you sure that you want to save your changes to this banner?
            Please ensure that you have only one banner active of each type.
          </Text>
        </Modal>
      );
    }
    return (
      <Modal
        hideFooterBorder
        hideHeaderBorder
        onOk={form.submit}
        title="Create banner"
        trigger={
          <Button loading={isCreateLoading} type="primary">
            Create
          </Button>
        }
      >
        <Text>
          Are you sure that you want to create this banner? Please ensure that
          you have only one banner active of each type.
        </Text>
      </Modal>
    );
  };

  useEffect(() => {
    if (id) {
      getBanner();
    }
  }, [getBanner, id]);

  useEffect(() => {
    setIsEditMode(!!banner?._id);
    form.resetFields();
  }, [form, banner?._id]);

  return (
    <Page>
      <PageTitle>
        <Icon mr="sm" type="banner" />
        <PageTitleTypo>
          {isEditMode ? "Edit Banner" : "Create Banner"}
        </PageTitleTypo>
      </PageTitle>
      <Card>
        <Flex gap="16px" vertical>
          <StyledForm
            autoComplete="off"
            data-form-type="other"
            form={form}
            initialValues={{
              title: banner?.title,
              description: banner?.description,
              startAt: dayjs(banner?.startAt),
              endAt: dayjs(banner?.endAt),
              bannerType: banner?.bannerType ?? BANNER_TYPE.ALERT,
              alertType: banner?.alertType ?? BANNER_ALERT_TYPE.WARNING,
              isActive: banner?.isActive ?? true,
              isClosable: banner?.isClosable ?? true,
            }}
            labelCol={{ span: 3 }}
            onFinish={createBanner}
            onValuesChange={onValuesChange}
          >
            <FormInput label="Title" name="title" required={true} />
            {form.getFieldValue("bannerType") !== BANNER_TYPE.BANNER && (
              <FormInput
                label="Description"
                name="description"
                type="textarea"
              />
            )}
            <FormItem
              label="Start time"
              name="startAt"
              rules={[
                {
                  required: true,
                  message: "Please enter a start time",
                },
                {
                  message: "Start date cannot be after or same as End date",
                  validator: (_, value) => {
                    if (
                      dayjs(value).isSameOrAfter(
                        dayjs(form.getFieldValue("endAt")),
                      )
                    ) {
                      return Promise.reject(
                        "Start date cannot be after or same as End date",
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DatePicker disabledDate={disabledDate} showTime={true} />
            </FormItem>
            <FormItem
              label="End time"
              name="endAt"
              rules={[
                {
                  required: true,
                  message: "Please enter an end time",
                },
                {
                  message: "End date cannot be before or same as Start date",
                  validator: (_, value) => {
                    if (
                      dayjs(value).isSameOrBefore(
                        dayjs(form.getFieldValue("startAt")),
                      )
                    ) {
                      return Promise.reject(
                        "End date cannot be before or same as Start date",
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DatePicker disabledDate={disabledDate} showTime={true} />
            </FormItem>
            <FormInput
              label="Banner type"
              name="bannerType"
              options={typeOptions}
              type="select"
            />
            <FormInput
              label="Alert type"
              name="alertType"
              options={alertTypeOptions}
              type="select"
            />
            <FormInput label="Active" name="isActive" type="checkbox" />
            <FormInput
              dependencies={["bannerType"]}
              disabled={form.getFieldValue("bannerType") === BANNER_TYPE.BANNER}
              label="Closable"
              name="isClosable"
              type="checkbox"
            />
          </StyledForm>
          {banner?.createdByUser && (
            <Card type="inner">
              <>
                <Text>Created By : {banner.createdByUser.username}</Text>
                <Text mt={2}>
                  Created At : {dayjs(banner.createdAt).format("L LT")}
                </Text>
                {banner?.updatedByUser && (
                  <>
                    <Text mt={2}>
                      Updated By : {banner.updatedByUser.username}
                    </Text>
                    <Text mt={2}>
                      Updated At : {dayjs(banner.updatedAt).format("L LT")}
                    </Text>
                  </>
                )}
              </>
            </Card>
          )}
          {bannerToPreview?.title && (
            <Banner banner={bannerToPreview} isAdminApp={true} />
          )}
          <Space size="small">
            {renderButton()}
            {banner && (
              <Modal
                hideFooterBorder
                hideHeaderBorder
                onOk={deleteBanner}
                title="Delete banner"
                trigger={
                  <Button danger loading={isDeleteLoading} type="primary">
                    Delete
                  </Button>
                }
              >
                <Text>Are you sure that you want to delete this banner?</Text>
              </Modal>
            )}
          </Space>
        </Flex>
      </Card>
    </Page>
  );
}

export default BannerPage;
