import { QuestionnaireCategory } from "./categories/QuestionnaireCategory";
import { QuestionnaireQuestion } from "./questions/QuestionnaireQuestion";
import { QuestionGroup } from "./questions/QuestionGroup";

export interface IQuestionnaireSnapshot {
  [key: string]: any;
}

export function applySnapshot(
  target: QuestionnaireQuestion[],
  snapshot: IQuestionnaireSnapshot,
): void {
  for (const question of target) {
    question.applySnapshot(snapshot);
  }
}

export class Questionnaire {
  categories: QuestionnaireCategory[];
  constructor(categories: QuestionnaireCategory[]) {
    this.categories = categories;
  }

  applySnapshot(snapshot: IQuestionnaireSnapshot): Questionnaire {
    const categories = this.categories.map((category) => {
      return new QuestionnaireCategory({
        ...category,
        questions: category.questions.map((question) => question.clone()),
      });
    });

    for (const category of categories) {
      applySnapshot(category.questions, snapshot);
    }

    return new Questionnaire(categories);
  }

  /**
   * Returns a list of all the questions in this questionnaire.
   * You can customize the depth it should use by supplying {depth: number}
   */
  getQuestions(options: { depth?: number } = {}): QuestionnaireQuestion[] {
    const opts = Object.assign({ depth: 1 }, options);

    return this.categories.reduce<QuestionnaireQuestion[]>(
      (questions, category) => {
        return [...questions, ...category.getQuestions(opts)];
      },
      [],
    );
  }

  /**
   * Returns just the question groups (group of questions) in this questionnaire.
   */
  getGroups(): QuestionGroup[] {
    return this.categories.reduce<QuestionGroup[]>((groups, category) => {
      return [...groups, ...category.getGroups()];
    }, []);
  }

  /**
   * Returns a specific question from this questionnaire
   */
  getQuestion(id: string): QuestionnaireQuestion {
    for (const category of this.categories) {
      const question = category.getQuestion(id);
      if (question) {
        return question;
      }
    }

    throw new Error(`Could not find question "${id}" in this questionnaire.`);
  }
}
