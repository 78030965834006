import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  font-size: 24px;
`;

function PageTitle({ children }) {
  return <StyledDiv>{children}</StyledDiv>;
}

export { PageTitle };
