/* eslint-disable no-unused-vars */

enum RISK_LEVELS {
  NOT_SET = 0,
  LOW = 100,
  MEDIUM = 200,
  MEDIUM_HIGH = 300,
  HIGH = 400,
}

Object.freeze(RISK_LEVELS);

export { RISK_LEVELS };
