import { Flex } from "antd";
import { observer } from "mobx-react";
import React from "react";

import { FormControl, Input } from "@gfw/orion";

const NewsProviderForm = observer(function NewsProviderForm({ provider }) {
  return (
    <Flex gap="middle" vertical>
      <FormControl>
        <FormControl.Label>Name</FormControl.Label>
        <Input
          onChange={(e) => {
            provider.update("name", e.target.value);
          }}
          type="text"
          value={provider.name}
        />
      </FormControl>

      <FormControl>
        <FormControl.Label>Home Page</FormControl.Label>
        <Input
          onChange={(e) => {
            provider.update("homepage", e.target.value);
          }}
          type="text"
          value={provider.homepage}
        />
      </FormControl>

      <FormControl>
        <FormControl.Label>Image</FormControl.Label>
        <Input
          onChange={(e) => {
            provider.update("image", e.target.value);
          }}
          type="text"
          value={provider.image}
        />
      </FormControl>

      <FormControl>
        <FormControl.Label>Country</FormControl.Label>
        <Input
          onChange={(e) => {
            provider.update("iso2", e.target.value);
          }}
          size="xs"
          type="text"
          value={provider.iso2}
        />
        <FormControl.Text font-size="10px">
          ISO2 name (fr for france as an example)
        </FormControl.Text>
      </FormControl>
    </Flex>
  );
});

export { NewsProviderForm };
