import { Collapse, Pagination } from "antd";
import styled from "styled-components";

export const Spacer = styled.div`
  height: 25px;
`;

export const Owner = styled.div`
  opacity: 0.5;
  text-align: right;
  padding: 0px;
  padding-left: 14px;
`;

export const PaginationStyled = styled(Pagination)`
  text-align: center;
  padding-bottom: 10px;
`;

export const CollapseStyled = styled(Collapse)`
  padding-bottom: 10px;
  text-align: right;
  opacity: 0.5;
`;
