import { TextQuestion, YesNoQuestion } from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.ACTIVITY,
  questions: [
    new TextQuestion({
      key: "companyActivities",
      label: "What are the company activities?",
      isMultiLine: true,
    }),
    // TODO: shouldn't this just be a selector?? Hard with the value being a free text right now though...migrate?
    new TextQuestion({
      key: "activitiesLocated",
      label: "In which countries are your activities located?",
      isMultiLine: true,
    }),
    new YesNoQuestion({
      key: "regulatoryControl",
      label: "Is the activity submitted to a regulatory control?",
      Yes: [
        new TextQuestion({
          key: "authorityName",
          label: "Name of the authority",
        }),
        new TextQuestion({
          key: "authorityWebsite",
          label: "Website of the authority",
        }),
      ],
    }),
  ],
};

export default category;
