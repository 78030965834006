import { ChoiceQuestion, TextQuestion } from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.INVESTMENT_INFORMATION,
  questions: [
    new ChoiceQuestion({
      key: "sourceOfInvestment",
      label: "What is the source of your investment?",
      isMultipleChoice: true,
      threshold: 8,
      choices: [
        "Global Assets (insurance)",
        "Equity & reserves",
        "Third-parties accounts",
        "Selling of goods and services",
        "Cashflow",
        "Exceptional",
        ChoiceQuestion.Choice("Other", {
          questions: [TextQuestion.Specify("sourceOfInvestmentOther")],
        }),
      ],
    }),
    // TODO: this should really be a country picker right?? But hard to do with the value being free text right now
    new TextQuestion({
      key: "investmentLocated",
      label: "In which countries are your activities located?",
      isMultiLine: true,
    }),
    new ChoiceQuestion({
      key: "totalAssetsManaged",
      label: "Total assets managed?",
      choices: [
        "0-50 Million Euro",
        "50-100 Million Euro",
        "100-500 Million Euro",
        "500-1000 Million Euro",
        "Over 1 Billion Euro",
      ],
    }),
  ],
};

export default category;
