import { QUESTIONNAIRE_ITEM_TYPE } from "@app/constants";
import { messagesKeys } from "@app/validation/messages";

import { ItemRuleValidator } from "../../type";

export const checkLenRule: ItemRuleValidator = (item, value, ruleValue) => {
  if (
    [QUESTIONNAIRE_ITEM_TYPE.TEXT, QUESTIONNAIRE_ITEM_TYPE.TEXTAREA].includes(
      item.type,
    )
  ) {
    if (value.length === Number(ruleValue)) return null;
    return messagesKeys.CQAnswers.stringNotExact;
  }
  throw new Error("item type is not handled");
};
