const PERSON_TYPES = Object.freeze({
  CONTACT: "contact",
  UBO: "ubo",
});

/**
 * Responsibilities/Roles that a person can have
 */
const PERSON_ROLES = Object.freeze([
  "CEO",
  "CFO",
  "Head of Legal Department",
  "Head of Compliance Department",
  "Head of Operations",
  "Head of Sales",
  "Head of Client Services",
  "Head of Risk",
  "AML Officer/MLRO",
  "Compliance Officer",
  "Legal counsel",
  "Relationship Manager",
]);

/**
 * Array of all person types
 */
function getPersonTypes() {
  return Object.values(PERSON_TYPES);
}

export { PERSON_TYPES, PERSON_ROLES, getPersonTypes };
