import { TextQuestion, ChoiceQuestion, QuestionGroup } from "../questions";
import { toChoices } from "../questions/ChoiceQuestion";
import { countries } from "../datasources";
import { allContinentNames } from "../../continents";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.INSTRUMENTS_AND_MARKETS,
  questions: [
    new ChoiceQuestion({
      key: "instrumentTypes",
      label: "Types of instruments to be traded",
      isMultipleChoice: true,
      choices: [
        ...toChoices([
          "Equities",
          "Fixed income",
          "Loans",
          "Deposits",
          "FX Spot/FX Forward",
          "P-note",
          "Futures/Options (ETD)",
          "OTC instruments w. ISDA",
          "Securities Lending",
          "Margin Clearer",
          "Alternative investments",
        ]),
        {
          value: "Other",
          questions: [TextQuestion.Specify("instrumentTypesOther")],
        },
      ],
    }),
    new QuestionGroup("Distribution network", {
      questions: [
        new ChoiceQuestion({
          key: "targetmarkets",
          label: "Target markets",
          choices: countries,
          isMultipleChoice: true,
        }),
        new ChoiceQuestion({
          key: "targetmarketscontinent",
          label: "Majority of clients are domiciled in",
          choices: toChoices(allContinentNames),
          isMultipleChoice: true,
        }),
      ],
    }),
  ],
};

export default category;
