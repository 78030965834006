/* eslint-disable no-unused-vars */

export enum USER_ASSIGNMENT_TYPE {
  DUE_DILIGENCE_REQUEST = 100,
  ACCESS_RIGHTS_REQUEST = 500,
}

export enum USER_ASSIGNMENT_STATUS {
  PENDING = 100,
  COMPLETED = 200,
  CANCELED = 300,
  DECLINED = 400,
}
