import { Observer, useLocalObservable } from "mobx-react";
import { Switch } from "antd";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import gql from "graphql-tag";
import React from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  Grid,
  Icon,
  Loading,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { FlexBox, Text } from "@gfw/orion";

import { NewsArticleForm } from "@app/resources/News/components";
import { newsArticleModel } from "@app/resources/News/utils";
import {
  Page,
  PageTitle,
  PageTitleTypo,
} from "@app/resources/generic/components";

const NEWS_ARTICLE_QUERY = gql`
  query NewsArticle($id: ID!) {
    NewsArticle(id: $id) {
      id
      title
      subtitle
      publishedAt
      hidden
      link
      type
      provider {
        id
        name
      }
    }
  }
`;

function EditNewsArticlePage() {
  const { id } = useParams();

  const article = useLocalObservable(() => newsArticleModel);

  const { isLoading } = useQuery(
    ["newsArticle"],
    async () => {
      const response = await apiClient.graphql(NEWS_ARTICLE_QUERY, {
        variables: { id },
      });

      if (response?.NewsArticle) {
        const { NewsArticle } = response;
        const articleData = {
          title: NewsArticle.title,
          subtitle: NewsArticle.subtitle,
          link: NewsArticle.link,
          type: NewsArticle.type,
          hidden: NewsArticle.hidden,
          provider: NewsArticle.provider,
          publishedAt: NewsArticle.publishedAt,
        };
        article.updateAll(articleData);
      } else {
        return { error: "error" };
      }
    },
    { refetchOnWindowFocus: false },
  );

  const updateNewsArticleHandler = async () => {
    try {
      await apiClient.patch(`/news/articles/${id}`, article.getSnapshot());
      notification.success({ message: `The article has been updated!` });
    } catch (error) {
      __DEPRECATED__ErrorHandler(error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <PageTitle>
        <Icon mr="sm" type="news" />
        <PageTitleTypo>Edit News Article</PageTitleTypo>
      </PageTitle>
      <Card>
        <Grid.Row gutter={64}>
          <Grid.Col flex={4}>
            <NewsArticleForm article={article} isEditing={true} />
            <Observer>
              {() => (
                <Button
                  disabled={article.invalid}
                  mt="lg2"
                  onClick={updateNewsArticleHandler}
                  type="primary"
                >
                  Update
                </Button>
              )}
            </Observer>
          </Grid.Col>
          <Grid.Col flex={1}>
            <Text>Actions</Text>
            <FlexBox align="center" my="sm">
              <Switch
                checked={article.hidden}
                onChange={() => {
                  article.update("hidden", !article.hidden);
                  updateNewsArticleHandler();
                }}
                size="small"
              />
              <Text ml="sm">Hide</Text>
            </FlexBox>
          </Grid.Col>
        </Grid.Row>
      </Card>
    </Page>
  );
}

export { EditNewsArticlePage };
