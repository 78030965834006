const EXPIRES_IN_SECONDS = 86400 * 14;

const TOKEN_CONFIGURATION = Object.freeze({
  EXPIRES_IN_SECONDS,
  EXPIRES_IN_SECONDS_TEMP: 60 * 60,
  EXPIRES_IN_MS: EXPIRES_IN_SECONDS * 1000,
  EXPIRES_IN_MS_AFTER_RENEW: 60 * 60 * 1000,
  RENEW_IF_EXPIRES_IN_MS: Math.floor((EXPIRES_IN_SECONDS * 1000) / 2),
});

export { TOKEN_CONFIGURATION };
