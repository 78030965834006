import {
  TextQuestion,
  ChoiceQuestion,
  YesNoQuestion,
  QuestionGroup,
} from "../questions";
import { toChoices } from "../questions/ChoiceQuestion";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.GOVERNANCE_RISK_COMPLIANCE,
  questions: [
    new QuestionGroup("Governing documents", {
      variant: "noBorder",
      questions: [
        new ChoiceQuestion({
          key: "Policies",
          label:
            "Mark the areas in which you have implemented policies and plans?",
          isMultipleChoice: true,
          choices: [
            ...toChoices([
              "Code of Ethics/Conduct",
              "Anti-Bribery & Corruption",
              "Conflicts of Interests (incl. inducements)",
              "Whistleblowing",
              "Handling of Complaints",
              "Data protection & Confidentiality",
              "Outsourcing",
              "Compliance",
              "Risk Management",
              "Internal Audit",
              "Incident reporting",
              "IT & Security",
              "Business Continuity Plan",
              "Disaster Recovery Plan",
              "Anti-Money-Laundering (AML) & Counter-Financing-of-Terrorism (CFT)",
              "Know your client",
              "Tax evasion",
              "Sales code of conduct",
              "Personal Account dealing",
              "Fraud Prevention",
            ]),
            {
              value: "Other",
              questions: [TextQuestion.Specify("PoliciesOther", true)],
            },
          ],
        }),
        new ChoiceQuestion({
          key: "ApprovingBody",
          label: "Approving body",
          choices: [
            { value: "Board" },
            {
              value: "Other",
              questions: [TextQuestion.Specify("ApprovingBodyOther")],
            },
          ],
        }),
        new ChoiceQuestion({
          key: "grc-frequency-of-reviews",
          label: "Frequency of reviews",
          choices: [
            { value: "At least annually and upon need" },
            {
              value: "Other",
              questions: [
                TextQuestion.Explain("grc-frequency-of-reviews-other"),
              ],
            },
          ],
        }),
      ],
    }),
    new QuestionGroup("Escalation process", {
      questions: [
        new YesNoQuestion({
          key: "escalationProcess",
          subtitle: "Regulatory status",
          label:
            "Confirm that you have an adequate escalation process in case of any material breach and/or changes in relation to your regulatory status",
          No: [TextQuestion.Explain("escalationProcessNo")],
        }),
        new YesNoQuestion({
          key: "incidentReporting",
          subtitle: "Incident reporting",
          label:
            "Confirm that you have an adequate process for escalation, reporting and documentation of incidents within your business operations.",
          No: [TextQuestion.Explain("incidentReportingNo")],
        }),

        new YesNoQuestion({
          key: "confirm_insurance",
          subtitle: "Insurance",
          label:
            "Confirm that you currently hold insurance for your activities in order to cover your risks adequately.",
          No: [TextQuestion.Explain("confirm_insurance_no")],
          Yes: [
            new ChoiceQuestion({
              key: "confirm_insurance_yes",
              label: "Covered risks",
              isMultipleChoice: true,
              choices: [
                ...toChoices([
                  "Professional & civil liability",
                  "Crime",
                  "Directors & Officers",
                ]),
                {
                  value: "Other",
                  questions: [
                    TextQuestion.Explain("confirm_insurance_yes_explain"),
                  ],
                },
              ],
            }),
          ],
        }),

        new ChoiceQuestion({
          key: "claimLast5YearsInsuranceCompany",
          label:
            "During the past 5 years, have you made a claim with your insurance company?",
          choices: toChoices(["Yes", "No", "N/A"]),
        }),
      ],
    }),
    new QuestionGroup("Money Laundering Reporting Officer", {
      questions: [
        new TextQuestion({
          key: "MLROName",
          label: "Name",
        }),
        new TextQuestion({
          key: "MLROTitle",
          label: "Title",
        }),
        new TextQuestion({
          key: "MLROFirm",
          label: "Firm, if outsourced",
        }),
        new TextQuestion({
          key: "MLROEmail",
          label: "Email",
        }),
        new TextQuestion({
          key: "MLROPhone",
          label: "Phone",
        }),
      ],
    }),
    new QuestionGroup("Compliance Function", {
      questions: [
        new TextQuestion({
          key: "CFName",
          label: "Head of Compliance",
        }),
        new TextQuestion({
          key: "CFFirm",
          label: "Firm, if outsourced",
        }),
        new TextQuestion({
          key: "CFEmail",
          label: "Email",
        }),
        new TextQuestion({
          key: "CFPPhone",
          label: "Phone",
        }),
      ],
    }),
    new QuestionGroup("Risk Management Function", {
      questions: [
        new TextQuestion({
          key: "RMFName",
          label: "Head of Risk Management",
        }),
        new TextQuestion({
          key: "RMFFirm",
          label: "Firm, if outsourced",
        }),
        new TextQuestion({
          key: "RMFEmail",
          label: "Email",
        }),
        new TextQuestion({
          key: "RMFPhone",
          label: "Phone",
        }),
      ],
    }),
    new QuestionGroup("Internal Audit Function", {
      questions: [
        new TextQuestion({
          key: "IAFName",
          label: "Head of Internal Audit",
        }),
        new TextQuestion({
          key: "IAFFirm",
          label: "Firm, if outsourced",
        }),
        new YesNoQuestion({
          key: "confirmAuditReview",
          label:
            "Can you please confirm that an annual review is performed by an external auditor regarding the efficacy and sufficiency of internal controls put in place.",
        }),
      ],
    }),
    new QuestionGroup("Outsourcing", {
      questions: [
        new TextQuestion({
          isMultiLine: true,
          key: "outsourcing_functions",
          label:
            "If any of the above functions (Compliance, Risk Management, Internal Audit) is outsourced please describe the process in place for its monitoring and your evaluation of the service.",
        }),
      ],
    }),
    new QuestionGroup("External Audit Function", {
      questions: [
        new TextQuestion({
          key: "EAName",
          label: "Name of auditor of financial statements",
        }),
        new TextQuestion({
          key: "EAOtherNames",
          label: "Names of other auditors",
        }),
        new TextQuestion({
          key: "EAOtherAuditReports",
          label: "Other audit reports",
        }),
        new TextQuestion({
          key: "companyCertification",
          label: "List the certification your company obtained",
          isMultiLine: true,
        }),
        new YesNoQuestion({
          key: "auditReviewed",
          label:
            "Has the relationship with audit firm been formally reviewed during the last five years (or inception), by your Senior Management Executive Board?",
        }),
        new TextQuestion({
          key: "auditPreviousFirm",
          label:
            "Name of previous Form. If this audit firm has been engaged by you within the last 5 years, please give the name of the previous firm used and the reason or the change.",
        }),
      ],
    }),
    new QuestionGroup("Material findings", {
      questions: [
        new ChoiceQuestion({
          key: "InternalControlInfo",
          label:
            "Confirm that the reports by the functions according to above, did not contain any material findings during the past 3 years",
          choices: [
            "Yes, we confirm",
            {
              value: "No, we cannot confirm",
              questions: [TextQuestion.Explain("InternalControlInfoNo")],
            },
          ],
        }),
      ],
    }),
    new QuestionGroup("Other", {
      questions: [
        new YesNoQuestion({
          key: "independentCompliance",
          subtitle: "Compliance team independence",
          label:
            "Could you please confirm the compliance team works independently from operational teams?",
          No: [TextQuestion.Explain("independentComplianceNo")],
        }),
      ],
    }),
  ],
};

export default category;
