/* eslint-disable no-unused-vars */
enum CORRESPONDENCE_STATE {
  OPEN = "open",
  CLOSED = "closed",
}
enum CORRESPONDENCE_TYPE {
  GENERAL = "GENERAL",
  DUE_DILIGENCE_REPORT = "DUE_DILIGENCE_REPORT",
  DUE_DILIGENCE = "DUE_DILIGENCE",
  DUE_DILIGENCE_REQUEST = "DUE_DILIGENCE_REQUEST",
  BROADCAST = "BROADCAST",
}

export { CORRESPONDENCE_STATE, CORRESPONDENCE_TYPE };
