import { TextQuestion, ChoiceQuestion } from "../questions";
import { QUESTIONNAIRE_CATEGORIES } from "@app/constants/values/questionnaire";

const category = {
  ...QUESTIONNAIRE_CATEGORIES.HUMAN_RESOURCES,
  questions: [
    new TextQuestion({
      key: "totalNumberOfStaff",
      label: "Please provide the total number of staff",
    }),
    new TextQuestion({
      key: "totalNumberOfStaffOnTemporaryContacts",
      label: "Please provide the number of staff on temporary contracts",
    }),
    new TextQuestion({
      key: "purposeOfTemporaryContacts",
      label:
        "Please provide details on the purpose of these temporary contracts",
    }),
    new ChoiceQuestion({
      key: "staffTurnover",
      label: "What is your staff turnover for the current Year?",
      choices: ["0%-5%", "5%-10%", "10%-20%", "20%-30%", "Above"],
    }),
    new TextQuestion({
      key: "defailsOfOnGoingStaffTraining",
      label: "Please provide details of on-going staff training",
    }),
    new TextQuestion({
      key: "averageExperienceOfStaffMembersForKeyDepartments",
      label:
        "Please provide the average seniority/experience of your staff members for the key departments",
    }),
  ],
};

export default category;
