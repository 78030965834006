import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";

import { Modal } from "@gfw/corvus";

const { TextArea } = Input;

const CommentEditor = function ({
  addComment,
  editComment,
  submitting,
  comment,
  setCommentToEdit,
}) {
  const [form] = Form.useForm();

  const [showModal, setShowModal] = useState(!!comment);

  useEffect(() => {
    if (comment) setShowModal(true);
  }, [setShowModal, comment]);

  const hideModal = () => {
    form.resetFields();
    setShowModal(false);
    setCommentToEdit(null);
  };

  const label = comment?._id ? "Edit a comment" : "Create a new comment";

  if (!comment) return null;

  return (
    <Modal
      autoClose={false}
      closable={false}
      hideFooterBorder
      isLoading={submitting}
      maskClosable={false}
      onCancel={hideModal}
      onOk={form.submit}
      visible={showModal}
    >
      <Form
        autoComplete="off"
        form={form}
        initialValues={comment}
        layout="vertical"
        onFinish={async ({ content }) => {
          if (comment._id) {
            // edit
            await editComment({ commentOId: comment._id, content });
          } else {
            // add
            await addComment(content);
          }
          hideModal();
        }}
      >
        <Form.Item
          label={label}
          name="content"
          required={true}
          rules={[
            {
              required: true,
              message: "Please type your comment here",
            },
          ]}
          validateFirst={true}
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CommentEditor;
