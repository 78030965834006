import { Button, Form } from "antd";
import { useNavigate } from "react-router-dom";
import React from "react";

import {
  __DEPRECATED__ErrorHandler,
  Card,
  FormProfileLeiNumber,
  FormProfileLocation,
  FormProfileName,
  FormProfileProposedContacts,
  FormProfileType,
  Icon,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

import {
  Page,
  PageTitle,
  PageTitleTypo,
} from "@app/resources/generic/components";

function NewProfilePage() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  async function handleSubmit(values) {
    try {
      const { data } = await apiClient.post("profiles", values);
      notification.success({ message: "The proposed profile is created" });
      navigate(`/profiles/${data.id}`);
    } catch (error) {
      __DEPRECATED__ErrorHandler(error);

      if (error?.response?.status === 409) {
        form.setFields([
          {
            name: "name",
            errors: [
              "A profile is already proposed with this name, type and location",
            ],
          },
          {
            name: "types",
            errors: [
              "A profile is already proposed with this name, type and location",
            ],
          },
          {
            name: "location",
            errors: [
              "A profile is already proposed with this name, type and location",
            ],
          },
        ]);
      }
    }
  }

  return (
    <Page>
      <PageTitle>
        <Icon mr="sm" type="profile" />
        <PageTitleTypo>Create Profile</PageTitleTypo>
      </PageTitle>
      <Card>
        <Form
          autoComplete="off"
          data-form-type="other"
          form={form}
          initialValues={{ contacts: [""] }}
          labelCol={{ span: 3 }}
          onFinish={handleSubmit}
          style={{ maxWidth: 1024 }}
        >
          <FormProfileName
            onSelect={(_, option) => {
              form.setFieldValue("LEINumber", option.lei);
              form.validateFields(["LEINumber"]);
            }}
          />
          <FormProfileLeiNumber />
          <FormProfileType />
          <FormProfileLocation />
          <FormProfileProposedContacts />
        </Form>
        <Button
          onClick={form.submit}
          style={{ width: 150, marginRight: 15 }}
          type="primary"
        >
          CREATE
        </Button>
      </Card>
    </Page>
  );
}

export default NewProfilePage;
