const REQUEST_TYPE = Object.freeze({
  ACCESS_RIGHTS_REQUEST: "ACCESS_RIGHTS_REQUEST",
  BROADCAST_INQUIRY_REQUEST: "BROADCAST_INQUIRY_REQUEST",
  BROADCAST_STATEMENT_REQUEST: "BROADCAST_STATEMENT_REQUEST",
  DUE_DILIGENCE_REQUEST: "DUE_DILIGENCE_REQUEST",
});

const REQUEST_STATE = Object.freeze({
  NOT_STARTED: "NOT_STARTED",
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  READ: "READ",
  NEW: "NEW",
  ANSWERED: "ANSWERED",
  REVIEWED: "REVIEWED",
});

const REQUEST_DIRECTION = Object.freeze({
  INCOMING: "INCOMING",
  OUTGOING: "OUTGOING",
});

export { REQUEST_STATE, REQUEST_DIRECTION, REQUEST_TYPE };
