const SERVICES = Object.freeze({
  ACCESS_RIGHTS: {
    CREATE: "ACS_C",
    READ: "ACS_R",
    UPDATE: "ACS_U",
  },
  ADMIN: {
    READ: "ADMN_R",
  },
  ADMIN_STATS: {
    READ: "ADST_R",
    UPDATE: "ADST_U",
  },
  ARTICLE: {
    CREATE: "ART_C",
    READ: "ART_R",
    UPDATE: "ART_U",
  },
  ARTICLE_PROVIDER: {
    CREATE: "ARTP_C",
    READ: "ARTP_R",
    UPDATE: "ARTP_U",
    DELETE: "ARTP_D",
  },
  BANNER: {
    CREATE: "NOT_C",
    READ: "NOT_R",
    UPDATE: "NOT_U",
    DELETE: "NOT_D",
  },
  BROADCAST: {
    CREATE: "BCT_C",
    READ: "BCT_R",
    UPDATE: "BCT_U",
    DELETE: "BCT_D",
  },
  CHECKLIST: {
    CREATE: "CHKL_C",
    READ: "CHKL_R",
    UPDATE: "CHKL_U",
    DELETE: "CHKL_D",
  },
  CONTACT: {
    CREATE: "CONT_C",
    READ: "CONT_R",
    UPDATE: "CONT_U",
    DELETE: "CONT_D",
  },
  CORRESPONDENCE: {
    CREATE: "COR_C",
    READ: "COR_R",
    UPDATE: "COR_U",
    DELETE: "COR_D",
  },
  COUNTERPARTY: {
    CREATE: "COUN_C",
    READ: "COUN_R",
    UPDATE: "COUN_U",
    DELETE: "COUN_D",
  },
  COUNTERPARTY_DATA: {
    CREATE: "CPDT_C",
    READ: "CPDT_R",
    UPDATE: "CPDT_U",
    DELETE: "CPDT_D",
  },
  COUNTERPARTY_FILE: {
    READ: "COUF_R",
  },
  CRM: {
    CREATE: "CRM_C",
    READ: "CRM_R",
    UPDATE: "CRM_U",
    DELETE: "CRM_D",
  },
  DOCUMENT: {
    CREATE: "DOC_C",
    READ: "DOC_R",
    UPDATE: "DOC_U",
    DELETE: "DOC_D",
  },
  DUE_DILIGENCE_CAMPAIGN: {
    CREATE: "DDC_C",
    READ: "DDC_R",
    UPDATE: "DDC_U",
    DELETE: "DDC_D",
  },
  DUE_DILIGENCE_REPORT: {
    CREATE: "DDRP_C",
    READ: "DDRP_R",
    UPDATE: "DDRP_U",
    DELETE: "DDRP_D",
  },
  DUE_DILIGENCE_REQUEST: {
    CREATE: "DDRQ_C",
    READ: "DDRQ_R",
    UPDATE: "DDRQ_U",
    DELETE: "DDRQ_D",
  },
  PROFILE: {
    CREATE: "PROF_C",
    READ: "PROF_R",
    UPDATE: "PROF_U",
    DELETE: "PROF_D",
  },
  PROFILE_ADMIN: {
    CREATE: "PRAD_C",
    READ: "PRAD_R",
    UPDATE: "PRAD_U",
    DELETE: "PRAD_D",
  },
  PROFILE_DUMP: {
    // should be managed as an extra perm
    CREATE: "PRDMP_C",
  },
  PROFILE_FILE: {
    CREATE: "PRFI_C",
    READ: "PRFI_R",
    UPDATE: "PRFI_U",
    DELETE: "PRFI_D",
  },
  PROFILE_INFO: {
    UPDATE: "PRIN_U",
  },
  PROFILE_MERGE: {
    // should be managed as an extra perm
    CREATE: "PRMRG_C",
    UPDATE: "PRMRG_U",
  },
  PROFILE_USER: {
    CREATE: "PRUS_C",
    READ: "PRUS_R",
    UPDATE: "PRUS_U",
    DELETE: "PRUS_D",
  },
  QUESTION_CATEGORY: {
    CREATE: "QCAT_C",
    READ: "QCAT_R",
    UPDATE: "QCAT_U",
    DELETE: "QCAT_D",
  },
  QUESTION: {
    CREATE: "QUEST_C",
    READ: "QUEST_R",
    UPDATE: "QUEST_U",
    DELETE: "QUEST_D",
  },
  REQUESTS: {
    READ: "REQ_R",
  },
  RSSFEED: {
    CREATE: "RSS_C",
    READ: "RSS_R",
    UPDATE: "RSS_U",
    DELETE: "RSS_D",
  },
  SIGNATURE: {
    CREATE: "SIGN_C",
    READ: "SIGN_R",
    UPDATE: "SIGN_U",
    DELETE: "SIGN_D",
  },
  SUBSCRIPTION: {
    CREATE: "SUB_C",
    DELETE: "SUB_D",
  },
  USER_ADMIN: {
    CREATE: "USAD_C",
    DELETE: "USAD_D",
  },
  USER_ASSIGNMENT: {
    CREATE: "UASS_C",
    READ: "UASS_R",
    UPDATE: "UASS_U",
    DELETE: "UASS_D",
  },
});

const DOCUMENTS_ACCESS = Object.freeze({
  ID_AUTHORIZED_SIGNATORY: {
    CREATE: "IDAS_C",
    READ: "IDAS_R",
    DELETE: "IDAS_D",
  },
  ID_BOARD_MEMBER: {
    CREATE: "IDBM_C",
    READ: "IDBM_R",
    DELETE: "IDBM_D",
  },
  ID_CEO: {
    CREATE: "IDCEO_C",
    READ: "IDCEO_R",
    DELETE: "IDCEO_D",
  },
  ID_COPY: {
    CREATE: "IDCP_C",
    READ: "IDCP_R",
    DELETE: "IDCP_D",
  },
  ID_OTHER: {
    CREATE: "IDO_C",
    READ: "IDO_R",
    DELETE: "IDO_D",
  },
  ID_UBO: {
    CREATE: "IDUBO_C",
    READ: "IDUBO_R",
    DELETE: "IDUBO_D",
  },
});

const PERMISSIONS = {
  ...SERVICES,
  ...DOCUMENTS_ACCESS,
};

export { PERMISSIONS };
