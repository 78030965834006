import { messagesKeys } from "@app/validation/messages";
import { QUESTIONNAIRE_ITEM_TYPE } from "@app/constants";

import { ItemRuleValidator } from "../../type";

export const checkMaxRule: ItemRuleValidator = (item, value, ruleValue) => {
  if (
    [QUESTIONNAIRE_ITEM_TYPE.TEXT, QUESTIONNAIRE_ITEM_TYPE.TEXTAREA].includes(
      item.type,
    )
  ) {
    if (value.length <= Number(ruleValue)) return null;
    return messagesKeys.CQAnswers.stringTooBig;
  }
  if (item.type === QUESTIONNAIRE_ITEM_TYPE.DATE) {
    const valueDate = new Date(value);
    const ruleValueDate = new Date(ruleValue);
    valueDate.setHours(0, 0, 0, 0);
    ruleValueDate.setHours(0, 0, 0, 0);
    if (valueDate.getTime() <= ruleValueDate.getTime()) return null;
    return messagesKeys.CQAnswers.dateTooBig;
  }
  if (item.type === QUESTIONNAIRE_ITEM_TYPE.NUMBER) {
    if (value <= Number(ruleValue)) return null;
    return messagesKeys.CQAnswers.numberTooBig;
  }
  if (
    [
      QUESTIONNAIRE_ITEM_TYPE.CHECKBOX,
      QUESTIONNAIRE_ITEM_TYPE.LIST,
      QUESTIONNAIRE_ITEM_TYPE.MULTILOCATION,
      QUESTIONNAIRE_ITEM_TYPE.MULTISELECT,
      QUESTIONNAIRE_ITEM_TYPE.TABLE,
    ].includes(item.type)
  ) {
    if (value.length <= Number(ruleValue)) return null;
    return messagesKeys.CQAnswers.numberOfAnswersTooBig;
  }
  throw new Error("item type is not handled");
};
